import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getMonthDayCount } from './getMonthDayCount';
import { getNextMonth } from './getNextMonth';

/** Gets the date that comes seven days after the param date. */
export function getNextWeekDate(date: CustomDateObject): CustomDateObject {
  const monthDayCount = getMonthDayCount(date.month, date.year);
  const nextWeekDay = date.dayOfMonth + 7;

  // If the date that comes seven days after is in the next month.
  if (nextWeekDay > monthDayCount) {
    const nextMonth = getNextMonth(date.month, date.year);

    return createCustomDateObject({
      day: nextWeekDay - monthDayCount,
      month: nextMonth.month,
      year: nextMonth.year,
    });
  }

  return createCustomDateObject({
    day: nextWeekDay,
    month: date.month,
    year: date.year,
  });
}
