import { MonthYearPair } from '../types';

/** Gets the month that comes before the param month.*/
export function getPreviousMonth(month: number, year: number): MonthYearPair {
  const previousMonth =
    month === 0 // month is January?
      ? 11 // set month to December
      : month - 1; // set month to previous

  const correspondingYear =
    month === 0 // month is January?
      ? year - 1 // set year to previous
      : year; // keep same year

  return { month: previousMonth, year: correspondingYear };
}
