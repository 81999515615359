import { isLeapYear } from './isLeapYear';

/** Gets the number of days in the param month. */
export function getMonthDayCount(month: number, year: number): number {
  let totalDays: number;

  switch (month) {
    case 1: // February
      totalDays = 28 + isLeapYear(year);
      break;
    case 3: // April
    case 5: // June
    case 8: // September
    case 10: // November
      totalDays = 30;
      break;
    default:
      totalDays = 31;
  }

  return totalDays;
}
