import { Course } from 'src/course';
import { Footer } from 'src/shared';

export default function CoursePage() {
  return (
    <>
      <Course />
      <Footer white />
    </>
  );
}
