import { useEffect, useState } from 'react';
import { ALL_MEMBER_TYPES } from '../constants';
import { MemberType } from '../types';

interface MemberTypeHook {
  memberType: MemberType;
  setMemberType: (memberType: MemberType) => void;
}

const STORAGE_KEY = 'memberType';

/**
 * Manages the user-selected member type. The member type is stored in-memory
 * and in the browser's local storage so that it an be used throughout the app
 * to customize the user experience.
 */
export function useMemberType(): MemberTypeHook {
  const [memberType, setMemberTypeState] = useState(MemberType.Student);

  useEffect(() => {
    // If we have a member type in local storage, load it.
    const storedMemberType = localStorage.getItem(STORAGE_KEY) as MemberType;

    if (storedMemberType && ALL_MEMBER_TYPES.includes(storedMemberType)) {
      setMemberTypeState(storedMemberType);
    }
  }, []);

  function setMemberType(mt: MemberType) {
    setMemberTypeState(mt);

    // Also store the member type locally in the browser.
    localStorage.setItem(STORAGE_KEY, mt);
  }

  return { memberType, setMemberType };
}
