/**
 * This function will convert camel case identifiers into a title format
 * (ie. each word is capitalized with spaces in between each word or number).
 * Ascii A-Z = 65-90
 * Ascii 0-9 = 48-57
 */

export function camelCaseToTitle(str: string) {
  let newString = str
    .split('')
    .map((char) => {
      let asciiCode = char.charCodeAt(0);

      if (
        (asciiCode >= 48 && asciiCode <= 57) ||
        (asciiCode >= 65 && asciiCode <= 90)
      ) {
        return ` ${char}`;
      }

      return char;
    })
    .join('');

  // Capitalize first letter.
  newString = newString[0].toUpperCase() + newString.slice(1);

  return newString;
}
