import { CalendarView } from '../types';

export function isValidCalendarView(view: string): boolean {
  if (
    view === CalendarView.Day ||
    view === CalendarView.Month ||
    view === CalendarView.Week
  ) {
    return true;
  }

  return false;
}
