import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { isValidYearMonthDayDate } from './isValidYearMonthDayDate';
import { parseToCustomDateObject } from './parseToCustomDateObject';

/** Converts the URL date param into a {@link CustomDateObject}. */
export function getDateFromUrl(date: string | undefined): CustomDateObject {
  if (date && isValidYearMonthDayDate(date)) {
    return parseToCustomDateObject(date);
  }

  return createCustomDateObject();
}
