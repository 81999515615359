import { useState } from 'react';
import { FirstHeading, getColorByHash, Modal } from 'src/shared';
import { Course } from 'src/state';
import styled from 'styled-components';
import { CourseCard } from '../card/CourseCard';

interface DiscoverCardProps {
  course: Course;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export function DiscoverCard({ course }: DiscoverCardProps) {
  const [showModal, setShowModal] = useState(false);
  const colorProp = { [getColorByHash(course.id)]: true };

  return (
    <>
      <CourseCard
        key={course.id}
        course={course}
        onClick={() => setShowModal(true)}
      />
      <Modal
        {...colorProp}
        onHide={() => setShowModal(false)}
        visible={showModal}>
        <Content>
          <FirstHeading>{course.name}</FirstHeading>
        </Content>
      </Modal>
    </>
  );
}
