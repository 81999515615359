/**
 * @module Types and constants for badges.
 *
 * This file was generated using "yarn generate:avatars".
 */
import birthdayCakeImg from 'src/libraries/avatars/badges/assets/birthday-cake.png';
import bombImg from 'src/libraries/avatars/badges/assets/bomb.png';
import bugImg from 'src/libraries/avatars/badges/assets/bug.png';
import cameraWithFlashImg from 'src/libraries/avatars/badges/assets/camera-with-flash.png';
import catWithWrySmileImg from 'src/libraries/avatars/badges/assets/cat-with-wry-smile.png';
import catImg from 'src/libraries/avatars/badges/assets/cat.png';
import clapperBoardImg from 'src/libraries/avatars/badges/assets/clapper-board.png';
import crabImg from 'src/libraries/avatars/badges/assets/crab.png';
import cricketImg from 'src/libraries/avatars/badges/assets/cricket.png';
import cryingCatImg from 'src/libraries/avatars/badges/assets/crying-cat.png';
import cupcakeImg from 'src/libraries/avatars/badges/assets/cupcake.png';
import deerImg from 'src/libraries/avatars/badges/assets/deer.png';
import dogImg from 'src/libraries/avatars/badges/assets/dog.png';
import dolphinImg from 'src/libraries/avatars/badges/assets/dolphin.png';
import eweImg from 'src/libraries/avatars/badges/assets/ewe.png';
import foxImg from 'src/libraries/avatars/badges/assets/fox.png';
import ghostImg from 'src/libraries/avatars/badges/assets/ghost.png';
import grinningCatImg from 'src/libraries/avatars/badges/assets/grinning-cat.png';
import growingHeartImg from 'src/libraries/avatars/badges/assets/growing-heart.png';
import hedgehogImg from 'src/libraries/avatars/badges/assets/hedgehog.png';
import honeybeeImg from 'src/libraries/avatars/badges/assets/honeybee.png';
import hundredPointsImg from 'src/libraries/avatars/badges/assets/hundred-points.png';
import jackoLanternImg from 'src/libraries/avatars/badges/assets/jack-o-lantern.png';
import kissMarkImg from 'src/libraries/avatars/badges/assets/kiss-mark.png';
import kissingCatImg from 'src/libraries/avatars/badges/assets/kissing-cat.png';
import koalaImg from 'src/libraries/avatars/badges/assets/koala.png';
import lionImg from 'src/libraries/avatars/badges/assets/lion.png';
import locomotiveImg from 'src/libraries/avatars/badges/assets/locomotive.png';
import moaiImg from 'src/libraries/avatars/badges/assets/moai.png';
import monkeyImg from 'src/libraries/avatars/badges/assets/monkey.png';
import musicalNoteImg from 'src/libraries/avatars/badges/assets/musical-note.png';
import nestingDollsImg from 'src/libraries/avatars/badges/assets/nesting-dolls.png';
import ninjaImg from 'src/libraries/avatars/badges/assets/ninja.png';
import octopusImg from 'src/libraries/avatars/badges/assets/octopus.png';
import orangutanImg from 'src/libraries/avatars/badges/assets/orangutan.png';
import pandaImg from 'src/libraries/avatars/badges/assets/panda.png';
import penguinImg from 'src/libraries/avatars/badges/assets/penguin.png';
import pigImg from 'src/libraries/avatars/badges/assets/pig.png';
import pileOfPooImg from 'src/libraries/avatars/badges/assets/pile-of-poo.png';
import pizzaImg from 'src/libraries/avatars/badges/assets/pizza.png';
import poutingCatImg from 'src/libraries/avatars/badges/assets/pouting-cat.png';
import rabbitFaceImg from 'src/libraries/avatars/badges/assets/rabbit-face.png';
import rabbitImg from 'src/libraries/avatars/badges/assets/rabbit.png';
import rainbowImg from 'src/libraries/avatars/badges/assets/rainbow.png';
import seeNoEvilMonkeyImg from 'src/libraries/avatars/badges/assets/see-no-evil-monkey.png';
import sharkImg from 'src/libraries/avatars/badges/assets/shark.png';
import skullImg from 'src/libraries/avatars/badges/assets/skull.png';
import slothImg from 'src/libraries/avatars/badges/assets/sloth.png';
import smilingCatWithHeartEyesImg from 'src/libraries/avatars/badges/assets/smiling-cat-with-heart-eyes.png';
import snailImg from 'src/libraries/avatars/badges/assets/snail.png';
import snakeImg from 'src/libraries/avatars/badges/assets/snake.png';
import snowmanWithoutSnowImg from 'src/libraries/avatars/badges/assets/snowman-without-snow.png';
import sparklingHeartImg from 'src/libraries/avatars/badges/assets/sparkling-heart.png';
import spoutingWhaleImg from 'src/libraries/avatars/badges/assets/spouting-whale.png';
import starImg from 'src/libraries/avatars/badges/assets/star.png';
import teddyBearImg from 'src/libraries/avatars/badges/assets/teddy-bear.png';
import tigerFaceImg from 'src/libraries/avatars/badges/assets/tiger-face.png';
import tropicalFishImg from 'src/libraries/avatars/badges/assets/tropical-fish.png';
import turtleImg from 'src/libraries/avatars/badges/assets/turtle.png';
import unicornImg from 'src/libraries/avatars/badges/assets/unicorn.png';
import wearyCatImg from 'src/libraries/avatars/badges/assets/weary-cat.png';
import wrappedGiftImg from 'src/libraries/avatars/badges/assets/wrapped-gift.png';
import zombieImg from 'src/libraries/avatars/badges/assets/zombie.png';
import zzzImg from 'src/libraries/avatars/badges/assets/zzz.png';

/**
 * Enum representing all supported badges.
 */
export enum BadgeEnum {
  BirthdayCake = 'birthday-cake',
  Bomb = 'bomb',
  Bug = 'bug',
  CameraWithFlash = 'camera-with-flash',
  CatWithWrySmile = 'cat-with-wry-smile',
  Cat = 'cat',
  ClapperBoard = 'clapper-board',
  Crab = 'crab',
  Cricket = 'cricket',
  CryingCat = 'crying-cat',
  Cupcake = 'cupcake',
  Deer = 'deer',
  Dog = 'dog',
  Dolphin = 'dolphin',
  Ewe = 'ewe',
  Fox = 'fox',
  Ghost = 'ghost',
  GrinningCat = 'grinning-cat',
  GrowingHeart = 'growing-heart',
  Hedgehog = 'hedgehog',
  Honeybee = 'honeybee',
  HundredPoints = 'hundred-points',
  JackoLantern = 'jack-o-lantern',
  KissMark = 'kiss-mark',
  KissingCat = 'kissing-cat',
  Koala = 'koala',
  Lion = 'lion',
  Locomotive = 'locomotive',
  Moai = 'moai',
  Monkey = 'monkey',
  MusicalNote = 'musical-note',
  NestingDolls = 'nesting-dolls',
  Ninja = 'ninja',
  Octopus = 'octopus',
  Orangutan = 'orangutan',
  Panda = 'panda',
  Penguin = 'penguin',
  Pig = 'pig',
  PileOfPoo = 'pile-of-poo',
  Pizza = 'pizza',
  PoutingCat = 'pouting-cat',
  RabbitFace = 'rabbit-face',
  Rabbit = 'rabbit',
  Rainbow = 'rainbow',
  SeeNoEvilMonkey = 'see-no-evil-monkey',
  Shark = 'shark',
  Skull = 'skull',
  Sloth = 'sloth',
  SmilingCatWithHeartEyes = 'smiling-cat-with-heart-eyes',
  Snail = 'snail',
  Snake = 'snake',
  SnowmanWithoutSnow = 'snowman-without-snow',
  SparklingHeart = 'sparkling-heart',
  SpoutingWhale = 'spouting-whale',
  Star = 'star',
  TeddyBear = 'teddy-bear',
  TigerFace = 'tiger-face',
  TropicalFish = 'tropical-fish',
  Turtle = 'turtle',
  Unicorn = 'unicorn',
  WearyCat = 'weary-cat',
  WrappedGift = 'wrapped-gift',
  Zombie = 'zombie',
  Zzz = 'zzz',
}

/**
 * List of supported badges.
 */
export const SUPPORTED_BADGES = Object.values(BadgeEnum);

/**
 * Map of badge enums and their sources.
 */
export const BADGE_SOURCES = {
  [BadgeEnum.BirthdayCake]: birthdayCakeImg,
  [BadgeEnum.Bomb]: bombImg,
  [BadgeEnum.Bug]: bugImg,
  [BadgeEnum.CameraWithFlash]: cameraWithFlashImg,
  [BadgeEnum.CatWithWrySmile]: catWithWrySmileImg,
  [BadgeEnum.Cat]: catImg,
  [BadgeEnum.ClapperBoard]: clapperBoardImg,
  [BadgeEnum.Crab]: crabImg,
  [BadgeEnum.Cricket]: cricketImg,
  [BadgeEnum.CryingCat]: cryingCatImg,
  [BadgeEnum.Cupcake]: cupcakeImg,
  [BadgeEnum.Deer]: deerImg,
  [BadgeEnum.Dog]: dogImg,
  [BadgeEnum.Dolphin]: dolphinImg,
  [BadgeEnum.Ewe]: eweImg,
  [BadgeEnum.Fox]: foxImg,
  [BadgeEnum.Ghost]: ghostImg,
  [BadgeEnum.GrinningCat]: grinningCatImg,
  [BadgeEnum.GrowingHeart]: growingHeartImg,
  [BadgeEnum.Hedgehog]: hedgehogImg,
  [BadgeEnum.Honeybee]: honeybeeImg,
  [BadgeEnum.HundredPoints]: hundredPointsImg,
  [BadgeEnum.JackoLantern]: jackoLanternImg,
  [BadgeEnum.KissMark]: kissMarkImg,
  [BadgeEnum.KissingCat]: kissingCatImg,
  [BadgeEnum.Koala]: koalaImg,
  [BadgeEnum.Lion]: lionImg,
  [BadgeEnum.Locomotive]: locomotiveImg,
  [BadgeEnum.Moai]: moaiImg,
  [BadgeEnum.Monkey]: monkeyImg,
  [BadgeEnum.MusicalNote]: musicalNoteImg,
  [BadgeEnum.NestingDolls]: nestingDollsImg,
  [BadgeEnum.Ninja]: ninjaImg,
  [BadgeEnum.Octopus]: octopusImg,
  [BadgeEnum.Orangutan]: orangutanImg,
  [BadgeEnum.Panda]: pandaImg,
  [BadgeEnum.Penguin]: penguinImg,
  [BadgeEnum.Pig]: pigImg,
  [BadgeEnum.PileOfPoo]: pileOfPooImg,
  [BadgeEnum.Pizza]: pizzaImg,
  [BadgeEnum.PoutingCat]: poutingCatImg,
  [BadgeEnum.RabbitFace]: rabbitFaceImg,
  [BadgeEnum.Rabbit]: rabbitImg,
  [BadgeEnum.Rainbow]: rainbowImg,
  [BadgeEnum.SeeNoEvilMonkey]: seeNoEvilMonkeyImg,
  [BadgeEnum.Shark]: sharkImg,
  [BadgeEnum.Skull]: skullImg,
  [BadgeEnum.Sloth]: slothImg,
  [BadgeEnum.SmilingCatWithHeartEyes]: smilingCatWithHeartEyesImg,
  [BadgeEnum.Snail]: snailImg,
  [BadgeEnum.Snake]: snakeImg,
  [BadgeEnum.SnowmanWithoutSnow]: snowmanWithoutSnowImg,
  [BadgeEnum.SparklingHeart]: sparklingHeartImg,
  [BadgeEnum.SpoutingWhale]: spoutingWhaleImg,
  [BadgeEnum.Star]: starImg,
  [BadgeEnum.TeddyBear]: teddyBearImg,
  [BadgeEnum.TigerFace]: tigerFaceImg,
  [BadgeEnum.TropicalFish]: tropicalFishImg,
  [BadgeEnum.Turtle]: turtleImg,
  [BadgeEnum.Unicorn]: unicornImg,
  [BadgeEnum.WearyCat]: wearyCatImg,
  [BadgeEnum.WrappedGift]: wrappedGiftImg,
  [BadgeEnum.Zombie]: zombieImg,
  [BadgeEnum.Zzz]: zzzImg,
};

/**
 * Map of badge enums and their names.
 */
export const BADGE_NAMES = {
  [BadgeEnum.BirthdayCake]: 'Birthday cake',
  [BadgeEnum.Bomb]: 'Bomb',
  [BadgeEnum.Bug]: 'Bug',
  [BadgeEnum.CameraWithFlash]: 'Camera with flash',
  [BadgeEnum.CatWithWrySmile]: 'Cat with wry smile',
  [BadgeEnum.Cat]: 'Cat',
  [BadgeEnum.ClapperBoard]: 'Clapper board',
  [BadgeEnum.Crab]: 'Crab',
  [BadgeEnum.Cricket]: 'Cricket',
  [BadgeEnum.CryingCat]: 'Crying cat',
  [BadgeEnum.Cupcake]: 'Cupcake',
  [BadgeEnum.Deer]: 'Deer',
  [BadgeEnum.Dog]: 'Dog',
  [BadgeEnum.Dolphin]: 'Dolphin',
  [BadgeEnum.Ewe]: 'Ewe',
  [BadgeEnum.Fox]: 'Fox',
  [BadgeEnum.Ghost]: 'Ghost',
  [BadgeEnum.GrinningCat]: 'Grinning cat',
  [BadgeEnum.GrowingHeart]: 'Growing heart',
  [BadgeEnum.Hedgehog]: 'Hedgehog',
  [BadgeEnum.Honeybee]: 'Honeybee',
  [BadgeEnum.HundredPoints]: 'Hundred points',
  [BadgeEnum.JackoLantern]: 'Jack o lantern',
  [BadgeEnum.KissMark]: 'Kiss mark',
  [BadgeEnum.KissingCat]: 'Kissing cat',
  [BadgeEnum.Koala]: 'Koala',
  [BadgeEnum.Lion]: 'Lion',
  [BadgeEnum.Locomotive]: 'Locomotive',
  [BadgeEnum.Moai]: 'Moai',
  [BadgeEnum.Monkey]: 'Monkey',
  [BadgeEnum.MusicalNote]: 'Musical note',
  [BadgeEnum.NestingDolls]: 'Nesting dolls',
  [BadgeEnum.Ninja]: 'Ninja',
  [BadgeEnum.Octopus]: 'Octopus',
  [BadgeEnum.Orangutan]: 'Orangutan',
  [BadgeEnum.Panda]: 'Panda',
  [BadgeEnum.Penguin]: 'Penguin',
  [BadgeEnum.Pig]: 'Pig',
  [BadgeEnum.PileOfPoo]: 'Pile of poo',
  [BadgeEnum.Pizza]: 'Pizza',
  [BadgeEnum.PoutingCat]: 'Pouting cat',
  [BadgeEnum.RabbitFace]: 'Rabbit face',
  [BadgeEnum.Rabbit]: 'Rabbit',
  [BadgeEnum.Rainbow]: 'Rainbow',
  [BadgeEnum.SeeNoEvilMonkey]: 'See no evil monkey',
  [BadgeEnum.Shark]: 'Shark',
  [BadgeEnum.Skull]: 'Skull',
  [BadgeEnum.Sloth]: 'Sloth',
  [BadgeEnum.SmilingCatWithHeartEyes]: 'Smiling cat with heart eyes',
  [BadgeEnum.Snail]: 'Snail',
  [BadgeEnum.Snake]: 'Snake',
  [BadgeEnum.SnowmanWithoutSnow]: 'Snowman without snow',
  [BadgeEnum.SparklingHeart]: 'Sparkling heart',
  [BadgeEnum.SpoutingWhale]: 'Spouting whale',
  [BadgeEnum.Star]: 'Star',
  [BadgeEnum.TeddyBear]: 'Teddy bear',
  [BadgeEnum.TigerFace]: 'Tiger face',
  [BadgeEnum.TropicalFish]: 'Tropical fish',
  [BadgeEnum.Turtle]: 'Turtle',
  [BadgeEnum.Unicorn]: 'Unicorn',
  [BadgeEnum.WearyCat]: 'Weary cat',
  [BadgeEnum.WrappedGift]: 'Wrapped gift',
  [BadgeEnum.Zombie]: 'Zombie',
  [BadgeEnum.Zzz]: 'Zzz',
};
