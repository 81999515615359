import { useNavigate } from 'react-router-dom';
import { getCourseRoute, Loading } from 'src/shared';
import { Course } from 'src/state';
import styled from 'styled-components';
import { CourseCard } from './card/CourseCard';

interface RegisteredCoursesProps {
  courses: Course[];
  isLoading: boolean;
}

const RegisteredCoursesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export function RegisteredCourses({
  courses,
  isLoading,
}: RegisteredCoursesProps) {
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  if (courses.length < 1) {
    return <div>Looks like you're not enrolled in any courses yet.</div>;
  }

  return (
    <RegisteredCoursesWrapper>
      {courses.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          onClick={() => navigate(getCourseRoute(course.id))}
        />
      ))}
    </RegisteredCoursesWrapper>
  );
}
