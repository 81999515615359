import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { getColorFromProps } from '../../../../colors/getColor';

interface SquareProps {
  lavender?: boolean;
  lightPurple?: boolean;
  mint?: boolean;
  pink?: boolean;
  rotation: string;
  route: string;
  title: string;
  white?: boolean;
}

const SquareContainer = styled.div<SquareProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  height: 350px;
  width: 100%;
`;

const Anchor = styled(RouterLink)`
  align-items: center;
  color: ${(props) => props.theme.black.string()};
  display: flex;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  width: 100%;
`;

const Title = styled.h2<SquareProps>`
  font-size: 20px;
  transform: rotate(${(props) => props.rotation});
`;

export function Square(props: SquareProps) {
  return (
    <SquareContainer {...props}>
      <Anchor to={props.route}>
        <Title {...props}>{props.title}</Title>
      </Anchor>
    </SquareContainer>
  );
}
