import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getMonthDayCount } from './getMonthDayCount';
import { getNextMonth } from './getNextMonth';

/** Gets the date that comes after the param date. */
export function getNextDate(date: CustomDateObject): CustomDateObject {
  const monthDayCount = getMonthDayCount(date.month, date.year);
  const nextDayOfMonth = date.dayOfMonth + 1;

  // If the date that comes after is in the next month.
  if (nextDayOfMonth > monthDayCount) {
    const nextMonth = getNextMonth(date.month, date.year);

    return createCustomDateObject({
      day: 1,
      month: nextMonth.month,
      year: nextMonth.year,
    });
  }

  return createCustomDateObject({
    day: nextDayOfMonth,
    month: date.month,
    year: date.year,
  });
}
