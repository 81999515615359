import {
  AuthenticationProvider,
  AuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client';
import { getTokens } from '../msal';

/** @todo: not currently working as intended. */
export class HomeroomGraphAuthProvider implements AuthenticationProvider {
  async getAccessToken(opts?: AuthenticationProviderOptions): Promise<string> {
    const { accessToken } = await getTokens();

    return accessToken;
  }
}
