import styled from 'styled-components';
import { ALL_MEMBER_TYPES } from '../constants';
import { MemberType } from '../types';
import { SelectorButton } from './SelectorButton';

export interface MemberTypeSelectorProps {
  memberType: MemberType | null;
  onSelect: (memberType: MemberType) => void;
}

const SelectorWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin: 3rem auto;
`;

/**
 * Allows member to select a member-type before logging in.
 */
export function MemberTypeSelector({
  memberType,
  onSelect,
}: MemberTypeSelectorProps) {
  return (
    <SelectorWrapper>
      {ALL_MEMBER_TYPES.map((mType) => (
        <SelectorButton
          key={mType}
          active={memberType === mType}
          memberType={mType}
          onClick={() => onSelect(mType)}
        />
      ))}
    </SelectorWrapper>
  );
}
