import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.backgroundColor.string()};
    color: ${(props) => props.theme.textColor.string()};
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.theme.fontSize};
    font-variant: common-ligatures tabular-nums;
    font-weight: ${(props) => props.theme.fontWeight};
    line-height: ${(props) => props.theme.lineHeight};
    margin: 0;
    min-height: 100vh;
    width: 100vw;
  }

  div, main, section {
    position: relative;
  }

  #homeroom-root {
    min-height: 100vh;
    width: 100vw;
  }
`;
