import { ClickableCard, getCourseRoute } from 'src/shared';
import { Lesson } from 'src/state';
import styled from 'styled-components';
import { InternalLinkLessonItem } from '../ClickableLessonItem';

interface LessonListProps {
  courseId: number;
  mergedLessons: Lesson[];
  selectedLesson: Lesson | null;
}

interface ProgressMarkerProps {
  highlight?: boolean;
}

const LessonListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProgressMarker = styled.div<ProgressMarkerProps>`
  background-color: ${(props) =>
    props.highlight ? props.theme.yellow.string() : props.theme.white.string()};
  border: 1px solid ${(props) => props.theme.grey.string()};
  border-radius: 50%;
  font-size: 18px;
  height: 32px;
  position: relative;
  text-align: center;
  width: 32px;
`;

const LessonCard = styled(ClickableCard).attrs({ as: 'span' })``;

export function LessonList({
  courseId,
  mergedLessons,
  selectedLesson,
}: LessonListProps) {
  return (
    <LessonListWrapper>
      {mergedLessons.map((lesson) => {
        const isStarted =
          lesson.started || selectedLesson?.lessonId === lesson.lessonId;

        return (
          <InternalLinkLessonItem
            key={lesson.lessonId}
            to={getCourseRoute(courseId, lesson.lessonId)}>
            <ProgressMarker highlight={lesson.completed || lesson.started}>
              {lesson.completed && <>&#10003;</>}
            </ProgressMarker>

            <LessonCard lightPurple={!isStarted} teal={isStarted}>
              {lesson.name}
            </LessonCard>
          </InternalLinkLessonItem>
        );
      })}
    </LessonListWrapper>
  );
}
