import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLoginRoute } from 'src/shared';

interface AuthGuardProps {
  children: React.ReactNode;
}

/**
 * Blocks child components from rendering unless the user is signed in.
 */
export function AuthGuard({ children }: AuthGuardProps) {
  const pushRoute = useNavigate();
  const { pathname } = useLocation();
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  useEffect(() => {
    if (!isAuthenticated) {
      pushRoute(getLoginRoute(pathname));
    }
  }, [isAuthenticated, pathname, pushRoute]);

  return isAuthenticated ? <>{children}</> : null;
}
