import { useEffect, useState } from 'react';
import { MctApi } from 'src/libraries/mct';
import { withCause } from 'src/utils';
import { Notification } from '../entities/notification';

interface UseHomeroomNotificationsResponse {
  isLoadingNotifications: boolean;
  notifications: Notification[];
}

export function useHomeroomNotifications(
  token: string,
  timestamp: number,
): UseHomeroomNotificationsResponse {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoadingNotifications, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      return;
    }

    setLoading(true);

    MctApi.getNotifications(token)
      .then(({ Notifications }) => {
        const fourteenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;
        const latestNotifications = Notifications.filter(
          (notif) =>
            new Date().valueOf() - new Date(notif.CreateDateTime).valueOf() <
            fourteenDaysInMilliseconds,
        );
        setNotifications(
          latestNotifications.map((n) => Notification.fromMctEntity(n)),
        );
      })
      .catch(withCause('Could not retrieve notifications.'))
      .finally(() => void setLoading(false));
  }, [timestamp, token]);

  return { isLoadingNotifications, notifications };
}
