import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSudoku } from 'sudoku-gen';
import { Difficulty } from './types';

interface PuzzleProps {
  difficulty: Difficulty;
  onSolved: () => void;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const Grid = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  border: 1px solid ${(props) => props.theme.violet.string()};
  display: grid;
  grid-template-columns: 50px 50px 50px 50px 50px 50px 50px 50px 50px;
  grid-template-rows: 50px 50px 50px 50px 50px 50px 50px 50px 50px;
`;

const Cell = styled.div<{
  bottomEdge: boolean;
  highlight: boolean;
  rightEdge: boolean;
  selectable: boolean;
}>`
  align-items: center;
  background-color: ${(props) =>
    props.highlight ? props.theme.lavender.string() : 'transparent'};
  border: 1px solid ${(props) => props.theme.lavender.string()};
  border-bottom-color: ${(props) =>
    props.bottomEdge
      ? props.theme.violet.string()
      : props.theme.lavender.string()};
  border-right-color: ${(props) =>
    props.rightEdge
      ? props.theme.violet.string()
      : props.theme.lavender.string()};
  cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
`;

const Digit = styled.h4<{ immutable: boolean }>`
  color: ${(props) =>
    props.immutable ? props.theme.black.string() : props.theme.purple.string()};
  font-size: 20px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
`;

const DigitSelector = styled.div`
  background-color: ${(props) => props.theme.mint.string()};
  display: grid;
  grid-template-columns: 80px 80px 80px;
  grid-template-rows: 80px 80px 80px;
  margin-bottom: 1rem;
`;

const Number = styled.div`
  align-items: center;
  border: 1px solid ${(props) => props.theme.teal.string()};
  cursor: pointer;
  display: flex;
  font-size: 24px;
  justify-content: center;
`;

const ClearCell = styled.div`
  background-color: ${(props) => props.theme.pink.string()};
  border: 1px solid ${(props) => props.theme.violet.string()};
  border-radius: ${(props) => props.theme.inputBorderRadius};
  cursor: pointer;
  text-align: center;
`;

const BLANK_CELL = '-';

export function Puzzle({ difficulty, onSolved }: PuzzleProps) {
  const [immutables, setImmutables] = useState<number[]>([]);
  const [puzzle, setPuzzle] = useState<string[]>([]);
  const [selectedCell, setSelectedCell] = useState(-1);
  const [solution, setSolution] = useState('');

  /** Initializes the game. */
  useEffect(() => {
    const { puzzle, solution } = getSudoku(difficulty);

    const puzzleArray = puzzle.split('');
    const immutables = puzzleArray
      .map((char, index) => (char === '-' ? -1 : index))
      .filter((num) => num > -1);

    setImmutables(immutables);
    setPuzzle(puzzleArray);
    setSolution(solution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Determines if the player has solved the puzzle. */
  useEffect(() => {
    if (!puzzle.length) {
      return;
    }

    if (!solution) {
      return;
    }

    if (puzzle.join('') === solution) {
      setTimeout(onSolved, 1600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [puzzle, solution]);

  /** Darkens the bottom border of the cells in row 3 and 6. */
  function darkenBottomBorder(index: number): boolean {
    // Row 3
    if (index > 17 && index < 27) {
      return true;
    }

    // Row 6
    if (index > 44 && index < 54) {
      return true;
    }

    return false;
  }

  /** Darkens the right border of the cells in column 3 and 6. */
  function darkenRightBorder(index: number): boolean {
    // Column 3
    if ((index - 2) % 9 === 0) {
      return true;
    }

    // Column 6
    if ((index - 5) % 9 === 0) {
      return true;
    }

    return false;
  }

  /** Highlights a cell, enabling the player to then put a number. */
  function selectCell(index: number) {
    setSelectedCell(index);
  }

  /** Determines if a cell will be highlighted. */
  function setHighlight(index: number): boolean {
    if (index === selectedCell) {
      return true;
    }

    if (selectedCell > -1 && puzzle[selectedCell] !== BLANK_CELL) {
      return puzzle[selectedCell] === puzzle[index];
    }

    return false;
  }

  function updatePuzzle(selectedCell: number, char: string) {
    if (selectedCell > -1 && !immutables.includes(selectedCell)) {
      setPuzzle((currPuzzle) => {
        const updatedPuzzle = [...currPuzzle];

        updatedPuzzle[selectedCell] = char;

        return updatedPuzzle;
      });
    }
  }

  return (
    <Wrapper>
      <Grid>
        {puzzle.map((char, index) => {
          const immutable = immutables.includes(index);

          return (
            <Cell
              bottomEdge={darkenBottomBorder(index)}
              highlight={setHighlight(index)}
              key={index}
              onClick={() => selectCell(index)}
              rightEdge={darkenRightBorder(index)}
              selectable={!immutable}>
              {char !== BLANK_CELL && (
                <Digit immutable={immutable}>{char}</Digit>
              )}
            </Cell>
          );
        })}
      </Grid>

      <Controls>
        <DigitSelector>
          {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((num) => (
            <Number key={num} onClick={() => updatePuzzle(selectedCell, num)}>
              {num}
            </Number>
          ))}
        </DigitSelector>

        <ClearCell onClick={() => updatePuzzle(selectedCell, BLANK_CELL)}>
          Clear Cell
        </ClearCell>
      </Controls>
    </Wrapper>
  );
}
