import { ChangeEvent, useState } from 'react';
import {
  OpenPeepsAvatar,
  OpenPeepsColorPropKey,
  OpenPeepsOptions,
  OpenPeepsVisualTraitKey,
  OPEN_PEEPS_COLOR_PROPS,
  OPEN_PEEPS_VISUAL_TRAIT_KEYS,
  OPEN_PEEPS_VISUAL_TRAIT_OPTIONS,
} from 'src/libraries/avatars';
import { Select } from 'src/shared';
import styled from 'styled-components';
import { BadgeList } from '../BadgeList';
import { EditorWrapper } from '../EditorWrapper';
import { AvatarEditorPreview } from '../Preview';
import { camelCaseToTitle } from '../utils/camelCaseToTitle';
import { TraitPreview } from './TraitPreview';

interface EditOpenPeepsAvatarProps {
  data: OpenPeepsOptions;
  onUpdate: (data: OpenPeepsOptions) => void;
}

const TraitEditor = styled.div`
  display: flex;
  flex-direction: column;
`;

const TraitSelector = styled.div`
  margin-bottom: 1rem;
  span {
    margin-right: 0.3rem;
  }
  select {
    width: auto;
  }
`;

const ColorInputWrapper = styled.div`
  width: 200px;
`;

const ColorInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    flex-grow: 1;
    margin-right: 0.7rem;
    text-align: right;
  }
`;

export function EditOpenPeepsAvatar({
  data,
  onUpdate,
}: EditOpenPeepsAvatarProps) {
  const [selectedTrait, setSelectedTrait] =
    useState<OpenPeepsVisualTraitKey>('accessories');

  const handleUpdateColor = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...data,
      [event.target.name]: [event.target.value],
    });
  };

  function handleUpdateTraitValue(
    trait: OpenPeepsVisualTraitKey,
    value?: string,
  ) {
    onUpdate({ ...data, [trait]: [value] });
  }

  const handleSelectTrait = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTrait(event.target.value! as OpenPeepsVisualTraitKey);
  };

  const renderTraitOption = (avatarPropertyKey: OpenPeepsVisualTraitKey) => {
    return (
      <option key={avatarPropertyKey.toString()} value={avatarPropertyKey}>
        {camelCaseToTitle(avatarPropertyKey.toString())}
      </option>
    );
  };

  const renderColorInputs = (colorProperty: OpenPeepsColorPropKey) => {
    return (
      <ColorInput key={colorProperty}>
        <span>{camelCaseToTitle(colorProperty.replace('Color', ''))}</span>
        <input
          name={colorProperty}
          onChange={handleUpdateColor}
          type="color"
          value={data[colorProperty]}
        />
      </ColorInput>
    );
  };

  return (
    <EditorWrapper>
      <AvatarEditorPreview color="transparent">
        <OpenPeepsAvatar options={data} />
      </AvatarEditorPreview>

      <TraitEditor>
        <TraitSelector>
          <span>Customize your:</span>
          <Select onChange={handleSelectTrait} value={selectedTrait}>
            {OPEN_PEEPS_VISUAL_TRAIT_KEYS.map(renderTraitOption)}
          </Select>
        </TraitSelector>

        <BadgeList>
          <TraitPreview onClick={() => handleUpdateTraitValue(selectedTrait)}>
            <OpenPeepsAvatar options={{ ...data, [selectedTrait]: [] }} />
          </TraitPreview>
          {OPEN_PEEPS_VISUAL_TRAIT_OPTIONS[selectedTrait].map((trait) => (
            <TraitPreview
              key={trait!.toString()}
              onClick={() => handleUpdateTraitValue(selectedTrait, trait)}>
              <OpenPeepsAvatar
                options={{ ...data, [selectedTrait]: [trait] }}
              />
            </TraitPreview>
          ))}
        </BadgeList>
      </TraitEditor>

      <ColorInputWrapper>
        {OPEN_PEEPS_COLOR_PROPS.map(renderColorInputs)}
      </ColorInputWrapper>
    </EditorWrapper>
  );
}
