import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getNextMonth } from './getNextMonth';

/** Gets the first day of the next month. */
export function getNextMonthDate(date: CustomDateObject): CustomDateObject {
  const nextMonth = getNextMonth(date.month, date.year);

  return createCustomDateObject({
    day: 1,
    month: nextMonth.month,
    year: nextMonth.year,
  });
}
