import { useState } from 'react';
import { X } from 'react-feather';
import { Button, ColorProps, getColorFromProps } from 'src/shared';
import { Profile } from 'src/state';
import styled from 'styled-components';
import { EditAvatar } from './avatar/EditAvatar';
import { DetailsForm } from './DetailsForm';
import { ParentForm } from './ParentForm';
import { PasswordForm } from './PasswordForm';
import { TabBody } from './TabBody';

interface PopupProps {
  initialView?: string;
  onClose: () => void;
  profile: Profile;
}

interface PopupTabProps extends ColorProps {
  active: boolean;
}

// TODO - Fix/Improve vertical scrolling.
const BlurredBackground = styled.div`
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const PopupContainer = styled.div<ColorProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  border: 2px solid ${(props) => props.theme.violet.string()};
  border-radius: 5px;
  box-sizing: border-box;
  left: 50%;
  max-width: 1024px;
  min-height: 65%;
  outline: 5px solid ${(props) => props.theme.white.string()};
  padding: 1rem;
  position: absolute;
  top: 15%;
  transform: translateX(-50%);
  width: 100%;
`;

/** Hides the bottom of inactive tabs. This is a fix for z-index. */
const PopupTabOverlay = styled.div<ColorProps & { first?: boolean }>`
  background-color: ${(props) => getColorFromProps(props).string()};
  border-left: ${(props) =>
    props.first ? `2px solid ${props.theme.violet.string()};` : 'none'};
  border-top: 2px solid ${(props) => props.theme.violet.string()};
  border-top-left-radius: ${(props) =>
    props.first ? props.theme.inputBorderRadius : '0'};
  bottom: calc(-12px);
  height: 1rem;
  left: calc(-2px - 5px);
  margin-left: ${(props) => (props.first ? '5px' : '0')};
  border-top: 2px solid ${(props) => props.theme.violet.string()};
  position: absolute;
  width: calc(100% + 2 * 2px + 2 * 5px); // Add border and outline width.
`;

const PopupTab = styled.button<PopupTabProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  border: 2px solid ${(props) => props.theme.violet.string()};
  border-radius: ${(props) => props.theme.inputBorderRadius};
  cursor: pointer;
  font-family: ${(props) => props.theme.inputFontFamily};
  font-size: 1rem;
  left: calc(-1rem - 2px);
  margin: auto 0.6rem;
  outline: 5px solid ${(props) => props.theme.white.string()};
  padding: 1rem;
  position: relative;
  text-transform: uppercase;
  top: -3.8rem;
  z-index: ${(props) => (props.active ? 1 : -1)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${PopupTabOverlay} {
    display: ${(props) => (props.active ? 'none' : 'inline-block')};
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 1rem;

  svg {
    margin: -5px -10px -9px;
  }
`;

export function Popup({ initialView, onClose, profile }: PopupProps) {
  const [view, setView] = useState(initialView ?? 'details');
  const colorProps: ColorProps = {
    lavender: view === 'details',
    lightPurple: view === 'avatar',
    pink: view === 'password',
    mint: view === 'parent',
  };

  return (
    <BlurredBackground>
      <PopupContainer {...colorProps}>
        <PopupTab
          active={view === 'details'}
          lavender
          onClick={() => setView('details')}>
          Update Details
          <PopupTabOverlay first {...colorProps} />
        </PopupTab>

        <PopupTab
          active={view === 'avatar'}
          lightPurple
          onClick={() => setView('avatar')}>
          Update Avatar
          <PopupTabOverlay {...colorProps} />
        </PopupTab>

        <PopupTab
          active={view === 'password'}
          onClick={() => setView('password')}
          pink>
          Update Password
          <PopupTabOverlay {...colorProps} />
        </PopupTab>

        <PopupTab
          active={view === 'parent'}
          mint
          onClick={() => setView('parent')}>
          Parent/Guardian
          <PopupTabOverlay {...colorProps} />
        </PopupTab>

        <CloseButton onClick={onClose}>
          <X size={30} />
        </CloseButton>

        {view === 'details' && (
          <TabBody>
            <DetailsForm onClose={onClose} profile={profile} />
          </TabBody>
        )}
        {view === 'avatar' && (
          <TabBody>
            <EditAvatar onClose={onClose} profile={profile} />
          </TabBody>
        )}
        {view === 'password' && (
          <TabBody>
            <PasswordForm />
          </TabBody>
        )}
        {view === 'parent' && (
          <TabBody>
            <ParentForm />
          </TabBody>
        )}
      </PopupContainer>
    </BlurredBackground>
  );
}
