import { useEffect } from 'react';
import { Board, Difficulty, Mark, Winner } from '../types';
import { easyBot } from '../utils/easyBot';
import { hardBot } from '../utils/hardBot';
import { mediumBot } from '../utils/mediumBot';

/** Places a mark on the board programmatically. */
export function useBotTurn(
  board: Board,
  difficulty: Difficulty,
  playerMark: Mark,
  playerTurn: boolean,
  winner: Winner,
  onBotTurnEnd: (boardIndex: number) => void,
) {
  useEffect(() => {
    if (!playerMark) {
      return;
    }

    if (playerTurn) {
      return;
    }

    if (winner) {
      return;
    }

    let boardIndex;

    if (difficulty === Difficulty.Hard) {
      boardIndex = hardBot(board, playerMark);
    } else if (difficulty === Difficulty.Medium) {
      boardIndex = mediumBot(board);
      console.log('BOARD INDEX:', boardIndex);
    } else {
      boardIndex = easyBot(board);
    }

    onBotTurnEnd(boardIndex);

    // The effect does not depend on onBotTurnEnd.
    // eslint-disable-next-line
  }, [board, playerMark, playerTurn, winner]);
}
