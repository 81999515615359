import { SUPPORTED_BADGES } from 'src/libraries/avatars';
import { Course } from 'src/state';

/**
 * Returns an array of {@link SUPPORTED_BADGES} plus corresponding messages
 * based on the student's completed courses and lessons.
 */
export function getProfileBadges(courses: Course[]) {
  const messages: string[] = [];
  const numberOfBadges = courses.reduce((sum, course) => {
    const numberOfCompletedLessons = course.mergedLessons.reduce(
      (sum, lesson) => (lesson.completed ? sum + 1 : sum),
      0,
    );

    if (numberOfCompletedLessons) {
      messages.push(
        `You've completed ${Math.round(course.percentage)}% of ${course.name}.`,
      );
    }

    const courseCompleted = course.completed ? 1 : 0;

    if (courseCompleted) {
      messages.push(`Bonus for successfully completing ${course.name}.`);
    }

    return sum + numberOfCompletedLessons + courseCompleted;
  }, 0);

  return { badges: SUPPORTED_BADGES.slice(0, numberOfBadges), messages };
}
