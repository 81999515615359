import { useEffect } from 'react';
import { Board, Mark, Winner } from '../types';

/** Adds a delay before showing the end menu. */
export function useEndMatch(
  board: Board,
  playerMark: Mark,
  winner: Winner,
  changeResult: (result: string) => void,
  endMatch: () => void,
) {
  useEffect(() => {
    function delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (winner) {
      changeResult(winner.marks[0] === playerMark ? 'You win!' : 'You lose!');
      delay(1600).then(() => endMatch());
    } else if (board.every(Boolean)) {
      changeResult("It's a tie!");
      delay(1600).then(() => endMatch());
    }

    // The effect does not depend on endMatch.
    // eslint-disable-next-line
  }, [board, playerMark, winner]);
}
