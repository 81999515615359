import { Board } from '../types';

/** Returns a random board index with a blank mark. */
export function mediumBot(board: Board): number {
  const blankMarkIndices = board
    .map((mark, index) => (mark ? -1 : index))
    .filter((num) => num > -1);

  const randomNum = Math.trunc(Math.random() * blankMarkIndices.length);

  return blankMarkIndices[randomNum];
}
