import ClipboardIcon from 'src/assets/icons/assignment.svg';
import { useAppState } from 'src/state';
import styled from 'styled-components';
import { NextButton } from '../layout/nav/Next';
import { PreviousButton } from '../layout/nav/Previous';
import { CalendarLabel } from '../layout/table/CalendarLabel';
import { CalendarTitle } from '../layout/table/CalendarTitle';
import { HourCell } from '../layout/table/HourCell';
import { CalendarViewProps, DAYS_OF_WEEK, HOURS, MONTHS } from '../types';
import { getNextWeekDate } from '../utils/getNextWeekDate';
import { getPreviousWeekDate } from '../utils/getPreviousWeekDate';
import { parseToCustomDateObject } from '../utils/parseToCustomDateObject';
import { parseToMeridiem } from '../utils/parseToMeridiem';
import { getCalendarWeek } from './getCalendarWeek';
import { WeekCard } from './WeekCard';

const CalendarGrid = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

const DayOfWeek = styled.div`
  padding: 5px 0;
  text-align: center;
`;

export function CalendarWeekView({
  selectedDate,
  onDateUpdate,
}: CalendarViewProps) {
  const { registeredCourses } = useAppState();
  const lessons = registeredCourses.map((c) => c.lessons).flat();
  const WEEK = getCalendarWeek(selectedDate);

  /** Determines the month(s) and days for the calendar label. */
  function setCalendarTitle() {
    const startDate = parseToCustomDateObject(WEEK[0]);
    const endDate = parseToCustomDateObject(WEEK[4]);

    if (startDate.month === endDate.month) {
      return `${MONTHS[startDate.month]} ${startDate.dayOfMonth}-${
        endDate.dayOfMonth
      }`;
    }

    return `${MONTHS[startDate.month]} ${startDate.dayOfMonth} - ${
      MONTHS[endDate.month]
    } ${endDate.dayOfMonth}`;
  }

  function goToPreviousWeek() {
    onDateUpdate(getPreviousWeekDate(selectedDate));
  }

  function goToNextWeek() {
    onDateUpdate(getNextWeekDate(selectedDate));
  }

  return (
    <>
      <CalendarLabel>
        <CalendarTitle>{setCalendarTitle()}</CalendarTitle>
        <div>
          <PreviousButton onClick={goToPreviousWeek} />
          <NextButton onClick={goToNextWeek} />
        </div>
      </CalendarLabel>

      <CalendarGrid>
        <div></div>
        {WEEK.map((date, index) => (
          <DayOfWeek key={date}>
            {parseToCustomDateObject(date).dayOfMonth}{' '}
            {DAYS_OF_WEEK[index].substring(0, 3)}
          </DayOfWeek>
        ))}

        {HOURS.map((hour, index) => (
          <HourCell gridRow={`${index + 2} / ${index + 3}`} key={hour}>
            {parseToMeridiem(hour)}
          </HourCell>
        ))}

        {WEEK.map((date, index) => {
          const firstRow = 2; // Row 1 is occupied by days of the week.
          const { dayOfMonth, month, year } = parseToCustomDateObject(date);
          const todayLesson = lessons.find(
            (l) =>
              l.startTimestamp &&
              l.startTimestamp.getDate() === dayOfMonth &&
              l.startTimestamp.getMonth() === month &&
              l.startTimestamp.getFullYear() === year,
          );

          if (todayLesson) {
            const rowStart = todayLesson.startTimestamp
              ? todayLesson.startTimestamp.getHours() - HOURS[0] + firstRow
              : firstRow;
            const rowEnd = todayLesson.endTimestamp
              ? todayLesson.endTimestamp.getHours() +
                (todayLesson.endTimestamp.getMinutes() >= 30 ? 1 : 0) -
                HOURS[0] +
                firstRow
              : rowStart + firstRow;

            return (
              <WeekCard
                courseId={todayLesson.getCourseId(registeredCourses)}
                gridColumn={`${index + 2} / ${index + 3}`}
                gridRow={`${rowStart} / ${rowEnd}`}
                iconSource={ClipboardIcon}
                key={date}
                lessonId={todayLesson.lessonId}
                title={todayLesson.name}
              />
            );
          }

          return null;
        })}
      </CalendarGrid>
    </>
  );
}
