import { getMonthDayCount } from './getMonthDayCount';

/**
 * Validates a YYYY-MM-DD date string.
 * The numbers must be possible according to the calendar
 * (eg. December 32, 2021 (ie. 2021-12-32) does not exist).
 */
export function isValidYearMonthDayDate(date: string): boolean {
  const pattern = /^\d{4}-\d{2}-\d{2}$/;

  if (!pattern.test(date)) {
    return false;
  }

  const [year, month, day] = date.split('-').map((str) => Number(str));

  if (month < 1 || month > 12) {
    return false;
  }

  const monthDayCount = getMonthDayCount(month - 1, year);

  if (day < 1 || day > monthDayCount) {
    return false;
  }

  return true;
}
