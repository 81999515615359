import { GraphCalendarEvent } from 'src/libraries/graph';
import {
  MctCourseContentItemExtended,
  MctCourseEntity,
} from 'src/libraries/mct';
import { Course } from './course';
import { LessonResource } from './lessonResource';
import { filterSupportedLessonResources } from './utils';

/**
 * Represents a single lesson with a start and end time.
 */
export class Lesson {
  static fromMctEntity(
    lesson: MctCourseEntity,
    resources?: MctCourseContentItemExtended[],
    event?: GraphCalendarEvent,
  ): Lesson {
    return new Lesson(
      lesson.ProductId,
      lesson.CourseName,
      lesson.CourseDescription,
      lesson.CourseImage,
      lesson.CompletionPercentage,
      lesson.IsRegistered,
      event?.start ?? null,
      event?.end ?? null,
      (resources ?? [])
        .filter(filterSupportedLessonResources)
        .map((r) => LessonResource.fromMctEntity(r)),
    );
  }

  static forPlaceholder(id: number, name: string): Lesson {
    return new Lesson(id, name, '', '', 0, false, null, null, []);
  }

  /** Unique identifier for this specific lesson occurrence. */
  get id(): string {
    return this.startTimestamp
      ? `${this.lessonId}-${this.startTimestamp.valueOf()}`
      : this.lessonId.toString(10);
  }

  /** Determines if the start timestamp is in the past. */
  get started(): boolean {
    return (
      !!this.startTimestamp &&
      this.startTimestamp.valueOf() <= new Date().valueOf()
    );
  }

  /** Determines if the end timestamp is in the past. */
  get completed(): boolean {
    return (
      !!this.endTimestamp && this.endTimestamp.valueOf() < new Date().valueOf()
    );
  }

  /** Returns the corresponding {@link Course} ID. */
  getCourseId(courses: Course[]): number {
    const course = courses.find((course) =>
      course.lessons.find((l) => l.id === this.id),
    );

    return course?.id || 0;
  }

  /** Returns a copy of this {@link Lesson} with the provided resources. */
  withResources(resources: LessonResource[]): Lesson {
    return new Lesson(
      this.lessonId,
      this.name,
      this.description,
      this.image,
      this.percentage,
      this.isRegistered,
      this.startTimestamp,
      this.endTimestamp,
      [...resources],
    );
  }

  /** Returns a copy of this {@link Lesson} with the provided schedule. */
  withSchedule(schedule: GraphCalendarEvent): Lesson {
    return new Lesson(
      this.lessonId,
      this.name,
      this.description,
      this.image,
      this.percentage,
      this.isRegistered,
      schedule.start,
      schedule.end,
      this.resources,
    );
  }

  /** Returns a copy of this {@link Lesson} with the provided startTimestamp. */
  withStartTimeStamp(startTimestamp: Date | null): Lesson {
    return new Lesson(
      this.lessonId,
      this.name,
      this.description,
      this.image,
      this.percentage,
      this.isRegistered,
      startTimestamp,
      this.endTimestamp,
      this.resources,
    );
  }

  /** Returns a copy of this {@link Lesson} with the provided endTimestamp. */
  withEndTimeStamp(endTimestamp: Date | null): Lesson {
    return new Lesson(
      this.lessonId,
      this.name,
      this.description,
      this.image,
      this.percentage,
      this.isRegistered,
      this.startTimestamp,
      endTimestamp,
      this.resources,
    );
  }

  constructor(
    readonly lessonId: number,
    readonly name: string,
    readonly description: string,
    readonly image: string,
    readonly percentage: number,
    readonly isRegistered: boolean,
    readonly startTimestamp: Date | null,
    readonly endTimestamp: Date | null,
    readonly resources: LessonResource[],
  ) {}
}
