import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getPreviousMonth } from './getPreviousMonth';

/** Gets the first day of the previous month. */
export function getPreviousMonthDate(date: CustomDateObject): CustomDateObject {
  const previousMonth = getPreviousMonth(date.month, date.year);

  return createCustomDateObject({
    day: 1,
    month: previousMonth.month,
    year: previousMonth.year,
  });
}
