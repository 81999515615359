import { Board, BoardSequence } from '../types';

/**
 * Returns the indices and marks of one of the board's diagonals.
 * @param diagonal 0 gets the diagonal from the top left to the bottom right.
 * @param diagonal > 0 gets the diagonal from the top right to the bottom left.
 */
export function getDiagonal(diagonal: number, board: Board): BoardSequence {
  const secondIndex = 4;
  let firstIndex = 0;
  let thirdIndex = 8;

  if (diagonal) {
    firstIndex = 2;
    thirdIndex = 6;
  }

  return {
    indices: [firstIndex, secondIndex, thirdIndex],
    marks: [board[firstIndex], board[secondIndex], board[thirdIndex]],
  };
}
