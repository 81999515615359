import { Loading } from 'src/shared';
import { Course } from 'src/state';
import styled from 'styled-components';
import { DiscoverCard } from './DiscoverCard';

interface DiscoverProps {
  courses: Course[];
  isLoading: boolean;
}

const DiscoverWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export function Discover({ courses, isLoading }: DiscoverProps) {
  if (isLoading) {
    return <Loading />;
  }

  if (courses.length < 1) {
    return <div>No courses to display for now. Please check back later!</div>;
  }

  return (
    <DiscoverWrapper>
      {courses.map((course) => (
        <DiscoverCard key={course.id} course={course} />
      ))}
    </DiscoverWrapper>
  );
}
