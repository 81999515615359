import styled from 'styled-components';

export const LoginFormWrapper = styled.div`
  order: 0;
  text-align: center;
  width: 400px;

  @media screen and (min-width: 1024px) {
    max-width: 600px;
    width: 100%;
  }
`;
