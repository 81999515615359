import { Board, BoardSequence } from '../types';

/** Returns the indices and marks of one of the board's rows. */
export function getRow(row: number, board: Board): BoardSequence {
  const firstIndex = row * 3;
  const secondIndex = firstIndex + 1;
  const thirdIndex = firstIndex + 2;

  return {
    indices: [firstIndex, secondIndex, thirdIndex],
    marks: [board[firstIndex], board[secondIndex], board[thirdIndex]],
  };
}
