import raisedHandImg from 'src/assets/icons/raised-hand.png';
import { Card } from 'src/shared';
import styled from 'styled-components';
import { MemberType } from '../types';

interface ButtonProps {
  active?: boolean;
}

export interface SelectorButtonProps extends ButtonProps {
  memberType: MemberType;
  onClick: () => void;
}

const Button = styled(Card).attrs<ButtonProps>(({ active }) => ({
  as: 'button',
  height: '135px',
  lightPurple: !active,
  teal: active,
  width: '135px',
}))<ButtonProps>`
  align-items: flex-end;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4px 8px 4px;
  position: relative;
`;

const ICON_SIZE = 36;

const Icon = styled.div`
  background-color: ${(props) => props.theme.purple.string()};
  border-radius: 50%;
  left: -${ICON_SIZE / 2}px;
  padding: 5px;
  position: absolute;
  top: -${ICON_SIZE / 2}px;
`;

const Text = styled.span`
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 28px;
`;

export function SelectorButton(props: SelectorButtonProps) {
  return (
    <Button {...props}>
      {props.active && (
        <Icon>
          <img
            alt=""
            height={ICON_SIZE}
            src={raisedHandImg}
            width={ICON_SIZE}
          />
        </Icon>
      )}
      <Text>I&apos;m a</Text>
      <Text>{props.memberType}</Text>
    </Button>
  );
}
