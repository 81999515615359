import { FormEvent } from 'react';
import { Button } from 'src/shared';
import styled from 'styled-components';

interface SaveProps {
  onCancel: (event: FormEvent<HTMLButtonElement>) => void;
  onSave: (event: FormEvent<HTMLButtonElement>) => void;
}

const SaveWrapper = styled.div`
  padding: 2rem 0;
`;

export function Save({ onCancel, onSave }: SaveProps) {
  return (
    <SaveWrapper>
      <Button onClick={onSave} type="button">
        Save
      </Button>
      <Button onClick={onCancel} type="button">
        Cancel
      </Button>
    </SaveWrapper>
  );
}
