import { Emoji, EmojiEnum, SUPPORTED_EMOJIS } from 'src/libraries/avatars';
import { getColorByHash } from 'src/shared';
import { BadgeList } from '../BadgeList';
import { BadgeWrapper } from '../BadgeWrapper';
import { EditorWrapper } from '../EditorWrapper';
import { AvatarEditorPreview, AVATAR_PREVIEW_SIZE } from '../Preview';

interface EditEmojiAvatarProps {
  emoji: EmojiEnum;
  onUpdate: (emoji: EmojiEnum) => void;
}

export function EditEmojiAvatar({ emoji, onUpdate }: EditEmojiAvatarProps) {
  const emojiColor = getColorByHash(emoji);
  const emojiColorProp = { [emojiColor]: true };

  return (
    <EditorWrapper>
      <AvatarEditorPreview {...emojiColorProp}>
        <Emoji emoji={emoji} size={`${AVATAR_PREVIEW_SIZE}px`} />
      </AvatarEditorPreview>

      <BadgeList>
        {SUPPORTED_EMOJIS.map((EMOJI) => (
          <BadgeWrapper
            key={EMOJI}
            onClick={() => onUpdate(EMOJI)}
            selected={EMOJI === emoji}>
            <Emoji emoji={EMOJI} size="60px" />
          </BadgeWrapper>
        ))}
      </BadgeList>
    </EditorWrapper>
  );
}
