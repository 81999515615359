import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getMonthDayCount } from './getMonthDayCount';
import { getPreviousMonth } from './getPreviousMonth';

/** Gets the date that comes seven days before the param date. */
export function getPreviousWeekDate(date: CustomDateObject): CustomDateObject {
  const previousWeekDay = date.dayOfMonth - 7;
  // If the date that comes seven days before is in the previous month.
  if (previousWeekDay < 1) {
    const previousMonth = getPreviousMonth(date.month, date.year);
    const previousMonthDayCount = getMonthDayCount(
      previousMonth.month,
      previousMonth.year,
    );

    return createCustomDateObject({
      day: previousWeekDay + previousMonthDayCount,
      month: previousMonth.month,
      year: previousMonth.year,
    });
  }

  return createCustomDateObject({
    day: previousWeekDay,
    month: date.month,
    year: date.year,
  });
}
