import { MctNotificationEntity } from 'src/libraries/mct';

/**
 * Represents a single notification meant for the authenticated student.
 */
export class Notification {
  static fromMctEntity(notification: MctNotificationEntity) {
    const isoDateArray = notification.CreateDateTime.split('T');
    const date = isoDateArray[0];
    const time = isoDateArray[1].split('.')[0];
    const [year, month, day] = date.split('-');
    const [hours, minutes, seconds] = time.split(':');

    return new Notification(
      notification.NotificationId,
      notification.Message,
      new Date(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes),
        Number(seconds),
      ),
      notification.Status,
    );
  }

  constructor(
    readonly id: number,
    readonly message: string,
    readonly date: Date,
    readonly status: string,
  ) {}
}
