import blackCircleImg from 'src/assets/black-circle.png';
import { BadgeEnum, BADGE_SOURCES } from './badge.generated';

interface BadgeProps {
  badge: BadgeEnum;
  size: string;
}

export function BadgeAvatar({ badge, size }: BadgeProps) {
  const src = badge in BADGE_SOURCES ? BADGE_SOURCES[badge] : blackCircleImg;

  return <img alt={`${badge} badge`} height={size} src={src} width={size} />;
}
