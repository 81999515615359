import styled from 'styled-components';

interface SecondHeadingProps {
  margin?: string;
}

export const SecondHeading = styled.h2<SecondHeadingProps>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 30px;
  line-height: 1.3;
  margin: ${(props) => props.margin || '0.8rem auto'};
`;
