import { Form, Input, Label } from './FormComponents';

export function PasswordForm() {
  return (
    <Form>
      <Label htmlFor="password">Password</Label>
      <Input id="password" name="password" type="password" />

      <Label htmlFor="confirm">Confirm Password</Label>
      <Input id="confirm" name="confirm" type="password" />
    </Form>
  );
}
