import { MsalProvider } from '@azure/msal-react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { msalInstance } from '../../libraries/msal';
import { theme } from '../../shared';
import { GlobalStyle } from './GlobalStyle';
import { Router } from './Router';

export function HomeroomApp() {
  const description = 'HYPE Homeroom';
  const keywords = 'hype homeroom';
  const robots =
    process.env.NODE_ENV === 'production'
      ? 'index, follow'
      : 'noindex, noimageindex, nofollow, noarchive, nocache';

  return (
    <>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content={robots} />
        <meta name="coverage" content="Worldwide" />
        <meta property="og:type" content="website" />
        <link rel="stylesheet" href="/css/brown-font.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
        />
        <link rel="stylesheet" href="https://use.typekit.net/uuu0xqx.css" />
        <title>HYPE Homeroom</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <MsalProvider instance={msalInstance}>
          <Router />
        </MsalProvider>
      </ThemeProvider>
    </>
  );
}
