import { Course } from 'src/state';
import styled from 'styled-components';
import { LessonDetails } from './details/LessonDetails';
import { LessonList } from './list/LessonList';

interface LessonProps {
  course: Course;
  lessonId?: number;
}

const Wrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
  }
`;

export function LessonListAndDetails({ course, lessonId }: LessonProps) {
  const selectedLesson =
    course.mergedLessons.find((l) => l.lessonId === lessonId) ||
    course.currentLesson;

  return (
    <Wrapper>
      {course.mergedLessons.length > 1 && (
        <LessonList
          courseId={course.id}
          mergedLessons={course.mergedLessons}
          selectedLesson={selectedLesson}
        />
      )}
      <LessonDetails course={course} selectedLesson={selectedLesson} />
    </Wrapper>
  );
}
