import {
  Avatar,
  MctProfileData,
  MctUIProfileDataField,
  MctUiProfileField,
} from 'src/libraries/mct';

/**
 * Represents the profile for a student.
 */
export class Profile {
  /** Blank profile */
  static readonly DEFAULT_PROFILE = new Profile(
    '',
    '',
    '',
    Avatar.DEFAULT,
    '',
    '',
    '',
  );

  /** Constructs a {@link Profile} entity from an MCT profile data response. */
  static fromMctProfileData(profile: MctProfileData) {
    const email = profile.ProfileFields.find(dataField('Contact'));
    const firstName = profile.ProfileFields.find(dataField('First Name'));
    const lastName = profile.ProfileFields.find(dataField('Last Name'));
    const avatar = profile.ProfileFields.find(dataField('HomeroomAvatar'));
    const bio = profile.ProfileFields.find(dataField('HomeroomBio'));
    const hypeText = profile.ProfileFields.find(dataField('HomeroomHypeText'));
    const favoriteEmoji = profile.ProfileFields.find(
      dataField('HomeroomEmoji'),
    );

    return new Profile(
      email?.Value ?? '',
      firstName?.Value ?? '',
      lastName?.Value ?? '',
      Avatar.parse(avatar?.Value ?? ''),
      bio?.Value ?? '',
      hypeText?.Value ?? '',
      favoriteEmoji?.Value ?? '',
    );
  }

  /**
   * Constructs a {@link Profile} entity from an MCT organization-based user
   * profile response.
   */
  static fromMctUiProfileFields(fields: MctUiProfileField[]) {
    const email = fields.find(uiField('Contact'));
    const firstName = fields.find(uiField('First Name'));
    const lastName = fields.find(uiField('Last Name'));
    const avatar = fields.find(uiField('HomeroomAvatar'));
    const bio = fields.find(uiField('HomeroomBio'));
    const hypeText = fields.find(uiField('HomeroomHypeText'));
    const favoriteEmoji = fields.find(uiField('HomeroomEmoji'));

    return new Profile(
      email?.Value ?? '',
      firstName?.Value ?? '',
      lastName?.Value ?? '',
      Avatar.parse(avatar?.Value ?? ''),
      bio?.Value ?? '',
      hypeText?.Value ?? '',
      favoriteEmoji?.Value ?? '',
    );
  }

  /** Serializes this {@link Profile} instance into MCT's JSON format. */
  toMctJson() {
    return {
      Contact: this.email,
      'First Name': this.firstName,
      'Last Name': this.lastName,
      HomeroomAvatar: this.avatar.encode(),
      HomeroomBio: this.bio,
      HomeroomHypeText: this.hypeText,
      HomeroomEmoji: this.favoriteEmoji,
    };
  }

  /** Creates a copy of this {@link Profile} with the updated avatar. */
  withAvatar(avatar: Avatar) {
    return new Profile(
      this.email,
      this.firstName,
      this.lastName,
      avatar,
      this.bio,
      this.hypeText,
      this.favoriteEmoji,
    );
  }

  /** Creates a copy of this {@link Profile} with the updated details. */
  withDetails(
    email: string,
    firstName: string,
    lastName: string,
    bio: string,
    hypeText: string,
  ) {
    return new Profile(
      email,
      firstName,
      lastName,
      this.avatar,
      bio,
      hypeText,
      this.favoriteEmoji,
    );
  }

  /** Creates a copy of this {@link Profile}. */
  copy() {
    return new Profile(
      this.email,
      this.firstName,
      this.lastName,
      this.avatar.copy(),
      this.bio,
      this.hypeText,
      this.favoriteEmoji,
    );
  }

  /** Compares this {@link Profile} instance to another. */
  isEqual(other: Profile) {
    return (
      other &&
      this.email === other.email &&
      this.firstName === other.firstName &&
      this.lastName === other.lastName &&
      this.avatar.encode() === other.avatar.encode() &&
      this.bio === other.bio &&
      this.hypeText === other.hypeText &&
      this.favoriteEmoji === other.favoriteEmoji
    );
  }

  constructor(
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly avatar: Avatar,
    readonly bio: string,
    readonly hypeText: string,
    readonly favoriteEmoji: string,
  ) {}
}

/** Helper function for retrieving a profile data field by name. */
function dataField(fieldName: string) {
  return ({ Name }: MctUIProfileDataField) => Name === fieldName;
}

/** Helper function for retrieving a UI profile field by name. */
function uiField(fieldName: string) {
  return ({ Name }: MctUiProfileField) =>
    (Name || []).some(({ Value }) => Value === fieldName);
}
