import styled from 'styled-components';

export const ProgramsWrapper = styled.div`
  height: 100%;
  max-width: calc(100% - 2rem);
  order: 1;
  padding: 1rem;
  width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 600px;
    order: -1;
  }
`;
