import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { logDebug } from '../../utils';
import { MSAL_SCOPES } from './constants';
import { msalInstance } from './instance';

export interface MsalTokens {
  accessToken: string;
  idToken: string;
}

// Promise representing the access and ID tokens. This variable is scoped to
// this file, and is used to keep a single reference to tokens promise.
let tokensPromise: Promise<MsalTokens> | null = null;

/** Clears the access token singleton. */
export function clearTokens() {
  tokensPromise = null;
}

/** Updates the access token singleton. */
export function updateTokens({ accessToken, idToken }: AuthenticationResult) {
  logDebug('Updated MSAL access token', accessToken);
  logDebug('Updated MSAL ID token', idToken);

  tokensPromise = Promise.resolve({ accessToken, idToken });
}

/**
 * Returns a reference to the access token singleton, creating it if it doesn't
 * already exist asking the user to authenticate if needed.
 */
async function checkAccessToken(): Promise<MsalTokens> {
  // Retrieve existing token silently.
  try {
    const { accessToken, idToken } = await msalInstance.acquireTokenSilent({
      account: msalInstance.getActiveAccount()!,
      scopes: MSAL_SCOPES,
    });

    logDebug('Silent MSAL tokens', accessToken, idToken);

    return { accessToken, idToken };
  } catch (error) {
    if (!(error instanceof InteractionRequiredAuthError)) {
      throw new Error(`Could not retrieve MSAL token: ${error}`);
    }
  }

  // Acquire new token through popup form.
  try {
    const { accessToken, idToken } = await msalInstance.acquireTokenPopup({
      scopes: MSAL_SCOPES,
    });

    logDebug('Popup MSAL tokens', accessToken, idToken);

    return { accessToken, idToken };
  } catch (error) {
    if ((error as any).errorCode === 'popup_window_error') {
      alert(
        'It looks like popups are blocked in your browser. Please enable them to login.',
      );

      return { accessToken: '', idToken: '' };
    }

    throw new Error(`Could not retrieve MSAL token: ${error}`);
  }
}

/**
 * Retrieves MSAL access and ID tokens. This function can safely be called many
 * times.
 */
export async function getTokens(): Promise<MsalTokens> {
  if (!tokensPromise) {
    tokensPromise = checkAccessToken();
  }

  return tokensPromise;
}
