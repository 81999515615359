/** Determines if the selected year is a leap year. */
export function isLeapYear(year: number): 1 | 0 {
  // If year is divisible by 400, it is a leap year.
  // Otherwise, if year is divisible by 4, but not by 100, it is a leap year.
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    return 1;
  }

  return 0;
}
