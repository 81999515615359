import styled from 'styled-components';

interface TabBodyProps {
  children: React.ReactNode;
}

const TabBodyWrapper = styled.div`
  padding: 0 1.5rem 1rem;
`;

export function TabBody({ children }: TabBodyProps) {
  return <TabBodyWrapper>{children}</TabBodyWrapper>;
}
