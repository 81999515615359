import ClipboardIcon from 'src/assets/icons/assignment.svg';
import { useAppState } from 'src/state';
import styled from 'styled-components';
import { NextButton } from '../layout/nav/Next';
import { PreviousButton } from '../layout/nav/Previous';
import { CalendarTitle } from '../layout/table/CalendarTitle';
import { CalendarViewProps, DAYS_OF_WEEK, MONTHS } from '../types';
import { createCalendar } from '../utils/createCalendar';
import { getNextMonthDate } from '../utils/getNextMonthDate';
import { getPreviousMonthDate } from '../utils/getPreviousMonthDate';
import { parseToCustomDateObject } from '../utils/parseToCustomDateObject';
import { MonthCard } from './MonthCard';

const CALENDAR_CELL_HEIGHT = 128; // in px
const CALENDAR_CELL_WIDTH = 125; // in px

/** Contains the days of the week and the days of the month. */
const CalendarGrid = styled.div`
  color: ${(props) => props.theme.violet.string()};
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin: 0 auto;
  width: ${CALENDAR_CELL_WIDTH * 7}px;
`;

const DayOfWeek = styled.div`
  background-color: ${(props) => props.theme.lavender.string()};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: ${CALENDAR_CELL_WIDTH}px;
`;

const CalendarDay = styled.div<{ isThisMonth: boolean; isWeekend: boolean }>`
  background-color: ${(props) => props.theme.lightPurple.string()};
  border: 1px solid ${(props) => props.theme.lavender.string()};
  box-sizing: border-box;
  height: ${CALENDAR_CELL_HEIGHT}px;
  opacity: ${(props) => (props.isWeekend || !props.isThisMonth ? 0.7 : 1)};
  position: relative;
  width: ${CALENDAR_CELL_WIDTH}px;
`;

const DayOfMonth = styled.h3`
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 27px;
  margin: 0;
  position: absolute;
  right: 10px;
  top: 5px;
`;

/** Contains the {@link CalendarTitle} and the chevrons. */
const CalendarLabel = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export function CalendarMonthView({
  selectedDate,
  onDateUpdate,
}: CalendarViewProps) {
  const { registeredCourses } = useAppState();
  const lessons = registeredCourses.map((c) => c.lessons).flat();
  const lessonsThisMonth = lessons.filter(
    (l) => l.startTimestamp?.getMonth() === selectedDate.month,
  );

  const CALENDAR = createCalendar(selectedDate);

  function goToPreviousMonth() {
    onDateUpdate(getPreviousMonthDate(selectedDate));
  }

  function goToNextMonth() {
    onDateUpdate(getNextMonthDate(selectedDate));
  }

  return (
    <>
      <CalendarLabel>
        <CalendarTitle>
          {MONTHS[selectedDate.month]} {selectedDate.year}
        </CalendarTitle>
        <div>
          <PreviousButton onClick={goToPreviousMonth} />
          <NextButton onClick={goToNextMonth} />
        </div>
      </CalendarLabel>

      <CalendarGrid>
        {DAYS_OF_WEEK.map((dayOfWeek) => (
          <DayOfWeek key={dayOfWeek}>{dayOfWeek.substring(0, 3)}</DayOfWeek>
        ))}

        {CALENDAR.map((week) => {
          return week.map((date) => {
            const { dayOfMonth, dayOfWeek, month } =
              parseToCustomDateObject(date);
            const todayLesson = lessonsThisMonth.find(
              (l) =>
                l.startTimestamp?.getDate() === dayOfMonth &&
                l.startTimestamp?.getMonth() === month,
            );

            return (
              <CalendarDay
                key={date}
                isThisMonth={month === selectedDate.month}
                isWeekend={dayOfWeek === 5 || dayOfWeek === 6}>
                <DayOfMonth>{dayOfMonth}</DayOfMonth>
                {todayLesson ? (
                  <MonthCard
                    courseId={todayLesson.getCourseId(registeredCourses)}
                    iconSource={ClipboardIcon}
                    lessonId={todayLesson.lessonId}
                    title={todayLesson.name}
                  />
                ) : null}
              </CalendarDay>
            );
          });
        })}
      </CalendarGrid>
    </>
  );
}
