import { Button } from 'src/shared';
import styled from 'styled-components';
import { fadeInAnimation } from './fadeInAnimation';

interface EndMenuProps {
  backToStartMenu: () => void;
  playAgain: () => void;
}

const Wrapper = styled.div`
  animation: ${fadeInAnimation};
  width: 100%;
`;

const Message = styled.h3`
  color: ${(props) => props.theme.violet.string()};
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 32px;
  margin-bottom: 1rem;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

export function EndMenu({ backToStartMenu, playAgain }: EndMenuProps) {
  return (
    <Wrapper>
      <Message>Congratulations!</Message>
      <ButtonGroup>
        <Button onClick={playAgain} pink>
          Play Again
        </Button>
        <Button onClick={backToStartMenu} yellow>
          Back To Start Menu
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}
