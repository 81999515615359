export enum LessonResourceType {
  Unknown = 'unknown',
  // Downloadable document.
  Document = 'document',
  // Embeddable video.
  EmbeddableVideo = 'video',
  // Hosted video, meant to be viewed on external website.
  ExternalVideo = 'video-ext',
  // PDF document, can often be viewed in-browser.
  PDF = 'pdf',
  // Quiz and surveys.
  Quiz = 'quiz',
  // YouTube video.
  YouTube = 'youtube',
}
