/** Converts numerical year, month, day into YYYY-MM-DD date string. */
export function parseToYearMonthDay(
  year: number,
  month: number,
  day: number,
): string {
  const monthStr = (month + 1).toString().padStart(2, '0');
  const dayStr = day.toString().padStart(2, '0');

  return `${year}-${monthStr}-${dayStr}`;
}
