import ClipboardIcon from 'src/assets/icons/assignment.svg';
import { useAppState } from 'src/state';
import styled from 'styled-components';
import { NextButton } from '../layout/nav/Next';
import { PreviousButton } from '../layout/nav/Previous';
import { CalendarLabel } from '../layout/table/CalendarLabel';
import { CalendarTitle } from '../layout/table/CalendarTitle';
import { HourCell } from '../layout/table/HourCell';
import { CalendarViewProps, DAYS_OF_WEEK, HOURS, MONTHS } from '../types';
import { getNextDate } from '../utils/getNextDate';
import { getPreviousDate } from '../utils/getPreviousDate';
import { parseToMeridiem } from '../utils/parseToMeridiem';
import { DayCard } from './DayCard';

const CalendarGrid = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  display: grid;
  grid-template-columns: 100px auto;
`;

const Weekend = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 32px;
  padding: 20px 0;
  text-align: center;
`;

export function CalendarDayView({
  selectedDate,
  onDateUpdate,
}: CalendarViewProps) {
  const { registeredCourses } = useAppState();
  const lessons = registeredCourses.map((c) => c.lessons).flat();

  const todayLesson = lessons.find(
    (l) =>
      l.startTimestamp &&
      l.startTimestamp.getDate() === selectedDate.dayOfMonth &&
      l.startTimestamp.getMonth() === selectedDate.month &&
      l.startTimestamp.getFullYear() === selectedDate.year,
  );

  const rowStart = todayLesson?.startTimestamp
    ? todayLesson.startTimestamp.getHours() - HOURS[0] + 1
    : 1;
  const rowEnd = todayLesson?.endTimestamp
    ? todayLesson.endTimestamp.getHours() +
      (todayLesson.endTimestamp.getMinutes() >= 30 ? 1 : 0) -
      HOURS[0] +
      1
    : rowStart + 1;

  function goToPreviousDay() {
    onDateUpdate(getPreviousDate(selectedDate));
  }

  function goToNextDay() {
    onDateUpdate(getNextDate(selectedDate));
  }

  return (
    <>
      <CalendarLabel>
        <CalendarTitle>
          {DAYS_OF_WEEK[selectedDate.dayOfWeek]} {MONTHS[selectedDate.month]}{' '}
          {selectedDate.dayOfMonth}
        </CalendarTitle>
        <div>
          <PreviousButton onClick={goToPreviousDay} />
          <NextButton onClick={goToNextDay} />
        </div>
      </CalendarLabel>

      {selectedDate.dayOfWeek === 5 || selectedDate.dayOfWeek === 6 ? (
        <Weekend>Enjoy your weekend!</Weekend>
      ) : (
        <CalendarGrid>
          {HOURS.map((hour, index) => (
            <HourCell gridRow={`${index + 1} / ${index + 2}`} key={hour}>
              {parseToMeridiem(hour)}
            </HourCell>
          ))}

          {todayLesson && (
            <DayCard
              courseId={todayLesson.getCourseId(registeredCourses)}
              gridRow={`${rowStart} / ${rowEnd}`}
              iconSource={ClipboardIcon}
              lessonId={todayLesson.lessonId}
              title={todayLesson.name}
            />
          )}
        </CalendarGrid>
      )}
    </>
  );
}
