import { useState } from 'react';
import {
  AvatarStyle,
  AVATAR_STYLES,
  EmojiEnum,
  OpenPeepsOptions,
} from 'src/libraries/avatars';
import { Avatar, AvatarData } from 'src/libraries/mct';
import { Button } from 'src/shared';
import { Profile, useAppState } from 'src/state';
import styled from 'styled-components';
import { Save } from '../Save';
import { EditEmojiAvatar } from './emoji/EditEmojiAvatar';
import { EditOpenPeepsAvatar } from './open-peeps/EditOpenPeepsAvatar';

interface EditAvatarProps {
  onClose: () => void;
  profile: Profile;
}

const EditAvatarWrapper = styled.div`
  margin-bottom: 10px;
  width: 90%;
`;

const StyleSelector = styled.div`
  margin: 0 0 2rem 0;
  button {
    margin-right: 15px;
  }
`;

export function EditAvatar({ onClose, profile }: EditAvatarProps) {
  const [avatarStyle, setAvatarStyle] = useState<AvatarStyle>(
    profile.avatar.style,
  );
  const [avatarData, setAvatarData] = useState<AvatarData>(profile.avatar.data);
  const { onUpdateProfile } = useAppState();

  function handleUpdateEmoji(emoji: EmojiEnum) {
    setAvatarData((data) => ({ ...data, emoji }));
  }

  function handleUpdateOpenPeeps(openPeeps: OpenPeepsOptions) {
    setAvatarData((data) => ({ ...data, openPeeps }));
  }

  function handleSave() {
    onUpdateProfile(profile.withAvatar(new Avatar(avatarStyle, avatarData)));
    onClose();
  }

  return (
    <EditAvatarWrapper>
      <StyleSelector>
        {AVATAR_STYLES.map((style) => (
          <Button
            key={style}
            lavender={style === avatarStyle}
            onClick={() => setAvatarStyle(style)}>
            {style}
          </Button>
        ))}
      </StyleSelector>

      {avatarStyle === 'emoji' && (
        <EditEmojiAvatar
          emoji={avatarData.emoji}
          onUpdate={handleUpdateEmoji}
        />
      )}

      {avatarStyle === 'open-peeps' && (
        <EditOpenPeepsAvatar
          data={avatarData.openPeeps}
          onUpdate={handleUpdateOpenPeeps}
        />
      )}

      <Save onCancel={onClose} onSave={handleSave} />
    </EditAvatarWrapper>
  );
}
