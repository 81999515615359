import { MctMlxUser } from 'src/libraries/mct';
import { Group } from './group';

/**
 * Represents an authenticated user of Homeroom. This could be a student, parent
 * facilitator, and so on.
 */
export class User {
  static fromMctEntity(user: MctMlxUser) {
    return new User(
      user.Id,
      user.EmailAddress,
      user.FirstName,
      user.LastName,
      user.OrganizationId,
      [],
    );
  }

  withGroups(groups: Group[]) {
    return new User(
      this.id,
      this.email,
      this.firstName,
      this.lastName,
      this.organizationId,
      [...groups.map((g) => g.copy())],
    );
  }

  constructor(
    readonly id: number,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly organizationId: number,
    readonly groups: Group[],
  ) {}
}
