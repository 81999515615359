import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getDefaultCalendarRoute } from 'src/calendar';
import { LoginPageWrapper, StudentPageWrapper } from 'src/shared';
import CalendarPage from '../Calendar';
import CoursePage from '../Course';
import GamesPage from '../Games';
import HomePage from '../Home';
import LoginPage from '../Login';
import ProfilePage from '../Profile';

export function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<LoginPageWrapper />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<StudentPageWrapper />}>
          <Route
            path="/calendar"
            element={<Navigate to={getDefaultCalendarRoute()} />}
          />
          <Route path="/calendar/:view/:date" element={<CalendarPage />} />
          <Route path="/courses" element={<HomePage />} />
          <Route path="/courses/:courseId" element={<CoursePage />} />
          <Route path="/courses/:courseId/:lessonId" element={<CoursePage />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
        <Route index element={<Navigate to="/courses" />} />
      </Routes>
    </HashRouter>
  );
}
