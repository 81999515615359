import blackCircleImg from 'src/assets/black-circle.png';
import { Emoji, OpenPeepsAvatar } from 'src/libraries/avatars';
import { Avatar } from 'src/libraries/mct';
import { logWarning } from 'src/utils';

interface AvatarImageProps {
  avatar?: Avatar;
  size: number;
}

/**
 * Renders the avatar image based on the provided {@link Avatar} entity.
 */
export function AvatarImage({ avatar, size }: AvatarImageProps) {
  if (avatar?.style === 'emoji') {
    return <Emoji emoji={avatar.data.emoji} size={`${size}px`} />;
  }

  if (avatar?.style === 'open-peeps') {
    return <OpenPeepsAvatar options={avatar.data.openPeeps} size={size} />;
  }

  if (avatar) {
    logWarning(`Unsupported avatar style "${avatar.style}".`);
  }

  return <img alt="Avatar" height={size} src={blackCircleImg} width={size} />;
}
