import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';
import { getMonthDayCount } from './getMonthDayCount';
import { getNextMonth } from './getNextMonth';
import { getPreviousMonth } from './getPreviousMonth';
import { parseToYearMonthDay } from './parseToYearMonthDay';

/**
 * 1) Fill the first week of the calendar with the last days of the previous
 * month and the first days of the selected month.
 * 2) Continue filling the calendar with the selected month's days.
 * 3) Fill the end of the calendar with the first days of the next month.
 */
export function createCalendar(selectedDate: CustomDateObject): string[][] {
  const CALENDAR: string[][] = [];
  const monthDayCount = getMonthDayCount(selectedDate.month, selectedDate.year);

  // Get the first day of the selected month.
  const firstDayOfMonth = createCustomDateObject({
    day: 1,
    month: selectedDate.month,
    year: selectedDate.year,
  });

  // Get the next month.
  const nextMonth = getNextMonth(selectedDate.month, selectedDate.year);

  // Keeps track of the days of the selected month.
  let dayPointer = 1;
  let nextMonthDayPointer = 1;

  // Fill the first week of the CALENDAR.
  if (firstDayOfMonth.dayOfWeek > 0) {
    // Get the previous month and its day count.
    const previousMonth = getPreviousMonth(
      selectedDate.month,
      selectedDate.year,
    );
    const previousMonthDayCount = getMonthDayCount(
      previousMonth.month,
      previousMonth.year,
    );

    CALENDAR[0] = [];

    for (let i = 0; i < 7; i++) {
      if (i < firstDayOfMonth.dayOfWeek) {
        CALENDAR[0][i] = parseToYearMonthDay(
          previousMonth.year,
          previousMonth.month,
          previousMonthDayCount - firstDayOfMonth.dayOfWeek + i + 1,
        );
      } else {
        CALENDAR[0][i] = parseToYearMonthDay(
          selectedDate.year,
          selectedDate.month,
          dayPointer,
        );
        dayPointer++;
      }
    }
  }

  // Fill the rest of the CALENDAR.
  while (dayPointer <= monthDayCount) {
    const week: string[] = [];

    for (let i = 0; i < 7; i++) {
      if (dayPointer <= monthDayCount) {
        week[i] = parseToYearMonthDay(
          selectedDate.year,
          selectedDate.month,
          dayPointer,
        );
        dayPointer++;
      } else {
        // Fill the end of the CALENDAR with the first days of the next month.
        week[i] = parseToYearMonthDay(
          nextMonth.year,
          nextMonth.month,
          nextMonthDayPointer,
        );
        nextMonthDayPointer++;
      }
    }

    CALENDAR.push(week);
  }

  return CALENDAR;
}
