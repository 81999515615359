const homeroomEnv = process.env.REACT_APP_HOMEROOM_ENV ?? 'production';

export const config = {
  // Environment.
  env: homeroomEnv,
  isDev: homeroomEnv === 'development',
  isProd: homeroomEnv === 'production',
  isStaging: homeroomEnv === 'staging',

  // Microsoft Authentication Library.
  msalAuthority:
    'https://gethypeb2c.b2clogin.com/tfp/gethypeb2c.onmicrosoft.com/b2c_1_signupsignin1',
  msalClientId: '1562e155-4e9f-4ad7-8d19-3709f7e94963',
  msalKnownAuthorities: ['gethypeb2c.b2clogin.com'],
  msalRedirectUri: `${window.location.protocol}//${window.location.host}`,
};
