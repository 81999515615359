import { Calendar, Dribbble, Grid } from 'react-feather';
import { AvatarImage } from 'src/shared';
import { useAppState } from 'src/state';
import {
  getCalendarRoute,
  getCourseRoute,
  getGamesRoute,
  getProfileRoute,
} from '../../../../routes';
import { Tab } from './Tab';

const TAB_ICON_SIZE = 24;

export function CalendarTab() {
  return (
    <Tab lavender route={getCalendarRoute()} title="calendar">
      <Calendar size={TAB_ICON_SIZE} />
    </Tab>
  );
}

export function CoursesTab() {
  return (
    <Tab route={getCourseRoute()} title="courses" white>
      <Grid size={TAB_ICON_SIZE} />
    </Tab>
  );
}

export function GamesTab() {
  return (
    <Tab lightYellow route={getGamesRoute()} title="games">
      <Dribbble size={TAB_ICON_SIZE} />
    </Tab>
  );
}

export function ProfileTab() {
  const { profile } = useAppState();

  return (
    <Tab lightPurple route={getProfileRoute()} title="profile">
      <AvatarImage avatar={profile?.avatar} size={TAB_ICON_SIZE} />
    </Tab>
  );
}
