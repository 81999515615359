import styled from 'styled-components';

interface FirstHeadingProps {
  margin?: string;
}

export const FirstHeading = styled.h1<FirstHeadingProps>`
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 2.8rem;
  font-weight: ${(props) => props.theme.fontWeight};
  margin: ${(props) => props.margin || '0.4rem auto 1rem'};
`;
