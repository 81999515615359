import { Avatar } from 'src/libraries/mct';
import { getColorByHash } from '../../colors/getColorByHash';
import { AvatarImage } from '../AvatarImage';
import { Card } from './Card';

interface AvatarCardProps {
  size: number;
  avatar: Avatar;
}

/**
 * Renders an avatar within a {@link Card} component. The Card color is chosen
 * randomly based on a hash produced from the "source" prop.
 */
export const AvatarCard = ({ avatar, size }: AvatarCardProps) => {
  const cardColor = getColorByHash(avatar);
  const cardColorProp = { [cardColor]: true };

  return (
    <Card {...cardColorProp}>
      <AvatarImage avatar={avatar} size={size} />
    </Card>
  );
};
