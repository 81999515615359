import styled from 'styled-components';
import { getColorFromProps } from '../../colors/getColor';
import { CardProps, OutlineCardProps } from './types';

const ForegroundOutline = styled.div<CardProps>`
  border: 3px solid ${(props) => getColorFromProps(props).string()};
  border-radius: 8px;
  display: inline-block;
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: 1rem;
  position: relative;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

// BackgroundOutline dimensions and its border (except color) must be the same as ForegroundOutline.
// Cannot use shorthand 'border' property with 'inherit'.
const BackgroundOutline = styled.div<CardProps>`
  border-color: ${(props) => getColorFromProps(props).string()};
  border-style: inherit;
  border-width: inherit;
  border-radius: inherit;
  height: 100%;
  left: -0.5rem;
  position: absolute;
  top: -0.5rem;
  width: 100%;
`;

// The <BackgroundOutline /> prop is in the form of { color: true } (eg. pink: true).
// This allows for <BackgroundOutline /> props to be of type CardProps, allowing the use of getColorFromProps().
export function OutlineCard(props: OutlineCardProps) {
  const { children, ...cardProps } = props;

  return (
    <ForegroundOutline {...cardProps}>
      <BackgroundOutline
        {...{
          [props.backgroundOutlineColor ?? 'purple']: true,
        }}
      />
      {children}
    </ForegroundOutline>
  );
}
