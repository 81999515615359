import { useState } from 'react';
import { Button } from 'src/shared';
import styled from 'styled-components';
import { Sudoku } from './sudoku';
import { TicTacToe } from './ticTacToe';

enum AllGames {
  Sudoku = 'Sudoku',
  TicTacToe = 'Tic Tac Toe',
}

const GamesWrapper = styled.div`
  background-color: ${(props) => props.theme.lightYellow.string()};
  box-sizing: border-box;
  padding: 40px 1.5rem;
  width: 100%;
`;

const GameSelector = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export function Games() {
  const [game, setGame] = useState(AllGames.TicTacToe);

  return (
    <GamesWrapper>
      <GameSelector>
        {Object.values(AllGames).map((oneGame) => (
          <Button
            key={oneGame}
            lavender={oneGame === game}
            onClick={() => setGame(oneGame)}>
            {oneGame}
          </Button>
        ))}
      </GameSelector>

      {game === AllGames.Sudoku && <Sudoku />}
      {game === AllGames.TicTacToe && <TicTacToe />}
    </GamesWrapper>
  );
}
