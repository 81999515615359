/**
 * Open Peeps avatar types.
 */
import { Options as OpenPeepsOptions, schema } from '@dicebear/open-peeps';

export type { OpenPeepsOptions };

export type OpenPeepsOptionKey = keyof OpenPeepsOptions;

export type OpenPeepsOptionValue = Exclude<
  OpenPeepsOptions[OpenPeepsOptionKey],
  undefined
>;

export const DEFAULT_OPEN_PEEPS_OPTIONS: OpenPeepsOptions = {
  accessories: ['glasses2'],
  accessoriesProbability: 100,
  clothingColor: ['#cec2fc'],
  face: ['cute'],
  facialHair: [],
  facialHairProbability: 100,
  hairColor: ['#000'],
  head: ['bun'],
  mask: [],
  maskProbability: 100,
  skinColor: ['#572d0a'],
};

// Map of all visual traits, excluding colors.
interface OpenPeepsVisualTraits {
  accessories: Exclude<OpenPeepsOptions['accessories'], undefined>;
  face: Exclude<OpenPeepsOptions['face'], undefined>;
  facialHair: Exclude<OpenPeepsOptions['facialHair'], undefined>;
  head: Exclude<OpenPeepsOptions['head'], undefined>;
  mask: Exclude<OpenPeepsOptions['mask'], undefined>;
}

export type OpenPeepsVisualTraitKey = keyof OpenPeepsVisualTraits;
export type OpenPeepsVisualTraitValue =
  | OpenPeepsOptions['accessories'][]
  | OpenPeepsOptions['face'][]
  | OpenPeepsOptions['facialHair'][]
  | OpenPeepsOptions['head'][]
  | OpenPeepsOptions['mask'][];

/**
 * This function allows us to get the visual traits directly from the open peeps
 * package using the provided {@link schema}. The resulting  array is first cast
 * to "unknown" so that it may be recast to the known type.
 */
function getSchemaItems<T>(key: string) {
  if (!schema.properties) {
    return [];
  }

  const items = schema.properties[key];

  if (!items || typeof items === 'boolean') {
    return [];
  }

  return items['default'] as unknown as Exclude<T, undefined>;
}

export const OPEN_PEEPS_VISUAL_TRAIT_OPTIONS: OpenPeepsVisualTraits = {
  accessories: getSchemaItems<OpenPeepsOptions['accessories']>('accessories'),
  face: getSchemaItems<OpenPeepsOptions['face']>('face'),
  facialHair: getSchemaItems<OpenPeepsOptions['facialHair']>('facialHair'),
  head: getSchemaItems<OpenPeepsOptions['head']>('head'),
  mask: getSchemaItems<OpenPeepsOptions['mask']>('mask'),
};

export const OPEN_PEEPS_VISUAL_TRAIT_KEYS = Object.keys(
  OPEN_PEEPS_VISUAL_TRAIT_OPTIONS,
) as OpenPeepsVisualTraitKey[];

export type OpenPeepsColorPropKey = 'clothingColor' | 'hairColor' | 'skinColor';

// List of every color attribute.
export const OPEN_PEEPS_COLOR_PROPS: OpenPeepsColorPropKey[] = [
  'clothingColor',
  'hairColor',
  'skinColor',
];

export type OpenPeepsColorPropValue =
  | OpenPeepsOptions['clothingColor']
  | OpenPeepsOptions['hairColor']
  | OpenPeepsOptions['skinColor'];
