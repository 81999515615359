import { ChangeEvent, FormEvent, useState } from 'react';
import { Profile, useAppState } from 'src/state';
import { Form, Input, Label, TextArea } from './FormComponents';
import { Save } from './Save';

interface DetailsFormProps {
  onClose: () => void;
  profile: Profile;
}

export function DetailsForm({ onClose, profile }: DetailsFormProps) {
  const [firstName, setFirstName] = useState(profile.firstName);
  const [lastName, setLastName] = useState(profile.lastName);
  const [email, setEmail] = useState(profile.email);
  const [bio, setBio] = useState(profile.bio);
  const [hypeText, setHypeText] = useState(profile.hypeText);
  const { onUpdateProfile } = useAppState();

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (event.target.name === 'firstName') {
      return setFirstName(event.target.value);
    }

    if (event.target.name === 'lastName') {
      return setLastName(event.target.value);
    }

    if (event.target.name === 'email') {
      return setEmail(event.target.value);
    }

    if (event.target.name === 'bio') {
      return setBio(event.target.value);
    }

    if (event.target.name === 'hypeText') {
      return setHypeText(event.target.value);
    }
  }

  function handleSubmit(event?: FormEvent) {
    event?.preventDefault();

    onUpdateProfile(
      profile.withDetails(email, firstName, lastName, bio, hypeText),
    );
    onClose();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="firstName">Your first name</Label>
      <Input
        id="firstName"
        name="firstName"
        onChange={handleChange}
        type="text"
        value={firstName}
      />

      <Label htmlFor="lastName">Your last name</Label>
      <Input
        id="lastName"
        name="lastName"
        onChange={handleChange}
        type="text"
        value={lastName}
      />

      <Label htmlFor="email">Your email address</Label>
      <Input
        id="email"
        name="email"
        onChange={handleChange}
        type="email"
        value={email}
        disabled
      />

      <Label htmlFor="bio">Short bio</Label>
      <TextArea
        id="bio"
        name="bio"
        onChange={handleChange}
        value={bio}></TextArea>

      <Label htmlFor="hypeText">What gets you HYPE?</Label>
      <TextArea
        id="hypeText"
        name="hypeText"
        onChange={handleChange}
        value={hypeText}></TextArea>

      <Save onCancel={onClose} onSave={handleSubmit} />
    </Form>
  );
}
