import styled from 'styled-components';

interface BadgeWrapperProps {
  selected?: boolean;
}

export const BadgeWrapper = styled.div<BadgeWrapperProps>`
  background-color: ${(props) =>
    props.selected ? props.theme.lavender.string() : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
`;
