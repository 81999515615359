import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fadeIn } from './fadeInAnimation';

const solutions = [
  {
    immutables: [
      1, 3, 4, 9, 10, 11, 15, 16, 18, 19, 27, 28, 30, 34, 42, 43, 44, 45, 46,
      49, 51, 55, 56, 57, 58, 59, 64, 66, 76, 80,
    ],
    lastAnimationIndex: 79,
    solution: [
      9, 2, 1, 6, 3, 8, 7, 4, 5, 4, 6, 5, 9, 7, 1, 2, 8, 3, 8, 7, 3, 5, 4, 2, 1,
      6, 9, 1, 8, 9, 4, 2, 5, 3, 7, 6, 5, 3, 6, 8, 9, 7, 4, 1, 2, 7, 4, 2, 1, 6,
      3, 5, 9, 8, 2, 5, 8, 7, 1, 9, 6, 3, 4, 3, 1, 4, 2, 8, 6, 9, 5, 7, 6, 9, 7,
      3, 5, 4, 8, 2, 1,
    ],
  },
  {
    immutables: [
      0, 1, 3, 4, 8, 9, 11, 14, 21, 22, 23, 24, 27, 28, 30, 38, 42, 46, 48, 57,
      58, 62, 63, 74, 76, 77, 79, 80,
    ],
    lastAnimationIndex: 78,
    solution: [
      5, 2, 9, 7, 8, 3, 4, 6, 1, 3, 4, 8, 6, 5, 1, 7, 9, 2, 6, 7, 1, 2, 9, 4, 5,
      3, 8, 1, 5, 3, 9, 4, 2, 8, 7, 6, 8, 9, 4, 5, 7, 6, 2, 1, 3, 7, 6, 2, 3, 1,
      8, 9, 5, 4, 4, 1, 5, 8, 3, 7, 6, 2, 9, 9, 8, 6, 1, 2, 5, 3, 4, 7, 2, 3, 7,
      4, 6, 9, 1, 8, 5,
    ],
  },
];

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.2rem;
`;

const Grid = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  border: 1px solid ${(props) => props.theme.violet.string()};
  display: grid;
  grid-template-columns: 45px 45px 45px 45px 45px 45px 45px 45px 45px;
  grid-template-rows: 45px 45px 45px 45px 45px 45px 45px 45px 45px;
`;

const Cell = styled.div<{
  bottomEdge: boolean;
  rightEdge: boolean;
}>`
  align-items: center;
  border: 1px solid ${(props) => props.theme.lavender.string()};
  border-bottom-color: ${(props) =>
    props.bottomEdge
      ? props.theme.violet.string()
      : props.theme.lavender.string()};
  border-right-color: ${(props) =>
    props.rightEdge
      ? props.theme.violet.string()
      : props.theme.lavender.string()};
  display: flex;
  justify-content: center;
`;

const Immutable = styled.h4`
  color: ${(props) => props.theme.black.string()};
`;

const Animated = styled.h4<{ delay: number }>`
  animation: ${fadeIn} 400ms linear ${(props) => props.delay / 2}s normal;
  animation-fill-mode: forwards;
  color: ${(props) => props.theme.purple.string()};
  opacity: 0;
`;

export function Preview() {
  const [solutionIndex, setSolutionIndex] = useState(0);
  const [solution, setSolution] = useState({ ...solutions[solutionIndex] });

  /** Darkens the bottom border of the cells in row 3 and 6. */
  function darkenBottomBorder(index: number): boolean {
    // Row 3
    if (index > 17 && index < 27) {
      return true;
    }

    // Row 6
    if (index > 44 && index < 54) {
      return true;
    }

    return false;
  }

  /** Darkens the right border of the cells in column 3 and 6. */
  function darkenRightBorder(index: number): boolean {
    // Column 3
    if ((index - 2) % 9 === 0) {
      return true;
    }

    // Column 6
    if ((index - 5) % 9 === 0) {
      return true;
    }

    return false;
  }

  /** Clears the solution and increments the solutions index. */
  function switchPreview() {
    const newSolutionIndex =
      solutionIndex === solutions.length - 1 ? 0 : solutionIndex + 1;

    setSolution({ immutables: [], solution: [], lastAnimationIndex: 0 });
    setSolutionIndex(newSolutionIndex);
  }

  /** Re-renders the preview with a different puzzle. */
  useEffect(() => {
    setSolution({ ...solutions[solutionIndex] });
  }, [solutionIndex]);

  return (
    <Wrapper>
      <Grid>
        {solution.solution.map((num, index) => (
          <Cell
            bottomEdge={darkenBottomBorder(index)}
            key={index}
            rightEdge={darkenRightBorder(index)}>
            {solution.immutables.includes(index) ? (
              <Immutable>{num}</Immutable>
            ) : (
              <Animated
                delay={index}
                onAnimationEnd={() =>
                  index === solution.lastAnimationIndex && switchPreview()
                }>
                {num}
              </Animated>
            )}
          </Cell>
        ))}
      </Grid>
    </Wrapper>
  );
}
