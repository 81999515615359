import { ChevronRight } from 'react-feather';
import { CalendarNavButton } from './Button';
import { BUTTON_SIZE, CalendarNavButtonProps } from './constants';

/** Navigates forwards in time within the calendar. */
export function NextButton({ onClick }: CalendarNavButtonProps) {
  return (
    <CalendarNavButton onClick={onClick}>
      <ChevronRight size={BUTTON_SIZE} />
    </CalendarNavButton>
  );
}
