import { Games } from 'src/games';
import { Footer } from 'src/shared';

export default function GamesPage() {
  return (
    <>
      <Games />
      <Footer lightYellow />
    </>
  );
}
