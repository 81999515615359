import {
  Announcements,
  CourseSummary,
  Discover,
  RegisteredCourses,
} from 'src/course';
import { FirstHeading, Footer, SecondHeading, Section } from 'src/shared';
import { useAppState } from 'src/state';

export default function HomePage() {
  const {
    registeredCourses,
    isLoadingCourseState,
    notifications,
    isLoadingNotifications,
    profile,
  } = useAppState();

  return (
    <>
      <Section>
        <FirstHeading>Welcome back {profile?.firstName}!</FirstHeading>
        <CourseSummary />
      </Section>
      <Section>
        <SecondHeading>Your Courses</SecondHeading>
        <RegisteredCourses
          courses={registeredCourses}
          isLoading={isLoadingCourseState}
        />
      </Section>
      <Section>
        <Announcements
          isLoading={isLoadingNotifications}
          notifications={notifications}
        />
      </Section>
      <Section>
        <SecondHeading>Discover</SecondHeading>
        <Discover
          courses={registeredCourses}
          isLoading={isLoadingCourseState}
        />
      </Section>
      <Footer white />
    </>
  );
}
