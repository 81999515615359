/**
 * Converts an image file path, number, object or string into a color.
 */

const COLORS_BY_HASH = [
  'lavender',
  'lightPurple',
  'lightYellow',
  'mint',
  'pink',
  'teal',
];

export function getColorByHash(src: number | object | string): string {
  const srcStr = JSON.stringify(src);

  // Converts each character in the string to a number and calculates the sum.
  const hashSum = srcStr
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((a, b) => a + b);

  // Gets the index of one of the colors from the COLORS_BY_HASH array.
  const hash = hashSum % COLORS_BY_HASH.length;

  return COLORS_BY_HASH[hash] || COLORS_BY_HASH[0];
}
