import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRedirectPath } from 'src/utils';

interface GuestGuardProps {
  children: React.ReactNode;
}

/**
 * Blocks child components from rendering unless the user is signed out (i.e. is
 * a guest). If the user is signed in, they will be redirected to the previous
 * or home page.
 */
export function GuestGuard({ children }: GuestGuardProps) {
  const location = useLocation();
  const pushRoute = useNavigate();
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  useEffect(() => {
    if (isAuthenticated) {
      pushRoute(getRedirectPath(location));
    }
  }, [isAuthenticated, location, pushRoute]);

  return isAuthenticated ? null : <>{children}</>;
}
