import { Board, Mark } from '../types';
import { canStrikeThree } from './canStrikeThree';
import { getColumn } from './getColumn';
import { getDiagonal } from './getDiagonal';
import { getRow } from './getRow';

/** Returns the board index that will most likely result in a victory. */
export function hardBot(board: Board, playerMark: Mark): number {
  const botMark = playerMark === Mark.O ? Mark.X : Mark.O;

  // If there is an opportunity to match three in a row, take it.
  for (let i = 0; i < 3; i++) {
    let boardIndex = -1;

    const column = getColumn(i, board);
    boardIndex = canStrikeThree(column, botMark);

    if (boardIndex > -1) {
      return boardIndex;
    }

    const diagonal = getDiagonal(i, board);
    boardIndex = canStrikeThree(diagonal, botMark);

    if (boardIndex > -1) {
      return boardIndex;
    }

    const row = getRow(i, board);
    boardIndex = canStrikeThree(row, botMark);

    if (boardIndex > -1) {
      return boardIndex;
    }
  }

  // If the player can match three in a row, block it.
  for (let i = 0; i < 3; i++) {
    let boardIndex = -1;

    const column = getColumn(i, board);
    boardIndex = canStrikeThree(column, playerMark);

    if (boardIndex > -1) {
      return boardIndex;
    }

    const diagonal = getDiagonal(i, board);
    boardIndex = canStrikeThree(diagonal, playerMark);

    if (boardIndex > -1) {
      return boardIndex;
    }

    const row = getRow(i, board);
    boardIndex = canStrikeThree(row, playerMark);

    if (boardIndex > -1) {
      return boardIndex;
    }
  }

  // If the bot mark is X, prioritze corners.
  if (botMark === Mark.X) {
    const boardCornerIndices = [0, 2, 6, 8];

    for (const boardCornerIndex of boardCornerIndices) {
      if (!board[boardCornerIndex]) {
        return boardCornerIndex;
      }
    }
  }

  // If the bot mark is O, take the center.
  if (botMark === Mark.O) {
    const boardCenterIndex = 4;

    if (!board[boardCenterIndex]) {
      return boardCenterIndex;
    }
  }

  // Take the first empty board index by default.
  return board.findIndex((mark) => !mark);
}
