import styled from 'styled-components';

/** Contains the calendar title and the chevrons. */
export const CalendarLabel = styled.div`
  align-items: flex-end;
  background-color: ${(props) => props.theme.lavender.string()};
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`;
