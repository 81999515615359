import { Outlet, useLocation } from 'react-router-dom';
import { AppStateProvider } from 'src/state';
import { AuthGuard } from '../guards/AuthGuard';
import { Navigation } from './navigation/Navigation';

/**
 * Common parent component for authenticated pages. It includes navigation and
 * the {@link AuthGuard}.
 */
export function StudentPageWrapper() {
  const { pathname } = useLocation();

  return (
    <AuthGuard>
      <AppStateProvider>
        <Navigation route={pathname}>
          <main>
            <Outlet />
          </main>
        </Navigation>
      </AppStateProvider>
    </AuthGuard>
  );
}
