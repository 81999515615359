import { initializeApp } from 'firebase/app';
import {
  deleteDoc,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { Profile } from 'src/state';
import { Avatar } from '../../mct/entities/Avatar';

const firebaseConfig = {
  apiKey: 'AIzaSyBZQXzg80Qo51GMMfIaWxy_EaRpmXWW5U0',
  authDomain: 'homeroom-backup.firebaseapp.com',
  projectId: 'homeroom-backup',
  storageBucket: 'homeroom-backup.appspot.com',
  messagingSenderId: '637340046072',
  appId: '1:637340046072:web:7029fc5c5e272e756ae10d',
};

initializeApp(firebaseConfig);

export class FirestoreProfile {
  /** Returns a profile document for the given ID. */
  static async getProfile(profile: Profile): Promise<Profile> {
    const record = await getDoc(this.getDocumentRef(profile.email));

    if (!record.exists()) {
      return profile.copy();
    }

    const fsProfile = record.data();

    return profile
      .withAvatar(fsProfile.avatar)
      .withDetails(
        profile.email,
        fsProfile.firstName || profile.firstName,
        fsProfile.lastName || profile.lastName,
        fsProfile.bio || profile.bio,
        fsProfile.hypeText || profile.hypeText,
      );
  }

  /** Deletes a profile document. */
  static async deleteProfile(profile: Profile): Promise<void> {
    return deleteDoc(this.getDocumentRef(profile.email));
  }

  private static readonly COLLECTION_NAME = 'profiles';

  private static readonly CONVERTER: FirestoreDataConverter<Profile> = {
    fromFirestore: function (document, options?) {
      const {
        avatar,
        bio,
        email,
        favoriteEmoji,
        firstName,
        hypeText,
        lastName,
      } = document.data();

      return new Profile(
        email,
        firstName,
        lastName,
        Avatar.parse(avatar),
        bio,
        hypeText,
        favoriteEmoji,
      );
    },
    toFirestore: function (profile: Profile, options?) {
      return {
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        avatar: profile.avatar.encode(),
        bio: profile.bio,
        hypeText: profile.hypeText,
        favoriteEmoji: profile.favoriteEmoji,
      };
    },
  };

  /** Returns a Firestore document reference for the given profile. */
  private static getDocumentRef(email: string): DocumentReference<Profile> {
    return doc(getFirestore(), this.COLLECTION_NAME, email).withConverter(
      this.CONVERTER,
    );
  }
}
