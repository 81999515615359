import styled from 'styled-components';
import {
  isCalendarRoute,
  isCourseRoute,
  isGamesRoute,
  isProfileRoute,
} from '../../../../routes';
import { NavigationProps } from '../types';
import { CalendarTab, CoursesTab, GamesTab, ProfileTab } from './Tabs';

const NavigationWrapper = styled.div`
  display: none;
  min-height: 100vh;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

const ChildrenWrapper = styled.div`
  flex-grow: 1;
`;

export function DesktopNavigation({ children, route }: NavigationProps) {
  return (
    <NavigationWrapper>
      <CoursesTab />
      {isCourseRoute(route) && <ChildrenWrapper>{children}</ChildrenWrapper>}
      <CalendarTab />
      {isCalendarRoute(route) && <ChildrenWrapper>{children}</ChildrenWrapper>}
      <GamesTab />
      {isGamesRoute(route) && <ChildrenWrapper>{children}</ChildrenWrapper>}
      <ProfileTab />
      {isProfileRoute(route) && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </NavigationWrapper>
  );
}
