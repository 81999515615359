import { MctCourseContentItemExtended } from 'src/libraries/mct';
import { LessonResourceType } from './types';
import { getLessonResourceType } from './utils';

/**
 * Represents a lesson resource (e.g. a PDF attachment).
 */
export class LessonResource {
  static fromMctEntity(lesson: MctCourseContentItemExtended): LessonResource {
    const { type, url } = getLessonResourceType(lesson);

    return new LessonResource(
      lesson.Data.Id,
      lesson.Data.Title || lesson.Data.QuizData?.Title || 'Unnamed resource',
      type,
      url,
      lesson.CompletionPercentage > 0,
      lesson.CompletionPercentage >= 100,
      !!lesson.LearningFlowType,
    );
  }

  /**
   * Specifies whether this resource is embeddable.
   */
  get hasEmbed(): boolean {
    return !!this.embed;
  }

  /**
   * HTML code for embedding the resource.
   */
  get embed(): string {
    switch (this.type) {
      case LessonResourceType.EmbeddableVideo: {
        const videoType = this.url.match(/[^?]*\.([a-zA-Z0-9]{3,4})/);

        if (videoType) {
          return `
          <video controls style="width: 100%;">
            <source src="${this.url}" type="video/${videoType[1]}">
          </video>`;
        }

        return '';
      }

      case LessonResourceType.YouTube:
        // 56.25% keeps the standard YouTube aspect ratio on the DIV.
        return `
        <div style="padding-bottom: 56.25%; position: relative; width: 100%;">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            src="${this.url}"
            style="height: 100%; position: absolute; width: 100%;"
          />
        </div>`;

      default:
        return '';
    }
  }

  private constructor(
    readonly id: number,
    readonly title: string,
    readonly type: LessonResourceType,
    readonly url: string,
    readonly started: boolean,
    readonly completed: boolean,
    readonly canBeLocked: boolean,
  ) {}
}
