/**
 * Course-related types from Microsoft Community Training API.
 *
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2
 */
import { MctTimestamp } from './common';
import { MctUser } from './user';

enum QuizType {
  Practice = 'Practice',
}

/**
 * Represents a single lesson within Microsoft Community Training.
 */
export interface MctCourseContentItem {
  CourseId: number;
  CourseItemId: number;
  ItemType: string;
  DisplayOrder: number;
  Data: {
    Id: number;
    DownloadUrl: string;
    TotalDuration: number | null;
    Tags: string;
    FileType: string;
    VideoTextTracks: any;
    Uuid: string;
    AuxPptUrl: string;
    AuxVideoUrl: string;
    AuxWordUrl: string;
    AuxZipUrl: string;
    AuxPdfUrl: string;
    IsChild: boolean;
    Title?: string;
    Description: string;
    Url: string;
    ThumbnailUrl: string;
    QuizData?: {
      Description: string;
      EndTime: string; // 12/02/2023 02:38:27
      Id: string; // Numeric
      IsAnswerCorrectnessHidden: boolean;
      IsCertificationKc: boolean;
      IsShuffleQuestions: boolean;
      IsSurvey: boolean;
      MaxQuestionsOnShuffle: number;
      MessageOnQuizEnd: string;
      NumOfAttempts: number;
      NumOfUserAttempts: number;
      PassingPercent: number;
      QuestionIds: string;
      QuizType: QuizType;
      ReminderBeforeDays: number;
      Score: number;
      StartTime: string; // 12/02/2022 02:38:27
      ThumbnailUrl: string;
      Title: string;
      TotalScore: string; // numeric
      Url: string;
      Uuid: string;
    };
  };
  CompletionPercentage: number;
  IsPublished: boolean;
  VideoProgressDuration: number;
  LearningFlowType: number;
}

/**
 * Response to: GET /v2/Courses/<COURSE ID>/Content
 */
export interface MctCourseContent {
  CourseItems: MctCourseContentItem[];
  courseDescription: string;
  LearningFlowType: number;
}

export interface MctCourseContentItemExtended extends MctCourseContentItem {
  CourseId: number;
  CourseName: string;
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-CourseEntity
 */
export interface MctCourseEntity {
  CourseName: string;
  CourseDescription: string;
  ProductId: number;
  CourseImage: string;
  IsRegistered: boolean;
  FeedbackFormURL: string;
  CompletionPercentage: number;
  CourseItemCount: number;
  PublishedLessonCount: number;
  LastLessonID: number;
  IsCertificateEnabled: boolean;
  CourseCompletionDate: MctTimestamp;
  Priority: string;
  ParentCourseId: number;
  ContentLanguage: string;
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-CourseOutlineModel
 */

export interface MctCourseOutlineModel {
  CourseName: string;
  CourseDescription: string;
  ProductId: number;
  CourseImage: string;
  IsRegistered: boolean;
  FeedbackFormURL: string;
  CourseItems: string[];
}

export type MctCourseUserSearchApiResponse = MctUser[];

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-CategoryEntity
 */
export interface MctCategoryEntity {
  CategoryId: number;
  CategoryName: string;
  CategoryDescription: string;
  CategoryImage: string;
  Courses: MctCourseEntity[];
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-LearningPathCourseEntity
 */
export interface MctLearningPathCourseEntity {
  CourseName: string;
  CourseDescription: string;
  ProductId: number;
  CourseImage: string;
  IsRegistered: boolean;
  FeedbackFormURL: string;
  CompletionPercentage: number;
  PublishedLessonCount: number;
  LastLessonID: number;
  IsCertificateEnabled: boolean;
  CourseCompletionDate: MctTimestamp;
  Priority: string;
  CategoryId: number;
  CategoryName: string;
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-LearningPathResponseEntity
 */
export interface MctLearningPathResponseEntity {
  Id: number;
  Name: string;
  Description: string;
  Logo: string;
  Priority: string;
  IsOrderRestrictionEnabled: boolean;
  CompletionPercentage: number;
  AdminEnrolledCourseIds: number[];
  Courses: MctLearningPathCourseEntity[];
  CertificateUrl: string;
  CertificateStatus: number;
}

/**
 * Response to: GET /v2/Courses
 *
 * Represents the set of self-enrolled and auto-enrolled courses for the
 * authenticated student.
 */
export interface MctContentEntity {
  Categories?: MctCategoryEntity[];
  CategoryEntityList?: MctCategoryEntity[];
  LearningPaths: MctLearningPathResponseEntity[];
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-MediaServicesToken
 */
export interface MctMediaServicesToken {
  Token: string;
}

/**
 * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V2#model-SecureLessonEntity
 */
export interface MctSecureLessonEntity {
  LessonId: number;
  LessonDownloadURL: string;
}

interface MctLocalizedData {
  LanguageCode: string | null;
  Value: string | null;
}

export interface MctCategoryListInfo {
  Id: number;
  Name: string;
  Names: MctLocalizedData[];
  DefaultLanguageCode: string;
  Logo: string;
  OrganizationId: number;
  OrganizationName: string;
  Uuid: string;
  HasEditAccess: boolean;
}

export enum MctEnrollmentType {
  // Self-enroll content.
  Optional = 1,
  // Auto-enroll content.
  Recommended,
  // Required content.
  Required,
}

export interface MctCourseCategoryInfo {
  Id: number;
  ParentId: number;
  ContentLanguage: string | null;
  ParentCourseId: number;
  Name: string | null;
  Description: string | null;
  Logo: string | null;
  ParentName: string | null;
  ParentNames: MctLocalizedData[];
  ParentDefaultLanguageCode: string | null;
  EnrollmentType: MctEnrollmentType;
  IsCertificate: boolean;
  LearningFlowType: boolean;
  CertificateTemplateId: number;
  NumPublishedLessons: number;
  NumPublishedKCs: number;
  OrganizationId: number;
  OrganizationName: string | null;
  ImportContentType: 'MsLearn' | 'MsDigitalContent' | 'UploadCoursesByFile';
  ImportContentURL: string | null;
  FeedbackFormUrl: string | null;
  HasEditAccess: boolean;
}

export interface MctCategoryInfo {
  CategoryIdKey: number;
  CategoryName: string;
}

interface MctCourseInfo {
  CourseIdKey: number;
  CourseName: string;
  CoursePriority: MctEnrollmentType;
  ParentId: number;
  IsSelected: boolean;
}

export interface MctCategoryCourseInfo {
  CategoryList: MctCategoryInfo[];
  CourseList: MctCourseInfo[];
  RestrictedCourseList: MctCourseInfo[] | null;
}

/**
 * Response to: GET /v2/admin/categoriesAndCourses
 */
export interface MctHomeModel {
  Offers: MctCategoryListInfo[];
  CourseItems: MctCourseCategoryInfo[];
  RestrictedCourseItems: MctCourseCategoryInfo[];
}
