export function getProgressBarMessage(percentage: number) {
  if (percentage < 1) {
    return "Let's get started!";
  }

  if (percentage < 50) {
    return 'Go!';
  }

  if (percentage < 80) {
    return 'You got this!';
  }

  if (percentage < 100) {
    return 'Almost there!';
  }

  return 'Complete!';
}
