import {
  ButtonLink,
  getCalendarDayRoute,
  getCalendarMonthRoute,
  getCalendarWeekRoute,
  Section,
} from 'src/shared';
import styled from 'styled-components';
import { CalendarView, CustomDateObject } from './types';
import { parseToYearMonthDay } from './utils/parseToYearMonthDay';
import { WeekAtAGlance } from './weekAtAGlance/WeekAtAGlance';

const MostOuterContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.lavender.string()};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CalendarViewContainer = styled.div`
  background-color: ${(props) => props.theme.white.string()};
  border-radius: 20px;
  box-sizing: border-box;
  margin-bottom: 4rem;
  max-width: 1024px;
  padding: 2rem;
  width: 100%;
`;

/** Contains the view buttons (ie. month, week, day). */
const ButtonGroup = styled.div`
  padding: 1rem 0;
`;

interface CalendarViewNavProps {
  children: React.ReactNode;
  selectedDate: CustomDateObject;
  view: CalendarView;
}

export function CalendarViewNav({
  children,
  selectedDate,
  view,
}: CalendarViewNavProps) {
  const ymdDate = parseToYearMonthDay(
    selectedDate.year,
    selectedDate.month,
    selectedDate.dayOfMonth,
  );

  return (
    <MostOuterContainer>
      <Section>
        <WeekAtAGlance />
      </Section>
      <CalendarViewContainer>
        <ButtonGroup>
          {[CalendarView.Week, CalendarView.Month, CalendarView.Day].map(
            (calView) => {
              let route;

              switch (calView) {
                case CalendarView.Day:
                  route = getCalendarDayRoute(ymdDate);
                  break;
                case CalendarView.Week:
                  route = getCalendarWeekRoute(ymdDate);
                  break;
                default:
                  route = getCalendarMonthRoute(ymdDate);
              }

              return (
                <ButtonLink
                  key={calView}
                  href={route}
                  lavender={calView !== view}
                  teal={calView === view}>
                  {calView}
                </ButtonLink>
              );
            },
          )}
        </ButtonGroup>

        {children}
      </CalendarViewContainer>
    </MostOuterContainer>
  );
}
