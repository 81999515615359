import { MonthYearPair } from '../types';

/** Gets the month that comes after the param month. */
export function getNextMonth(month: number, year: number): MonthYearPair {
  const nextMonth =
    month === 11 // month is December?
      ? 0 // set month to January
      : month + 1; // set month to subsequent

  const correspondingYear =
    month === 11 // month is December?
      ? year + 1 // set year to subsequent
      : year; // keep same year

  return { month: nextMonth, year: correspondingYear };
}
