import { BrowserAuthError, BrowserAuthErrorMessage } from '@azure/msal-browser';
import { logError } from '../../utils';
import { MSAL_POPUP_REQUEST } from './constants';
import { msalInstance } from './instance';
import { clearTokens, updateTokens } from './token';

export async function logout() {
  try {
    await msalInstance.logoutPopup();
    clearTokens();
  } catch (error) {
    logError(error);
  }
}

export async function openAuthPopup() {
  try {
    const result = await msalInstance.loginPopup(MSAL_POPUP_REQUEST);

    updateTokens(result);
  } catch (e) {
    const error = e as BrowserAuthError;

    if (error.errorCode !== BrowserAuthErrorMessage.userCancelledError.code) {
      logError(error);
    }
  }
}
