import { Button } from 'src/shared';
import styled from 'styled-components';
import { Difficulty } from './types';

interface StartMenuProps {
  beginMatch: () => void;
  chooseDifficulty: (difficulty: Difficulty) => void;
  difficulty: Difficulty;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Instructions = styled.h3`
  color: ${(props) => props.theme.violet.string()};
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 30px;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export function StartMenu({
  beginMatch,
  chooseDifficulty,
  difficulty,
}: StartMenuProps) {
  return (
    <Wrapper>
      <Instructions>
        Whether you play as X or O, try to place three in a row, horizontally,
        vertically, or diagonally.
      </Instructions>
      <ButtonGroup>
        {Object.values(Difficulty).map((diff) => (
          <Button
            key={diff}
            lavender={diff === difficulty}
            onClick={() => chooseDifficulty(diff)}>
            {diff}
          </Button>
        ))}
      </ButtonGroup>
      <Button onClick={beginMatch} pink>
        Begin
      </Button>
    </Wrapper>
  );
}
