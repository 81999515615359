import { Link } from 'react-router-dom';
import { Card, getColorByHash, getCourseRoute } from 'src/shared';
import styled from 'styled-components';

const ICON_HEIGHT = 20;
const ICON_WIDTH = 20;

interface MonthCardProps {
  courseId: number;
  iconSource: string;
  lessonId: number;
  title: string;
}

const CardWrapper = styled(Link)`
  color: ${(props) => props.theme.textColor.string()};
  left: 5px;
  position: absolute;
  top: 45px;
`;

export function MonthCard({
  courseId,
  iconSource,
  lessonId,
  title,
}: MonthCardProps) {
  const color = getColorByHash(title);
  const colorProp = { [color]: true };

  return (
    <CardWrapper to={getCourseRoute(courseId, lessonId)}>
      <Card
        {...colorProp}
        height="65px"
        width="100px"
        style={{ padding: '5px' }}>
        <img alt="" height={ICON_HEIGHT} src={iconSource} width={ICON_WIDTH} />
        <h6 style={{ fontSize: '10px', margin: '0' }}>{title}</h6>
      </Card>
    </CardWrapper>
  );
}
