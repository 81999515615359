import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/open-peeps';
import { OpenPeepsOptions } from './types';

interface OpenPeepsAvatarProps {
  options: Partial<OpenPeepsOptions>;
  size?: number;
}

/** Renders an OpenPeeps avatar. */
export function OpenPeepsAvatar({ options, size }: OpenPeepsAvatarProps) {
  const css = size ? { height: `${size}px`, width: `${size}px` } : {};

  return (
    <div
      dangerouslySetInnerHTML={{ __html: createAvatar(style, options) }}
      style={css}
    />
  );
}
