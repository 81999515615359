import { HTMLProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

interface LinkProps extends HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;
  href: string;
  teal?: boolean;
}

const Text = styled.span`
  position: inherit;
  z-index: 2;
`;

const Highlight = styled.span<{ teal?: boolean }>`
  background-color: ${(props) =>
    props.teal
      ? props.theme.teal.fade(0.1).string()
      : props.theme.pink.string()};
  border-radius: ${(props) => props.theme.inputBorderRadius};
  bottom: 5px;
  display: block;
  height: 0.3em;
  left: -2px;
  position: absolute;
  transition: height ${(props) => props.theme.transitionTime};
  width: calc(100% + 4px);
  z-index: 1;
`;

const Shadow = styled(Highlight)`
  background-color: ${(props) => props.theme.yellow.string()};
  bottom: 3px;
  left: 0;
  z-index: 0;
`;

const Anchor = styled(RouterLink)`
  color: ${(props) => props.theme.textColor.string()};
  position: relative;
  text-decoration: none;

  &:hover {
    ${Highlight} {
      height: 0.6rem;
    }
  }
`;

const ExternalAnchor = styled.a`
  color: ${(props) => props.theme.textColor.string()};
  position: relative;
  text-decoration: none;

  &:hover {
    ${Highlight} {
      height: 0.6rem;
    }
  }
`;

export function Link(props: LinkProps) {
  const { children, teal, ...anchorProps } = props;

  return (
    <Anchor
      to={anchorProps.href}
      onClick={anchorProps.onClick}
      target={anchorProps.target}>
      <Text>{children}</Text>
      <Highlight teal={teal} />
      <Shadow />
    </Anchor>
  );
}

export function ExternalLink(props: LinkProps) {
  const { children, teal, ...anchorProps } = props;

  return (
    <ExternalAnchor
      href={anchorProps.href}
      rel="norefferer"
      target={anchorProps.target}>
      <Text>{children}</Text>
      <Highlight teal={teal} />
      <Shadow />
    </ExternalAnchor>
  );
}
