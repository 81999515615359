import styled from 'styled-components';

export const BadgeList = styled.div`
  align-content: flex-start;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 260px;
  justify-content: flex-start;
  overflow-y: scroll;
`;
