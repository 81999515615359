import { CalendarGraph, GraphCalendarEvent } from 'src/libraries/graph';
import { MctApi } from 'src/libraries/mct';
import { logWithCause } from 'src/utils';
import { Course } from '../entities/course';
import { Lesson } from '../entities/lesson';
import { LessonResource } from '../entities/lessonResource';
import { User } from '../entities/user';
import { filterSupportedLessonResources } from '../entities/utils';
import { DEFAULT_COURSE_STATE } from './constants';
import { fixSpring2023Events } from './spring2023';
import { CourseState } from './types';

/**
 * Retrieves and merges all course data. Note that:
 *
 *  - A Category in MCT corresponds to a Course in Homeroom.
 *  - A Course in MCT corresponds to a Lesson in Homeroom.
 *  - A CourseContentItem in MCT corresponds to a Lesson Resource in Homeroom.
 */
export async function getCourseState(
  token: string,
  user: User,
): Promise<CourseState> {
  const state: CourseState = { ...DEFAULT_COURSE_STATE };

  // Retrieve all courses assigned to the logged-in user's groups.
  try {
    const coursesById = new Map();
    const mctGroupCourses = await Promise.all(
      user.groups.map((group) => MctApi.getGroupCourses(token, group.id)),
    );

    for (const mctGroupCourse of mctGroupCourses) {
      for (const mctCategory of mctGroupCourse.CategoryList) {
        coursesById.set(
          mctCategory.CategoryIdKey,
          Course.fromMctEntity(mctCategory),
        );
      }

      for (const mctCourse of mctGroupCourse.CourseList) {
        const course = coursesById.get(mctCourse.ParentId);

        if (!course) {
          continue;
        }

        coursesById.set(
          course.id,
          course.withLesson(
            Lesson.forPlaceholder(mctCourse.CourseIdKey, mctCourse.CourseName),
          ),
        );
      }
    }

    state.registeredCourses = Array.from(coursesById.values());
  } catch (error) {
    logWithCause("Could not retrieve user's group courses.", error);
  }

  // Retrieve all lesson details and resources.
  try {
    const coursesById = new Map(state.registeredCourses.map((c) => [c.id, c]));
    const mctCourseContentItemsList = await Promise.all(
      state.registeredCourses
        .map((c) => c.lessons.map((l) => [l.id, l.name]))
        .flat()
        .map(([id, name]) =>
          MctApi.getMctCourseContentItems(token, Number(id), name),
        ),
    );
    const mctCourseContentItems = mctCourseContentItemsList.flat();

    for (const course of state.registeredCourses) {
      let copy = course;

      for (const lesson of course.lessons) {
        const resources = mctCourseContentItems
          .filter((i) => i.CourseId === Number(lesson.id))
          .filter(filterSupportedLessonResources)
          .map(LessonResource.fromMctEntity);

        copy = copy.withLesson(lesson.withResources(resources));
      }

      coursesById.set(course.id, copy);
    }

    state.registeredCourses = Array.from(coursesById.values());
  } catch (error) {
    logWithCause('Could not retrieve lesson details.', error);
  }

  // Retrieve scheduling details.
  try {
    const groupNames = new Set(user.groups.map((g) => g.name));
    const coursesByName = new Map(
      state.registeredCourses.map((c) => [c.name, c]),
    );
    const coursesById = new Map(state.registeredCourses.map((c) => [c.id, c]));
    const calendarEvents = await CalendarGraph.getAllEvents();
    const relevantEvents = calendarEvents
      .map((event) => fixSpring2023Events(event, groupNames))
      .filter(({ cohortName, courseName, lessonName }) => {
        if (!groupNames.has(cohortName)) {
          return false;
        }

        const course = coursesByName.get(courseName);

        if (!course) {
          return false;
        }

        return course.lessons.some((l) => l.name === lessonName);
      });
    const calendarEventMap = new Map<string, GraphCalendarEvent[]>();

    for (const event of relevantEvents) {
      const course = coursesByName.get(event.courseName);

      if (!course) {
        continue;
      }

      const key = `${course.id}-${event.lessonName}`;
      const eventOccurrences = calendarEventMap.get(key) ?? [];

      calendarEventMap.set(key, [...eventOccurrences, event]);
    }

    // Add scheduling data to registered courses.
    for (const course of state.registeredCourses) {
      let copy = course;

      for (const lesson of course.lessons) {
        const key = `${course.id}-${lesson.name}`;
        const schedules = calendarEventMap.get(key);

        if (schedules?.length) {
          for (const schedule of schedules) {
            copy = copy
              .removeLesson(lesson)
              .withLesson(lesson.withSchedule(schedule));
          }
        }
      }

      coursesById.set(course.id, copy);
    }

    state.registeredCourses = Array.from(coursesById.values());
  } catch (error) {
    logWithCause('Could not retrieve lesson schedules.', error);
  }

  return state;
}
