import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { GuestGuard } from '../guards/GuestGuard';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.lightYellow.string()};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export function LoginPageWrapper() {
  return (
    <GuestGuard>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </GuestGuard>
  );
}
