import { ChevronLeft } from 'react-feather';
import { CalendarNavButton } from './Button';
import { BUTTON_SIZE, CalendarNavButtonProps } from './constants';

/** Navigates backwards in time within the calendar. */
export function PreviousButton({ onClick }: CalendarNavButtonProps) {
  return (
    <CalendarNavButton onClick={onClick}>
      <ChevronLeft size={BUTTON_SIZE} />
    </CalendarNavButton>
  );
}
