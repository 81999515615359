import { useParams } from 'react-router-dom';
import { Loading, NotFound /*, Section */ } from 'src/shared';
import { useAppState } from 'src/state';
import styled from 'styled-components';
import { StudentChat } from './chat/StudentChat';
import { Games } from './games/Games';
// import { Assignments } from './lesson/assignment/Assignments';
import { LessonListAndDetails } from './lesson/LessonListAndDetails';
// import { MeetYourTeacher } from './teacher/MeetYourTeacher';

const CourseWrapper = styled.div`
  background-color: ${(props) => props.theme.white.string()};
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

export function Course() {
  const { isLoadingCourseState, registeredCourses } = useAppState();
  const params = useParams();

  if (isLoadingCourseState) {
    return <Loading />;
  }

  const courseId = Number(params.courseId);
  const lessonId = Number(params.lessonId);

  const course = registeredCourses.find(({ id }) => id === courseId);

  if (!course) {
    return <NotFound />;
  }

  return (
    <CourseWrapper>
      <LessonListAndDetails course={course} lessonId={lessonId} />
      {/* <Section>
        <Assignments assignments={['temp-1']} />
      </Section>
      <Section>
        <MeetYourTeacher />
      </Section> */}
      <StudentChat />
      <Games />
    </CourseWrapper>
  );
}
