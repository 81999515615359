import blackCircleImg from 'src/assets/black-circle.png';
import { EmojiEnum, EMOJI_SOURCES } from './emoji.generated';

interface EmojiProps {
  emoji: EmojiEnum;
  size: string;
  // Meant to be used outside the app (e.g. in Storybook).
  unoptimized?: boolean;
}

export function Emoji({ emoji, size }: EmojiProps) {
  const src = emoji in EMOJI_SOURCES ? EMOJI_SOURCES[emoji] : blackCircleImg;

  return <img alt="" height={size} src={src} width={size} />;
}
