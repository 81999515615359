interface JsonEvent {
  id: string;
  summary: string;
  description: string;
  location: string;
  start: string;
  end: string;
}

export class GraphCalendarEvent {
  readonly cohortName: string;
  readonly courseName: string;
  readonly lessonName: string;

  static create(
    id: string,
    summary: string,
    start: Date,
    end: Date,
  ): GraphCalendarEvent {
    return new GraphCalendarEvent(id, summary, start, end, '', '');
  }

  static fromJson(data: JsonEvent): GraphCalendarEvent {
    return new GraphCalendarEvent(
      data.id,
      data.summary,
      new Date(data.start),
      new Date(data.end),
      data.description,
      data.location,
    );
  }

  private constructor(
    readonly id: string,
    readonly summary: string,
    readonly start: Date,
    readonly end: Date,
    readonly description: string,
    readonly location: string,
  ) {
    const { cohort, course, lesson } = this.parseSummary(summary);

    this.cohortName = cohort;
    this.courseName = course;
    this.lessonName = lesson;
  }

  /**
   * Parses a calendar event title in the following format:
   *
   *  COURSE NAME - LESSON NAME - COHORT NAME
   *
   * Example:
   *
   *  Web Development - HTML - Summer 2022
   */
  private parseSummary(summary: string) {
    const names = summary
      .replace('–', '-') // en dash
      .replace('—', '-') // em dash
      .replace('―', '-') // horizontal bar
      .replace('‒', '-') // figure bar
      .split('-')
      .map((n) => n.trim());
    const course = names[0];
    const lesson = names.length > 1 ? names[1] : '';
    const cohort = names.length > 2 ? names[2] : '';

    return { cohort, course, lesson };
  }
}
