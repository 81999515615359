import { CustomDateObject } from '../types';
import { createCalendar } from '../utils/createCalendar';
import { parseToYearMonthDay } from '../utils/parseToYearMonthDay';

export function getCalendarWeek(selectedDate: CustomDateObject): string[] {
  const CALENDAR = createCalendar(selectedDate);
  const ymdDate = parseToYearMonthDay(
    selectedDate.year,
    selectedDate.month,
    selectedDate.dayOfMonth,
  );

  for (const WEEK of CALENDAR) {
    if (WEEK.includes(ymdDate)) {
      // Only return Monday to Friday.
      return WEEK.slice(0, 5);
    }
  }

  return CALENDAR[0];
}
