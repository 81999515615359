import { CustomDateObject } from '../types';
import { createCustomDateObject } from './createCustomDateObject';

/** Converts a YYYY-MM-DD date string into a {@link CustomDateObject}. */
export function parseToCustomDateObject(date: string): CustomDateObject {
  const [year, month, day] = date.split('-').map((str) => Number(str));

  return createCustomDateObject({
    day,
    month: month - 1,
    year,
  });
}
