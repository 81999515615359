export enum Difficulty {
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
}

export enum Mark {
  Blank = '',
  O = 'O',
  X = 'X',
}

/** A row, column, or diagonal. */
export interface BoardSequence {
  indices: number[];
  marks: Mark[];
}

export type Board = [Mark, Mark, Mark, Mark, Mark, Mark, Mark, Mark, Mark];
export type Winner = BoardSequence | null;
