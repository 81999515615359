import { DesktopNavigation } from './desktop/DesktopNavigation';
import { MobileNavigation } from './mobile/MobileNavigation';
import { NavigationProps } from './types';

export function Navigation({ children, route }: NavigationProps) {
  return (
    <div>
      <DesktopNavigation route={route}>{children}</DesktopNavigation>
      <MobileNavigation route={route}>{children}</MobileNavigation>
    </div>
  );
}
