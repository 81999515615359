import { GraphCalendarEvent } from 'src/libraries/graph';

/**
 * Manual adjustments for the Spring 2023 student cohorts. Note: for the Spring
 * 2023 cohort, the "Web Design Principles" course is called
 * "Intro to Web Development".
 */
export function fixSpring2023Events(
  event: GraphCalendarEvent,
  groupNames: Set<string>,
): GraphCalendarEvent {
  if (event.cohortName !== 'Spring 2023') {
    return event;
  }

  const adjustedLessonName =
    adjustedLessonMap.get(event.lessonName) || event.lessonName;
  const adjustedCohortName =
    adjustedCohortMap.get(event.start.getDay()) || event.cohortName;
  let summary = event.summary
    .replace('Intro to Web Development', 'Web Design Principles')
    .replace(event.lessonName, adjustedLessonName)
    .replace(event.cohortName, adjustedCohortName);

  return GraphCalendarEvent.create(event.id, summary, event.start, event.end);
}

const adjustedLessonMap = new Map([
  ['02.1 HTML Continued', '03 HTML Continued'],
  ['03 CSS', '04 CSS'],
  ['03.1 CSS Continued', '05 CSS Continued'],
  ['04 Box Model', '06 Box Model'],
  ['05 Flexbox', '07 Flexbox'],
  ['06 Bootstrap', '08 Bootstrap'],
  ['07 Personal Portfolio Project', '09 Minimum Viable Product'],
  ['07.1 Personal Portfolio Project', '10 Presentation Preparation'],
]);

const adjustedCohortMap = new Map([
  [1, 'S23 Mon'],
  [2, 'S23 Tues'],
  [3, 'S23 Wed'],
  [4, 'S23 Thurs'],
]);
