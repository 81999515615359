import { ColorProps } from 'src/shared';
import styled from 'styled-components';
import { getColorFromProps } from '../../colors/getColor';
import { ExternalLink } from '../Link';

interface FooterProps extends ColorProps {}

const FooterWrapper = styled.div<FooterProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  margin: 0 auto;
  padding: 2rem 0;
  text-align: center;
`;

export function Footer(props: FooterProps) {
  const date = new Date();

  return (
    <FooterWrapper {...props}>
      &copy; {date.getFullYear()}{' '}
      <ExternalLink href="https://gethype.org" target="_blank">
        Hope For Youth Inc.
      </ExternalLink>{' '}
      All Rights Reserved.
    </FooterWrapper>
  );
}
