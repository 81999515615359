import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { getColorFromProps } from '../../../../colors/getColor';
import { TabProps } from '../types';

const TabContainer = styled.div<TabProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  min-width: 75px;
  width: 75px;
`;

const Icon = styled.div`
  position: fixed;
`;

const Title = styled.span`
  font-family: ${(props) => props.theme.inputFontFamily};
  font-size: 18px;
  position: sticky;
  text-transform: uppercase;
  top: 90px;
  writing-mode: vertical-rl;
`;

const Spacer = styled.div`
  display: block;
  height: 170px;
`;

const Anchor = styled(RouterLink)`
  align-items: center;
  box-sizing: border-box;
  color: ${(props) => props.theme.black.string()};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
  text-decoration: none;
  width: 100%;
`;

// For now, the icons are placeholders from the Badges.
export function Tab(props: TabProps) {
  return (
    <TabContainer {...props}>
      <Anchor to={props.route}>
        <Icon>{props.children}</Icon>
        <Spacer />
        <Title>{props.title}</Title>
      </Anchor>
    </TabContainer>
  );
}
