import {
  LoginFormWrapper,
  MemberTypeSelector,
  Programs,
  ProgramsWrapper,
  useMemberType,
  WelcomeMessage,
} from 'src/auth';
import { openAuthPopup } from 'src/libraries/msal';
import { Button, SecondHeading } from 'src/shared';

export default function LoginPage() {
  const { memberType, setMemberType } = useMemberType();
  const msg = `Welcome ${memberType}! Please login using the button below.`;

  return (
    <>
      <ProgramsWrapper>
        <Programs />
      </ProgramsWrapper>
      <LoginFormWrapper>
        <SecondHeading>Hello Roomie!</SecondHeading>
        <WelcomeMessage>{msg}</WelcomeMessage>
        <MemberTypeSelector memberType={memberType} onSelect={setMemberType} />
        <Button onClick={openAuthPopup}>Log in</Button>
      </LoginFormWrapper>
    </>
  );
}
