import styled from 'styled-components';
import { A11y, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Academy } from './Academy';
import { Camp } from './Camp';
import { InternationalExperience } from './InternationalExperience';
import { Welcome } from './Welcome';

const SwiperWrapper = styled.div`
  display: block;
  width: 100%;
`;

const swiperModules = [A11y, Autoplay, Pagination];

export function Programs() {
  return (
    <SwiperWrapper>
      <Swiper autoplay loop modules={swiperModules}>
        <SwiperSlide>
          <Welcome />
        </SwiperSlide>
        <SwiperSlide>
          <Camp />
        </SwiperSlide>
        <SwiperSlide>
          <Academy />
        </SwiperSlide>
        <SwiperSlide>
          <InternationalExperience />
        </SwiperSlide>
      </Swiper>
    </SwiperWrapper>
  );
}
