import {
  getCalendarRoute,
  getCourseRoute,
  getGamesRoute,
  getProfileRoute,
} from '../../../../routes';
import { Square } from './Square';

export function CalendarSquare() {
  return (
    <Square
      lavender
      rotation="-45deg"
      route={getCalendarRoute()}
      title="Calendar"
    />
  );
}

export function CourseSquare() {
  return (
    <Square
      lightPurple
      rotation="45deg"
      route={getCourseRoute()}
      title="Courses"
    />
  );
}

export function GamesSquare() {
  return (
    <Square pink rotation="-45deg" route={getGamesRoute()} title="Games" />
  );
}

export function ProfileSquare() {
  return (
    <Square mint rotation="45deg" route={getProfileRoute()} title="Profile" />
  );
}
