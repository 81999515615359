import welcomeImage from 'src/assets/images/welcome-to-homeroom.svg';
import { ProgramSlide } from './ProgramSlide';

export function Camp() {
  return (
    <ProgramSlide
      background={welcomeImage}
      text="Equity for women of color in the tech industry"
      title="HYPE Summer Coding Camp"
    />
  );
}
