import styled from 'styled-components';

export const Section = styled.section`
  margin: 30px auto 20px;
  padding: 2rem;

  &:first-child {
    padding-top: 0;
  }
`;
