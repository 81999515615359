import { CustomDateObject, DayMonthYear } from '../types';

/** Returns a {@link CustomDateObject}. */
export function createCustomDateObject(dmy?: DayMonthYear): CustomDateObject {
  const date = dmy ? new Date(dmy.year, dmy.month, dmy.day) : new Date();

  return {
    dayOfMonth: date.getDate(),
    dayOfWeek: date.getDay() === 0 ? 6 : date.getDay() - 1,
    month: date.getMonth(),
    year: date.getFullYear(),
  };
}
