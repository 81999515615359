import { useState } from 'react';
import styled from 'styled-components';
import { fadeInAnimation } from './fadeIn';
import { useBotTurn } from './hooks/useBotTurn';
import { useEndMatch } from './hooks/useEndMatch';
import { useInitializeMatch } from './hooks/useInitializeMatch';
import { Board as BoardType, Difficulty, Mark } from './types';
import { getWinner } from './utils/getWinner';

interface BoardProps {
  changeResult: (result: string) => void;
  difficulty: Difficulty;
  endMatch: () => void;
}

const BoardGrid = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  display: grid;
  grid-template-columns: 150px 150px 150px;
  grid-template-rows: 150px 150px 150px;
`;

const BoardSpace = styled.div<{ clickable: boolean; inPattern: boolean }>`
  align-items: center;
  background-color: ${(props) =>
    props.inPattern ? props.theme.lavender.string() : 'transparent'};
  border: 1px solid ${(props) => props.theme.lavender.string()};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
  transition: background-color 700ms;
`;

const BoardMark = styled.h3<{ isBotMark?: boolean }>`
  animation: ${(props) => (props.isBotMark ? fadeInAnimation : 'none')};
  color: ${(props) => props.theme.violet.string()};
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 56px;
`;

const initialBoard: BoardType = [
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
  Mark.Blank,
];

export function Board({ changeResult, difficulty, endMatch }: BoardProps) {
  const [board, setBoard] = useState<BoardType>([...initialBoard]);
  const [playerMark, setPlayerMark] = useState(Mark.Blank);
  const [playerTurn, setPlayerTurn] = useState(false);

  const winner = getWinner(board);

  /** Determines if the player can place a mark on the board space. */
  function isClickable(mark: Mark): boolean {
    return !mark && playerTurn && !winner;
  }

  /** Places the bot's mark on the board and starts the player's turn. */
  function onBotTurnEnd(boardIndex: number) {
    updateBoard(boardIndex, playerMark === Mark.O ? Mark.X : Mark.O);
    setPlayerTurn(true);
  }

  /** Sets the player's mark and turn. */
  function onGameInitialized(mark: Mark) {
    setPlayerMark(mark);
    setPlayerTurn(mark === Mark.X ? true : false);
  }

  /** Places the player's mark on the board and ends their turn. */
  function placePlayerMark(index: number) {
    updateBoard(index, playerMark);
    setPlayerTurn(false);
  }

  /** Places the mark in the selected index. */
  function updateBoard(index: number, mark: Mark) {
    setBoard((currBoard) => {
      const updatedBoard: BoardType = [...currBoard];

      updatedBoard[index] = mark;

      return updatedBoard;
    });
  }

  useInitializeMatch(onGameInitialized);
  useBotTurn(board, difficulty, playerMark, playerTurn, winner, onBotTurnEnd);
  useEndMatch(board, playerMark, winner, changeResult, endMatch);

  return (
    <BoardGrid>
      {board.map((mark, index) => {
        const clickable = isClickable(mark);

        return (
          <BoardSpace
            clickable={clickable}
            inPattern={winner ? winner.indices.includes(index) : false}
            key={index}
            onClick={() => clickable && placePlayerMark(index)}>
            {mark && (
              <BoardMark isBotMark={mark !== playerMark}>{mark}</BoardMark>
            )}
          </BoardSpace>
        );
      })}
    </BoardGrid>
  );
}
