import fetch from 'cross-fetch';
import { Profile } from '../../state/entities/profile';
import { User } from '../../state/entities/user';
import { ADMIN_PROXY_URL, PROXY_URL } from './constants';
import {
  MctCategoryCourseInfo,
  MctContentEntity,
  MctCourseContent,
  MctCourseContentItemExtended,
  MctHomeModel,
} from './types/course';
import { MctCourseGroup, MctGroupInfo } from './types/group';
import { MctNotificationResponseEntity } from './types/notification';
import {
  MctAllUsersModel,
  MctMlxUser,
  MctProfileData,
  MctUiProfileField,
} from './types/profile';
import { MctUser } from './types/user';

export class MctApi {
  /**
   * Retrieves all published courses, regardless of whether the user is
   * enrolled or not.
   */
  static async getAdminCategoriesAndCourses(token: string) {
    return this.getAdminResource<MctHomeModel>(
      token,
      '/v2/admin/categoriesAndCourses',
    );
  }

  /**
   * Retrieves all self and auto-enrolled courses for the authenticated student.
   */
  static async getContentEntity(token: string): Promise<MctContentEntity> {
    return this.getResource<MctContentEntity>(token, '/v2/Courses');
  }

  /**
   * Retrieves groups assigned to the given course. A group is essentially a
   * cohort of students.
   */
  static async getCourseGroups(
    token: string,
    courseId: number,
  ): Promise<MctCourseGroup[]> {
    return this.getAdminResource<MctCourseGroup[]>(
      token,
      `/v2/courses/${courseId}/groups`,
    );
  }

  /**
   * Searches for enrolled students in a course.
   */
  static async searchCourseUsers(
    token: string,
    courseId: number | string,
    searchTerm: string,
  ): Promise<MctUser[]> {
    return this.getAdminResource<MctUser[]>(
      token,
      `/v2/Course/${courseId}/users`,
      {
        searchTerm,
      },
    );
  }

  /**
   * Retrieves all published courses assigned to a group, regardless of whether
   * the user is enrolled or not.
   */
  static async getGroupCourses(
    token: string,
    groupId: number,
  ): Promise<MctCategoryCourseInfo> {
    return this.getAdminResource<MctCategoryCourseInfo>(
      token,
      `/v1/Groups/${groupId}/Courses`,
    );
  }

  /** Retrieves the profile data for the authenticated user. */
  static async getProfileData(token: string): Promise<MctProfileData> {
    return this.getResource<MctProfileData>(token, '/v2/Profile');
  }

  /**
   * Updates the profile of the authenticated user.
   */
  static async updateProfileData(
    token: string,
    profile: Profile,
  ): Promise<boolean> {
    return this.postAdminResource(
      token,
      '/v2/Profile',
      profile.toMctJson(),
      'PUT',
    );
  }

  /**
   * Retrieves the organization-based user profile for the authenticated user.
   */
  static async getOrgUserProfile(
    token: string,
    user: User,
  ): Promise<MctUiProfileField[]> {
    return this.getAdminResource<MctUiProfileField[]>(
      token,
      `/v2/Organizations/${user.organizationId}/UserProfiles/${user.id}`,
    );
  }

  /**
   * Updates the organization-based user profile for the authenticated user.
   */
  static async updateOrgUserProfile(
    token: string,
    profile: Profile,
    user?: User,
  ): Promise<boolean> {
    if (!user) {
      return false;
    }

    // Convert Profile to MCT's API format.
    const mctProfile = [
      getProfileField('First Name', profile.firstName, -1, 1),
      getProfileField('Last Name', profile.lastName, -2, 2),
      getProfileField('Contact', profile.email, -3, 3),
      getProfileField('HomeroomAvatar', profile.avatar.encode(), 1, 6),
      getProfileField('HomeroomBio', profile.bio, 2, 7),
      getProfileField('HomeroomHypeText', profile.hypeText, 3, 8),
      getProfileField('HomeroomEmoji', profile.favoriteEmoji, 4, 9),
    ];

    // Note: all users belong to the same organization (where ID == 1).
    await this.postAdminResource(
      token,
      `/v2/Organizations/${user.organizationId}/UserProfiles/${user.id}`,
      mctProfile,
    );

    return true;
  }

  /** Retrieves all published lessons for the given course. */
  static async getMctCourseContentItems(
    token: string,
    courseId: number,
    courseName: string,
  ): Promise<MctCourseContentItemExtended[]> {
    // Some courses can only be enrolled by admins, so we must use the
    // admin proxy here just in case.
    const content = await this.getAdminResource<MctCourseContent>(
      token,
      `/v2/Courses/${courseId}/Content`,
    );

    return content.CourseItems.map((item) => ({
      ...item,
      CourseId: courseId,
      CourseName: courseName,
    }));
  }

  /**
   * Retrieves the notifications for the authenticated user.
   */
  static async getNotifications(
    token: string,
  ): Promise<MctNotificationResponseEntity> {
    return this.getResource<MctNotificationResponseEntity>(
      token,
      '/v1/Notifications',
    );
  }

  /**
   * Retrieves all organizations. While Microsoft provides this as an array, we
   * most likely will ever only have one organization.
   *
   * https://hypehomeroomb2c.azurewebsites.net/swagger/index.html?urls.primaryName=V1#operations-tag-Organization
   */
  static async getOrganizationList(token: string): Promise<number[]> {
    // Hard-code the org value, as this will likely never change.
    return Promise.resolve([1]);
  }

  /**
   * Retrieves all groups a user is a part of.
   */
  static async getUserGroups(
    token: string,
    userId: number,
  ): Promise<MctGroupInfo[]> {
    return this.getAdminResource<MctGroupInfo[]>(
      token,
      `/v1/users/${userId}/groups`,
    );
  }

  /**
   * Retrieves all registered users.
   */
  static async getUserList(token: string): Promise<MctMlxUser[]> {
    const response = await this.getAdminResource<MctAllUsersModel>(
      token,
      '/v2/admin/users',
    );

    // TODO: get all users by paginating through results (see
    // response.NextPageLink and response.TotalUsers).
    return response.UserDetails;
  }

  /** Retrieves a resource from MCT through the API proxy. */
  private static async getResource<T = any>(
    token: string,
    endpoint: string,
    query?: { [name: string]: string },
    proxyUrl = PROXY_URL,
  ): Promise<T> {
    const proxy = new URL(proxyUrl);

    if (query) {
      for (const name of Object.keys(query)) {
        proxy.searchParams.append(name, query[name]);
      }
    }

    proxy.searchParams.set('endpoint', endpoint);

    const response = await fetch(proxy.toString(), {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (response.status >= 400) {
      throw new Error('Bad request error from MCT');
    }

    return response.json();
  }

  /**
   * Retrieves a resource from MCT through the API proxy using
   * service-to-service authentication. Some endpoints are not accessible to
   * all authenticated users. For those endpoints, we use a proxy that has its
   * own valid credentials.
   */
  private static async getAdminResource<T = any>(
    token: string,
    endpoint: string,
    query?: { [name: string]: string },
  ): Promise<T> {
    return this.getResource(token, endpoint, query, ADMIN_PROXY_URL);
  }

  /** Updates a resource in MCT through the API proxy. */
  private static async postResource<T = any>(
    token: string,
    endpoint: string,
    body: object,
    method = 'POST',
    proxyUrl = PROXY_URL,
  ): Promise<T> {
    const proxy = new URL(proxyUrl);

    proxy.searchParams.set('endpoint', endpoint);

    const response = await fetch(proxy.toString(), {
      method,
      body: JSON.stringify(body),
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (response.status >= 400) {
      throw new Error('Bad request error from MCT');
    }

    return response.json();
  }

  /**
   * Updates a resource in MCT through the API proxy using service-to-service
   * authentication. Some endpoints are not accessible to all authenticated
   * users. For those endpoints, we use a proxy that has its own valid
   * credentials.
   */
  private static async postAdminResource<T = any>(
    token: string,
    endpoint: string,
    body: object,
    method = 'POST',
  ): Promise<T> {
    return this.postResource(token, endpoint, body, method, ADMIN_PROXY_URL);
  }
}

function getProfileField(
  name: string,
  value: string,
  mapId: number,
  order: number,
) {
  return {
    Value: value,
    DefaultValue: null,
    ValidationLogic: null,
    MappingId: mapId,
    Name: [
      {
        LanguageCode: 'en-US',
        Value: name,
        IsDefault: true,
      },
    ],
    Type: 'TextfieldUnit',
    DisplayOrder: order,
    Editable: true,
    Mandatory: false,
    Hidden: false,
  };
}
