import { logout } from 'src/libraries/msal';
import styled from 'styled-components';
import { Button } from './buttons/Button';

interface NotFoundProps {
  msg?: string;
}

const NotFoundWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 3rem 1rem;
`;

const Title = styled.h1`
  margin: 0 0 -1rem;
  opacity: 0.2;
  padding: 0;
`;

const DEFAULT_MSG =
  "Unfortunately, we can't find the content you're looking for :/";

export function NotFound({ msg }: NotFoundProps) {
  return (
    <NotFoundWrapper>
      <Title>404</Title>
      <div>{msg ?? DEFAULT_MSG}</div>
      <Button onClick={logout}>Log out</Button>
    </NotFoundWrapper>
  );
}
