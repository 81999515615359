import styled from 'styled-components';

export const Form = styled.form`
  width: 80%;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.grey.string()};
  font-size: 18px;
`;

export const Input = styled.input`
  border: 2px solid ${(props) => props.theme.violet.string()};
  border-radius: 8px;
  font-size: 18px;
  margin: 8px 0 15px 0;
  padding: 13px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  border: 2px solid ${(props) => props.theme.violet.string()};
  border-radius: 8px;
  font-size: 18px;
  margin: 8px 0 15px 0;
  padding: 13px;
  resize: none;
  width: 100%;
`;
