import Color from 'color';
import { DefaultTheme } from 'styled-components';

export const COLOR_BLACK = Color('#0d011c');
export const COLOR_GREY = Color('#636463');
export const COLOR_LAVENDER = Color('#cec2fc');
export const COLOR_LIGHT_PURPLE = Color('#e1dcee');
export const COLOR_LIGHT_YELLOW = Color('#ffffec');
export const COLOR_MINT = Color('#c2ffc1');
export const COLOR_PINK = Color('#efb4fe');
export const COLOR_PURPLE = Color('#734ed9');
export const COLOR_RED = Color('#bc3a3a');
export const COLOR_TEAL = Color('#68c1b9');
export const COLOR_VIOLET = Color('#330166');
export const COLOR_YELLOW = Color('#f4ed27');
export const COLOR_WHITE = Color('#fcfcfc');

export const theme: DefaultTheme = {
  // Branding
  backgroundColor: COLOR_WHITE,
  black: COLOR_BLACK,
  grey: COLOR_GREY,
  lavender: COLOR_LAVENDER,
  lightPurple: COLOR_LIGHT_PURPLE,
  lightYellow: COLOR_LIGHT_YELLOW,
  mint: COLOR_MINT,
  pink: COLOR_PINK,
  purple: COLOR_PURPLE,
  red: COLOR_RED,
  teal: COLOR_TEAL,
  textColor: COLOR_BLACK,
  violet: COLOR_VIOLET,
  white: COLOR_WHITE,
  yellow: COLOR_YELLOW,

  // Fonts
  fontFamily: '"adonis-web", serif',
  fontSize: '18px',
  fontWeight: '400',
  fontWeightBold: '700',
  funFontFamily: '"Bangers", sans-serif',
  inputFontFamily: '"Brown", sans-serif',
  lineHeight: '1.6',

  // Inputs and buttons
  inputBorderRadius: '6px',
  inputFontSize: '0.8rem',
  inputHeight: '40px',

  // Miscellaneous
  transitionTime: '200ms',
};
