import { ClickableCard, getColorByHash } from 'src/shared';
import { Course } from 'src/state';
import styled from 'styled-components';
import { getProgressBarMessage } from './getProgressBarMessage';

interface CourseCardProps {
  course: Course;
  onClick: () => void;
}

const COURSE_CARD_SIZE = '180px';

const CourseCardWrapper = styled(ClickableCard).attrs({
  height: COURSE_CARD_SIZE,
  width: COURSE_CARD_SIZE,
})`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  text-align: right;
`;

const CourseName = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  line-height: 1;
`;

// The progress bar is determined by the background size.
const ProgressBar = styled.div<{ percentage: number }>`
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.yellow.string()} 100%,
    #000 0%
  );
  background-repeat: no-repeat;
  background-size: ${(props) => props.percentage}%;
  border: 2px solid ${(props) => props.theme.yellow.string()};
  font-family: ${(props) => props.theme.inputFontFamily};
  font-size: 0.7rem;
  padding: 2px 5px;
  text-align: left;
  text-transform: uppercase;
  width: 90%;
`;

export function CourseCard({ course, onClick }: CourseCardProps) {
  const message = getProgressBarMessage(course.percentage);
  const colorProp = { [getColorByHash(course.id)]: true };

  return (
    <CourseCardWrapper {...colorProp} onClick={onClick}>
      <CourseName>{course.name}</CourseName>
      <ProgressBar percentage={course.percentage}>{message}</ProgressBar>
    </CourseCardWrapper>
  );
}
