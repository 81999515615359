import { BadgeAvatar, BadgeEnum, BADGE_SOURCES } from 'src/libraries/avatars';
import { Card, getColorByHash } from 'src/shared';

interface BadgeProps {
  badge: BadgeEnum;
  size: string;
}

export const ProfileBadge = (props: BadgeProps) => {
  const cardColor = getColorByHash(BADGE_SOURCES[props.badge]);
  const cardColorProp = { [cardColor]: true };

  return (
    <Card {...cardColorProp}>
      <BadgeAvatar {...props} />
    </Card>
  );
};
