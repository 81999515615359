import React from 'react';
import ReactDOM from 'react-dom/client';
import { HomeroomApp } from './pages/root/HomeroomApp';
import { logDebug, reportWebVitals } from './utils';

const root = ReactDOM.createRoot(
  document.getElementById('homeroom-root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <HomeroomApp />
  </React.StrictMode>,
);

reportWebVitals(logDebug);
