import { createContext } from 'react';
import { MemberType } from 'src/auth/types';
import { DEFAULT_COURSE_STATE } from './course/constants';
import { ApplicationState } from './types';
import { DEFAULT_USER_STATE } from './user/constants';

export const DEFAULT_APP_STATE: ApplicationState = {
  ...DEFAULT_COURSE_STATE,
  ...DEFAULT_USER_STATE,
  memberType: MemberType.Student,

  // Notifications & announcements.
  isLoadingNotifications: false,
  notifications: [],
  onRefreshNotifications: () => {},
};

/** Context for the application state. */
export const AppContext = createContext<ApplicationState>(DEFAULT_APP_STATE);
