import Color from 'color';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { COLOR_TEAL } from 'src/shared/theme';
import styled, { css } from 'styled-components';
import { getColor } from '../../colors/getColor';
import { Frills } from './Frills';
import { ButtonLinkProps, ButtonProps } from './types';

const Background = styled.span``;

const ButtonBorder = styled.div``;

const ButtonWrapper = styled.button<{
  borderColor?: Color;
  disabled?: boolean;
  themeColor: Color;
}>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.inputFontFamily};
  font-size: 0.8rem;
  margin: 0 0.5rem;
  padding: 0;
  position: relative;

  ${Background} {
    background-color: ${(props) => props.themeColor.string()};
    border: 1px solid ${(props) => props.themeColor.string()};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    box-sizing: border-box;
    height: 100%;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: left ${(props) => props.theme.transitionTime},
      top ${(props) => props.theme.transitionTime};
    width: 100%;
  }

  ${ButtonBorder} {
    border: 2px solid
      ${(props) =>
        props.disabled
          ? props.theme.grey.string()
          : (props.borderColor || props.theme.purple).string()};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    padding: 0.5rem 1rem;
    position: relative;
    text-transform: uppercase;
    transition: border-color ${(props) => props.theme.transitionTime};
    user-select: none;

    &:focus {
      border: 3px solid ${(props) => props.theme.black.string()};
      outline: none;
    }
  }

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        ${Background} {
          left: 0;
          top: 0;
        }

        ${ButtonBorder} {
          border-color: transparent;
        }
      }

      &:active {
        box-shadow: inset 0px 2px 8px -1px ${props.themeColor.darken(0.16).string()};
      }
    `}
`;

const ButtonLinkWrapper = styled(ButtonWrapper)`
  color: ${(props) => props.theme.black.string()};
  display: inline-block;
  text-decoration: none;
`;

export function Button(props: ButtonProps) {
  const [active, setActive] = useState(false);
  const themeColor = getColor(props, COLOR_TEAL);
  // Remove custom props from regular HTML props.
  const { as, children, lavender, pink, ref, teal, yellow, ...buttonProps } =
    props;

  return (
    <ButtonWrapper
      onAnimationEnd={() => setActive(false)}
      onMouseDown={() => setActive(true)}
      themeColor={themeColor}
      {...buttonProps}>
      <Background />
      <ButtonBorder>
        <Frills active={active} themeColor={themeColor} />
        <span>{props.children}</span>
        <Frills active={active} themeColor={themeColor} />
      </ButtonBorder>
    </ButtonWrapper>
  );
}

/**
 * Renders a link styled as a button. This component must be kept in sync with
 * the button above.
 */
export function ButtonLink(props: ButtonLinkProps) {
  const [active, setActive] = useState(false);
  const themeColor = getColor(props, COLOR_TEAL);
  // Remove custom props from regular HTML props.
  const {
    as,
    children,
    href,
    lavender,
    pink,
    ref,
    teal,
    yellow,
    ...linkProps
  } = props;

  return (
    <Link to={href}>
      <ButtonLinkWrapper
        as="a"
        onAnimationEnd={() => setActive(false)}
        onMouseDown={() => setActive(true)}
        themeColor={themeColor}
        {...linkProps}>
        <Background />
        <ButtonBorder>
          <Frills active={active} themeColor={themeColor} />
          <span>{props.children}</span>
          <Frills active={active} themeColor={themeColor} />
        </ButtonBorder>
      </ButtonLinkWrapper>
    </Link>
  );
}
