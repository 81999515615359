import styled from 'styled-components';
import { getColorFromProps } from '../../colors/getColor';
import { CardProps } from './types';

export const Card = styled.div<CardProps>`
  background-color: ${(props) => getColorFromProps(props).string()};
  border: none;
  border-radius: 8px;
  box-shadow: 5px 5px 0px ${(props) => props.theme.purple.string()};
  display: inline-block;
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: 1rem;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const ClickableCard = styled(Card).attrs({ as: 'button' })`
  transition: box-shadow ${(props) => props.theme.transitionTime};

  &:hover {
    box-shadow: 7px 7px 0px ${(props) => props.theme.purple.string()};
  }

  &:active {
    box-shadow: 2px 2px 0px ${(props) => props.theme.purple.string()};
  }
`;
