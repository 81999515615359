import { FirestoreProfile } from 'src/libraries/firestore';
import { MctApi } from 'src/libraries/mct';
import { logWithCause } from 'src/utils';
import { Group } from '../entities/group';
import { Profile } from '../entities/profile';
import { User } from '../entities/user';
import { DEFAULT_USER_STATE } from './constants';
import { UserState } from './types';

export async function getUserState(token: string): Promise<UserState> {
  const state: UserState = { ...DEFAULT_USER_STATE };

  // Retrieve all users from the MCT API.
  try {
    const mctUsers = await MctApi.getUserList(token);

    state.users.push(...mctUsers.map(User.fromMctEntity));
  } catch (error) {
    logWithCause('Could not retrieve user list from MCT.', error);
  }

  // Retrieve the profile data for the authenticated user. The data might be
  // sparse, but at the least this allows us to get their email address.
  try {
    const mctProfileData = await MctApi.getProfileData(token);

    state.profile = Profile.fromMctProfileData(mctProfileData);
  } catch (error) {
    logWithCause('Could not retrieve profile data.', error);
  }

  state.currentUser = state.users.find((u) => u.email === state.profile?.email);

  // Use the email address to get the org-based user profile. This should
  // include all custom fields.
  if (state.currentUser) {
    try {
      const mctUiProfileFields = await MctApi.getOrgUserProfile(
        token,
        state.currentUser,
      );

      state.profile = Profile.fromMctUiProfileFields(mctUiProfileFields);
    } catch (error) {
      logWithCause('Could not retrieve org-based user profile.', error);
    }
  }

  // Retrieve a list of all groups the logged-in user is a part of.
  if (state.currentUser) {
    try {
      const mctGroups = await MctApi.getUserGroups(token, state.currentUser.id);
      const userGroups = mctGroups.map(Group.fromMctEntity);

      state.currentUser = state.currentUser.withGroups(userGroups);
    } catch (error) {
      logWithCause('Could not retrieve users groups.', error);
    }
  }

  // Check for extra profile data in Firestore.
  if (state.profile) {
    try {
      const homeroomProfile = state.profile.copy();
      state.profile = await FirestoreProfile.getProfile(homeroomProfile);

      // If the Firestore and Homeroom profiles are different, import the
      // Firestore profile into MCT and delete the Firestore profile.
      if (!state.profile.isEqual(homeroomProfile)) {
        // Update Homeroom profile.
        await MctApi.updateProfileData(token, state.profile);

        // Delete Firestore profile.
        await FirestoreProfile.deleteProfile(state.profile);
      }
    } catch (error) {
      logWithCause('Could not retrieve extra profile data.', error);
    }
  }

  return state;
}
