import { MctProfileData } from '../types/profile';
import { Avatar } from './Avatar';

export class Profile {
  /** Constructs a {@link Profile} entity from an MCT API response. */
  static fromEntity(profile: MctProfileData) {
    const email = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'Contact';
    });
    const firstName = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'First Name';
    });
    const lastName = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'Last Name';
    });
    const avatar = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'HomeroomAvatar';
    });
    const bio = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'HomeroomBio';
    });
    const hypeText = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'HomeroomHypeText';
    });
    const favoriteEmoji = profile.ProfileFields.find((profileField) => {
      return profileField.Name === 'HomeroomEmoji';
    });

    return new Profile(
      email?.Value ?? '',
      firstName?.Value ?? '',
      lastName?.Value ?? '',
      Avatar.parse(avatar?.Value ?? ''),
      bio?.Value ?? '',
      hypeText?.Value ?? '',
      favoriteEmoji?.Value ?? '',
    );
  }

  /** Creates a copy of this {@link Profile} with the updated avatar. */
  withAvatar(avatar: Avatar) {
    return new Profile(
      this.email,
      this.firstName,
      this.lastName,
      avatar,
      this.bio,
      this.hypeText,
      this.favoriteEmoji,
    );
  }

  /** Creates a copy of this {@link Profile} with the updated details. */
  withDetails(
    email: string,
    firstName: string,
    lastName: string,
    bio: string,
    hypeText: string,
  ) {
    return new Profile(
      email,
      firstName,
      lastName,
      this.avatar,
      bio,
      hypeText,
      this.favoriteEmoji,
    );
  }

  constructor(
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly avatar: Avatar,
    readonly bio: string,
    readonly hypeText: string,
    readonly favoriteEmoji: string,
  ) {}
}
