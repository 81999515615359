import { FirstHeading, SecondHeading } from 'src/shared';
import { Course, Lesson } from 'src/state';
import styled from 'styled-components';
import { LessonResources } from './Resources';

interface LessonDetailsProps {
  course: Course;
  selectedLesson: Lesson | null;
}

const LessonDetailsWrapper = styled.div`
  width: 40%;
`;

export function LessonDetails({ course, selectedLesson }: LessonDetailsProps) {
  return (
    <LessonDetailsWrapper>
      <FirstHeading>{course.name}</FirstHeading>

      {selectedLesson ? (
        <>
          <SecondHeading margin="0">{selectedLesson.name}</SecondHeading>
          <p>
            {selectedLesson.startTimestamp?.toLocaleDateString(undefined, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </p>
          <p>{selectedLesson.description}</p>
          <LessonResources resources={selectedLesson.resources} />
        </>
      ) : null}
    </LessonDetailsWrapper>
  );
}
