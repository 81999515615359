import { useEffect, useState } from 'react';
import { useTokens } from 'src/libraries/msal';
import { withCause } from 'src/utils';
import { User } from '../entities/user';
import { DEFAULT_COURSE_STATE } from './constants';
import { getCourseState } from './getCourseState';
import { CourseState } from './types';

/** Provides the course state as a hook. */
export function useCourseState(user?: User) {
  const [timestamp, setTimestamp] = useState(Date.now());
  const [partialState, setPartialState] = useState<Partial<CourseState>>({});
  const [isLoadingCourseState, setLoading] = useState(true);
  const { idToken } = useTokens();

  useEffect(() => {
    if (!idToken || !user) {
      return;
    }

    setLoading(true);

    getCourseState(idToken, user)
      .then(setPartialState)
      .catch(withCause('Could not retrieve course state.'))
      .finally(() => void setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, idToken, user?.id]);

  /** Refreshes the course state. */
  function onRefreshCourseState() {
    setTimestamp(Date.now());
  }

  const state: CourseState = {
    ...DEFAULT_COURSE_STATE,
    ...partialState,
    isLoadingCourseState,
    onRefreshCourseState,
  };

  return state;
}
