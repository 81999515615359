import { parseToYearMonthDay } from './parseToYearMonthDay';

/** Returns the current date YYYY-MM-DD date string. */
export function getCurrentYearMonthDayDate() {
  const date = new Date();

  return parseToYearMonthDay(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
}
