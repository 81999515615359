import {
  AvatarStyle,
  DEFAULT_OPEN_PEEPS_OPTIONS,
  EmojiEnum,
  OpenPeepsOptions,
} from '../../avatars';

export class Avatar {
  static DEFAULT: Avatar = new Avatar('emoji', {
    emoji: EmojiEnum.AngryFace,
    openPeeps: { ...DEFAULT_OPEN_PEEPS_OPTIONS },
  });

  /**
   * Parses a JSON-encoded string into a new Avatar instance. This will always
   * return a value. If the encoded string cannot be parsed,
   * {@link Avatar.DEFAULT} will be returned.
   */
  static parse(input: string) {
    try {
      const data = {
        ...Avatar.DEFAULT.serialize(),
        ...JSON.parse(atob(input)),
      };

      return new Avatar(data.style, {
        emoji: data.emoji,
        openPeeps: data.openPeeps,
      });
    } catch {
      return Avatar.DEFAULT;
    }
  }

  /** Serializes this Avatar into a plain JavaScript object. */
  serialize() {
    return {
      style: this.style,
      ...this.data,
    };
  }

  /** Base64 encoding of this {@link Avatar} instance. */
  encode() {
    return btoa(JSON.stringify(this.serialize()));
  }

  /** Creates a copy of this {@link Avatar}. */
  copy() {
    return new Avatar(this.style, { ...this.data });
  }

  constructor(readonly style: AvatarStyle, readonly data: AvatarData) {}
}

export interface AvatarData {
  emoji: EmojiEnum;
  openPeeps: OpenPeepsOptions;
}
