import { Location } from 'react-router-dom';
import { isAuthRoute } from '../shared/routes';
import { IS_DEVELOPMENT_ENV, IS_STAGING_ENV } from './env';

/** Retrieves the current URL path. */
export function getCurrentPath(location: Location) {
  return location.pathname;
}

/** Returns the host name, based on the deployed environment. */
export function getProtocolAndHost() {
  if (IS_DEVELOPMENT_ENV) {
    return 'http://localhost';
  }

  if (IS_STAGING_ENV) {
    return 'https://preview.hypehomeroom.com';
  }

  return 'https://hypehomeroom.com';
}

/** Retrieves the value of a Next router query parameter. */
export function getQueryParamValue(param?: string | string[] | null): string {
  if (!param) {
    return '';
  }

  return typeof param === 'string' ? param : String(param[0]);
}

/** Determines the path to redirect to after authenticating. */
export function getRedirectPath(location: Location) {
  const params = new URLSearchParams(location.search);

  if (params.has('next')) {
    return sanitizePath(params.get('next'));
  }

  return isAuthRoute(location.pathname) ? '/' : getCurrentPath(location);
}

/** Sanitizes a user-provided path, e.g. a redirect path. */
export function sanitizePath(next?: string | string[] | null) {
  const paths = getQueryParamValue(next)
    .split('/')
    // Remove invalid characters.
    .map((path) => path.replace(/[^a-zA-Z0-9_.,+:\-/]+/, ''))
    // Filter-out invalid paths.
    .filter((path) => path?.length > 0);

  return `/${paths.join('/')}`;
}
