/**
 * Button frills inspired from https://codepen.io/alexzaworski/pen/bNqoRW.
 */
import Color from 'color';
import styled, { css, keyframes } from 'styled-components';

const splashSize = '1rem';
const splashDuration = '300ms';
const frillWidth = 4;
const rotation = '34deg';
const stagger = '15px'; // Distance between center and edge frills.
const timingFn = 'ease-out';
const width = 20;

const moveLeft = keyframes`
  0% {
    transform: none;
  }
  65% {
    transform: translateX(-${splashSize});
  }
  100% {
    transform: translateX(-${splashSize});
  }
`;

const moveRight = keyframes`
  0% {
    transform: none;
  }
  65% {
    transform: translateX(${splashSize});
  }
  100% {
    transform: translateX(${splashSize});
   }
`;

const moveUp = keyframes`
  0% {}
  100% {
    bottom: 2rem;
  }
`;

const moveDown = keyframes`
  0% {}
  100% {
    top: 2rem;
  }
`;

const widthToZero = keyframes`
  0% {
    width: ${width}px;
  }
  100% {
    width: ${frillWidth}px;
  }
`;

export const Frills = styled.span<{ active: boolean; themeColor: Color }>`
  &,
  &:after,
  &:before {
    position: absolute;
    background: ${(props) => props.themeColor.string()};
    border-radius: ${frillWidth / 2}px;
    height: ${frillWidth}px;
  }

  &:after,
  &:before {
    content: '';
    display: block;
  }

  &:after {
    top: 1rem;
  }

  &:before {
    bottom: 1rem;
  }

  &:first-child,
  &:last-child {
    top: 1rem;
  }

  &:first-child {
    left: -40px;

    ${(props) =>
      props.active &&
      css`
        animation: ${splashDuration} ${moveLeft} ${timingFn},
          ${splashDuration} ${widthToZero} ${timingFn};
      `}

    &:after,
    &:before {
      left: ${stagger};
    }
    &:after {
      ${(props) =>
        props.active &&
        css`
          animation: ${splashDuration} ${widthToZero} ${timingFn},
            ${splashDuration} ${moveDown} ${timingFn};
        `}
    }
    &:before {
      ${(props) =>
        props.active &&
        css`
          animation: ${splashDuration} ${widthToZero} ${timingFn},
            ${splashDuration} ${moveUp} ${timingFn};
        `}
    }
  }

  &:last-child {
    right: -40px;

    ${(props) =>
      props.active &&
      css`
        animation: ${splashDuration} ${moveRight} ${timingFn},
          ${splashDuration} ${widthToZero} ${timingFn};
      `}

    &:after,
    &:before {
      right: ${stagger};
    }
    &:after {
      ${(props) =>
        props.active &&
        css`
          animation: ${splashDuration} ${widthToZero} ${timingFn},
            ${splashDuration} ${moveDown} ${timingFn};
        `}
    }
    &:before {
      ${(props) =>
        props.active &&
        css`
          animation: ${splashDuration} ${widthToZero} ${timingFn},
            ${splashDuration} ${moveUp} ${timingFn};
        `}
    }
  }

  &:first-child:before,
  &:last-child:after {
    transform: rotate(${rotation});
  }

  &:first-child:after,
  &:last-child:before {
    transform: rotate(-${rotation});
  }
`;
