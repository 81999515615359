import { logout } from 'src/libraries/msal';
import { Button } from 'src/shared';
import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.lightPurple.string()};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px;
`;

export function NotFound() {
  return (
    <NotFoundWrapper>
      <div>We couldn't find your profile :/</div>
      <Button onClick={logout}>Log out</Button>
    </NotFoundWrapper>
  );
}
