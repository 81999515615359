import { useState } from 'react';
import { Menu, X } from 'react-feather';
import styled from 'styled-components';
import { NavigationProps } from '../types';
import {
  CalendarSquare,
  CourseSquare,
  GamesSquare,
  ProfileSquare,
} from './Squares';

const TAB_ICON_SIZE = 30;

const OuterWrapper = styled.div`
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const NavigationWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
`;

const HamburgerMenu = styled.div`
  position: absolute;
  right: 27px;
  top: 10px;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

export function MobileNavigation({ children, route }: NavigationProps) {
  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive((currMenuActive) => !currMenuActive);
  }

  return (
    <OuterWrapper>
      <HamburgerMenu onClick={toggleMenu}>
        {menuActive ? (
          <X size={TAB_ICON_SIZE} />
        ) : (
          <Menu size={TAB_ICON_SIZE} />
        )}
      </HamburgerMenu>

      {menuActive && (
        <NavigationWrapper>
          <CalendarSquare />
          <CourseSquare />
          <ProfileSquare />
          <GamesSquare />
        </NavigationWrapper>
      )}

      {children}
    </OuterWrapper>
  );
}
