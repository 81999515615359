import { useState } from 'react';
import { useMemberType } from 'src/auth/hooks/useMemberType';
import { useTokens } from 'src/libraries/msal';
import { logDebug } from 'src/utils';
import { AppContext, DEFAULT_APP_STATE } from './context';
import { useCourseState } from './course/useCourseState';
import { useHomeroomNotifications } from './notification/useHomeroomNotifications';
import { ApplicationState } from './types';
import { useUserState } from './user/useUserState';

interface ProviderProps {
  children: React.ReactNode;
}

/**
 * Provides the app state to child components. This is also where data is merged
 * from different sources in order to create the Homeroom entities.
 */
export function AppStateProvider({ children }: ProviderProps) {
  const { memberType } = useMemberType();
  const userState = useUserState();
  const courseState = useCourseState(userState.currentUser);

  // These timestamps enable us to update the state with new data.
  const initialTimestamp = Date.now();
  const [notificationsTimestamp, setNotificationsTimestamp] =
    useState(initialTimestamp);

  const { idToken } = useTokens();
  const { notifications, isLoadingNotifications } = useHomeroomNotifications(
    idToken,
    notificationsTimestamp,
  );

  /** Refreshes the list of notifications. */
  function onRefreshNotifications() {
    setNotificationsTimestamp(Date.now());
  }

  const state: ApplicationState = {
    ...DEFAULT_APP_STATE,
    ...courseState,
    ...userState,
    memberType,

    // Notifications & announcements.
    isLoadingNotifications,
    notifications,
    onRefreshNotifications,
  };

  logDebug('Updated application state', state);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
}
