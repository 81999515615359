export enum CalendarView {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export interface MonthYearPair {
  month: number;
  year: number;
}

export interface DayMonthYear {
  day: number;
  month: number;
  year: number;
}

export interface CustomDateObject {
  dayOfMonth: number;
  dayOfWeek: number; // (Monday = 0 --> Sunday = 6)
  month: number; // (January = 0 --> December = 11)
  year: number;
}

export interface CalendarViewProps {
  selectedDate: CustomDateObject;
  onDateUpdate: (date: CustomDateObject) => void;
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const HOURS = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
