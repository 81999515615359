import { Board, BoardSequence } from '../types';

/** Returns the indices and marks of one of the board's columns. */
export function getColumn(column: number, board: Board): BoardSequence {
  const firstIndex = column;
  const secondIndex = firstIndex + 3;
  const thirdIndex = firstIndex + 6;

  return {
    indices: [firstIndex, secondIndex, thirdIndex],
    marks: [board[firstIndex], board[secondIndex], board[thirdIndex]],
  };
}
