import { Board, Winner } from '../types';
import { getColumn } from './getColumn';
import { getDiagonal } from './getDiagonal';
import { getRow } from './getRow';
import { isConsecutiveThree } from './isConsecutiveThree';

/** Determines if a mark appears three times in a row, column or diagonal. */
export function getWinner(board: Board): Winner {
  // At least 5 marks must be on the board for someone to win.
  if (board.filter(Boolean).length < 5) {
    return null;
  }

  for (let i = 0; i < 3; i++) {
    const column = getColumn(i, board);

    if (isConsecutiveThree(column.marks)) {
      return column;
    }

    const diagonal = getDiagonal(i, board);

    if (isConsecutiveThree(diagonal.marks)) {
      return diagonal;
    }

    const row = getRow(i, board);

    if (isConsecutiveThree(row.marks)) {
      return row;
    }
  }

  return null;
}
