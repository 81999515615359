import { useState } from 'react';
import { SecondHeading } from 'src/shared';
import styled from 'styled-components';
import { EndMenu } from './EndMenu';
import { Puzzle } from './Puzzle';
import { StartMenu } from './StartMenu';
import { Difficulty } from './types';

const Wrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

export function Sudoku() {
  const [difficulty, setDifficulty] = useState(Difficulty.Easy);
  const [showStartMenu, setShowStartMenu] = useState(true);
  const [showPuzzle, setShowPuzzle] = useState(false);
  const [showEndMenu, setShowEndMenu] = useState(false);

  function chooseDifficulty(difficulty: Difficulty) {
    setDifficulty(difficulty);
  }

  function isolateStartMenu() {
    setShowStartMenu(true);
    setShowPuzzle(false);
    setShowEndMenu(false);
  }

  function isolatePuzzle() {
    setShowStartMenu(false);
    setShowPuzzle(true);
    setShowEndMenu(false);
  }

  function isolateEndMenu() {
    setShowStartMenu(false);
    setShowPuzzle(false);
    setShowEndMenu(true);
  }

  return (
    <Wrapper>
      <SecondHeading margin="0 0 1.5rem 0">Sudoku</SecondHeading>
      <ContentWrapper>
        {showStartMenu && (
          <StartMenu
            beginMatch={isolatePuzzle}
            chooseDifficulty={chooseDifficulty}
            difficulty={difficulty}
          />
        )}

        {showPuzzle && (
          <Puzzle difficulty={difficulty} onSolved={isolateEndMenu} />
        )}

        {showEndMenu && (
          <EndMenu
            backToStartMenu={isolateStartMenu}
            playAgain={isolatePuzzle}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
}
