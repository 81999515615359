import { useState } from 'react';
import { Emoji, SUPPORTED_EMOJIS } from 'src/libraries/avatars';
import { logout } from 'src/libraries/msal';
import {
  AvatarCard,
  Button,
  FirstHeading,
  Link,
  Loading,
  SecondHeading,
} from 'src/shared';
import { useAppState } from 'src/state';
import styled from 'styled-components';
import { getProfileBadges } from './badge/getProfileBadges';
import { ProfileBadge } from './badge/ProfileBadge';
import { Popup } from './editor/Popup';
import { NotFound } from './NotFound';

const ProfileWrapper = styled.div`
  background-color: ${(props) => props.theme.lightPurple.string()};
  display: flex;
  min-height: 100vh;
  padding-top: 80px;
`;

const AvatarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const ProfileDetailsWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 80px 0 40px;
`;

const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  div {
    margin: 0 30px 30px 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TextHeader = styled.h3`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 0;
  padding-right: 10px;
  width: 200px;
`;

const TextBody = styled.div`
  font-size: 20px;
  margin: 0;
  width: 80%;
`;

const ButtonsWrapper = styled.div`
  margin: 2rem auto 1rem;
`;

export function Profile() {
  const [popup, setPopup] = useState(false);
  const [popupView, setPopupView] = useState('');
  const { registeredCourses } = useAppState();
  const { isLoadingUserState, profile } = useAppState();

  const { badges } = getProfileBadges(registeredCourses);

  function handleOpenEditor(view: string) {
    setPopup(true);
    setPopupView(view);
  }

  function handleCloseEditor() {
    setPopup(false);
  }

  if (isLoadingUserState) {
    return (
      <Loading
        badgeSize="200px"
        height="100vh"
        padding="80px 0 0 0"
        textSize="24px"
      />
    );
  }

  if (!profile) {
    return <NotFound />;
  }

  return (
    <ProfileWrapper>
      <AvatarWrapper>
        <AvatarCard avatar={profile.avatar} size={180} />
        <p>
          <Link href="#?" onClick={() => handleOpenEditor('avatar')} teal>
            Edit my avatar
          </Link>
        </p>
      </AvatarWrapper>

      <ProfileDetailsWrapper>
        <SecondHeading>Hey {profile.firstName}!</SecondHeading>

        <TextContainer>
          <TextHeader>Email:</TextHeader>
          <TextBody>{profile.email || 'No email available.'}</TextBody>
        </TextContainer>

        <TextContainer>
          <TextHeader>Bio:</TextHeader>
          <TextBody>
            {profile.bio ||
              'Edit your profile to introduce yourself to your classmates.'}
          </TextBody>
        </TextContainer>

        <TextContainer>
          <TextHeader>What gets you HYPE:</TextHeader>
          <TextBody>
            {profile.hypeText || 'Let us know by editing your profile.'}
          </TextBody>
        </TextContainer>

        <TextContainer>
          <TextHeader>Favorite Emoji:</TextHeader>
          <TextBody>
            {profile.favoriteEmoji ? (
              <Emoji
                emoji={SUPPORTED_EMOJIS[Number(profile.favoriteEmoji)]}
                size="100px"
              />
            ) : (
              <p>Add your favorite emoji by editing your profile.</p>
            )}
          </TextBody>
        </TextContainer>

        <FirstHeading>
          You&apos;ve got {badges.length || 'no'} badge
          {badges.length === 1 ? '' : 's'}!
        </FirstHeading>

        {badges.length ? (
          <Badges>
            {badges.map((badge) => (
              <ProfileBadge badge={badge} key={badge} size="80px" />
            ))}
          </Badges>
        ) : (
          <p>Complete courses to earn badges.</p>
        )}

        <ButtonsWrapper>
          <Button onClick={() => handleOpenEditor('details')}>
            Edit My Profile
          </Button>
          <Button onClick={logout}>Log out</Button>
        </ButtonsWrapper>

        {popup && (
          <Popup
            initialView={popupView}
            onClose={handleCloseEditor}
            profile={profile}
          />
        )}
      </ProfileDetailsWrapper>
    </ProfileWrapper>
  );
}
