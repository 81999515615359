import { Button } from 'src/shared';
import styled from 'styled-components';
import { Preview } from './Preview';
import { Difficulty } from './types';

interface StartMenuProps {
  beginMatch: () => void;
  chooseDifficulty: (difficulty: Difficulty) => void;
  difficulty: Difficulty;
}

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const Instructions = styled.h3`
  color: ${(props) => props.theme.violet.string()};
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 32px;
  margin-bottom: 1.2rem;
`;

const DifficultyWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.2rem;
  width: 100%;
`;

export function StartMenu({
  beginMatch,
  chooseDifficulty,
  difficulty,
}: StartMenuProps) {
  return (
    <Wrapper>
      <Instructions>
        Fill all the squares with numbers from 1 to 9 without repeating a number
        in the same row, column, or 3x3 square.
      </Instructions>
      <Preview />
      <DifficultyWrapper>
        {Object.values(Difficulty).map((diff) => (
          <Button
            key={diff}
            lavender={diff === difficulty}
            onClick={() => chooseDifficulty(diff)}>
            {diff}
          </Button>
        ))}
      </DifficultyWrapper>
      <Button onClick={beginMatch} pink>
        Begin
      </Button>
    </Wrapper>
  );
}
