import { useState } from 'react';
import { Lock } from 'react-feather';
import { ClickableCard, Modal } from 'src/shared';
import { LessonResource } from 'src/state';
import styled from 'styled-components';
import { ButtonLessonItem } from '../ClickableLessonItem';
import { LessonResourceIcon } from './LessonResourceIcon';
import { LessonResourceItem } from './LessonResourceItem';

interface LessonResourcesProps {
  resources: LessonResource[];
}

const ResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ResourceCard = styled(ClickableCard).attrs({ as: 'span' })`
  box-shadow: 5px 5px 0px ${(props) => props.theme.teal.string()};
  display: flex;
  gap: 0.5rem;

  &:hover {
    box-shadow: 7px 7px 0px ${(props) => props.theme.teal.string()};
  }

  &:active {
    box-shadow: 2px 2px 0px ${(props) => props.theme.teal.string()};
  }
`;

export function LessonResources({ resources }: LessonResourcesProps) {
  const [embed, setEmbed] = useState('');
  const showEmbed = embed.trim().length > 0;

  function handleHideEmbed() {
    setEmbed('');
  }

  if (!resources.length) {
    return null;
  }

  return (
    <ResourcesWrapper>
      {resources.map((resource, index) =>
        index > 0 && resource.canBeLocked && !resources[index - 1].completed ? (
          <ButtonLessonItem key={resource.id}>
            <ResourceCard lavender>
              <Lock />
              {resource.title}
            </ResourceCard>
          </ButtonLessonItem>
        ) : (
          <LessonResourceItem
            key={resource.id}
            resource={resource}
            setEmbed={setEmbed}>
            <ResourceCard mint>
              <LessonResourceIcon type={resource.type} />
              {resource.title}
            </ResourceCard>
          </LessonResourceItem>
        ),
      )}
      <Modal cover onHide={handleHideEmbed} visible={showEmbed}>
        <div
          dangerouslySetInnerHTML={{ __html: embed }}
          style={{ display: 'flex' }}
        />
      </Modal>
    </ResourcesWrapper>
  );
}
