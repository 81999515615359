import courseSummaryImage from 'src/assets/images/course-summary.svg';
import { Card, getProfileRoute, Link } from 'src/shared';
import { useAppState } from 'src/state';
import styled from 'styled-components';

const BG_WIDTH = 295;

// Not specifying a width ruins the positioning of the children elements.
const CourseSummaryCard = styled(Card).attrs({
  height: '255px',
  lavender: true,
})`
  padding-left: ${BG_WIDTH}px;
`;

// This exists to make the background image go outside the Card borders.
const Background = styled.div`
  background: transparent left center no-repeat url(${courseSummaryImage});
  background-size: contain;
  height: calc(100% + 60px);
  left: 20px;
  position: absolute;
  top: -25px;
  width: ${BG_WIDTH}px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  height: 100%;
  justify-content: space-between;
`;

const Text = styled.div`
  margin: 70px 70px 0 0;

  em {
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeightBold};
  }

  span {
    display: block;
  }
`;

const LinkWrapper = styled.div`
  font-weight: ${(props) => props.theme.fontWeightBold};
  text-align: right;
`;

export function CourseSummary() {
  const { registeredCourses } = useAppState();
  const coursePercentage =
    registeredCourses.length &&
    registeredCourses.reduce((sum, course) => sum + course.percentage, 0) /
      registeredCourses.length;
  return (
    <CourseSummaryCard>
      <Background />
      <Content>
        <Text>
          {coursePercentage ? (
            <>
              {/* 
              <span>
                You have completed <em>{Math.round(coursePercentage)}%</em> of
                your coursework.
              </span> 
              */}
              <span>
                You are making great progress on
                your coursework.
              </span>
              <span>Way to go! Don&apos;t stop learning!</span>
            </>
          ) : (
            <>
              <span>Get started with your coursework</span>
              <span>to earn badges and other surprises 😌</span>
            </>
          )}
        </Text>

        <LinkWrapper>
          <Link href={getProfileRoute()} teal>
            See My Badges &gt;
          </Link>
        </LinkWrapper>
      </Content>
    </CourseSummaryCard>
  );
}
