import {
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AuthenticationResult, LogLevel } from '@azure/msal-common';
import { config } from '../../shared/config';

export const msalInstance = new PublicClientApplication({
  auth: {
    authority: config.msalAuthority,
    clientId: config.msalClientId,
    knownAuthorities: config.msalKnownAuthorities,
    redirectUri: config.msalRedirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      logLevel: config.isProd ? LogLevel.Info : LogLevel.Verbose,
    },
  },
});

// If we already have accounts in the browser session, set the first one as the
// active account.
// Reference: https://github.com/microsoftgraph/msgraph-training-reactspa
const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

// Set the active account on successful login.
// Reference: https://github.com/microsoftgraph/msgraph-training-reactspa
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account);
  }
});
