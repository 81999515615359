import { BoardSequence, Mark } from '../types';

/**
 * Returns the index in which a mark can be added to result in three
 * consecutive marks. Returns -1 if not possible.
 */
export function canStrikeThree(sequence: BoardSequence, mark: Mark): number {
  const { indices, marks } = sequence;

  if (marks.join('') === mark.repeat(2)) {
    return indices[marks.findIndex((mark) => !mark)];
  }

  return -1;
}
