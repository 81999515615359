import { Profile } from 'src/profile';
import { Footer } from 'src/shared';

export default function ProfilePage() {
  return (
    <>
      <Profile />
      <Footer lightPurple />
    </>
  );
}
