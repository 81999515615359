import {
  Download,
  Edit3,
  FileText,
  Film,
  Link,
  Video,
  Youtube,
} from 'react-feather';
import { LessonResourceType } from 'src/state';

interface ResourceIconProps {
  type: LessonResourceType;
}

export function LessonResourceIcon({ type }: ResourceIconProps) {
  switch (type) {
    case LessonResourceType.Document:
      return <Download />;

    case LessonResourceType.EmbeddableVideo:
      return <Video />;

    case LessonResourceType.ExternalVideo:
      return <Film />;

    case LessonResourceType.PDF:
      return <FileText />;

    case LessonResourceType.Quiz:
      return <Edit3 />;

    case LessonResourceType.YouTube:
      return <Youtube />;

    default:
      return <Link />;
  }
}
