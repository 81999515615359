import { getColorFromProps } from 'src/shared';
import styled from 'styled-components';

export const AVATAR_PREVIEW_SIZE = 160;

interface AvatarEditorPreviewProps {
  bgColor?: string;
}

export const AvatarEditorPreview = styled.div<AvatarEditorPreviewProps>`
  background-color: ${(props) =>
    props.bgColor ?? getColorFromProps(props).string()};
  border: 3px solid ${(props) => props.theme.violet.string()};
  border-radius: 10px;
  height: ${AVATAR_PREVIEW_SIZE}px;
  min-height: ${AVATAR_PREVIEW_SIZE}px;
  min-width: ${AVATAR_PREVIEW_SIZE}px;
  width: ${AVATAR_PREVIEW_SIZE}px;
`;
