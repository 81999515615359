export enum DeployedEnvironment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

function getDeployedEnvironment(): DeployedEnvironment {
  switch (process.env.REACT_APP_HOMEROOM_ENV) {
    case DeployedEnvironment.Development:
    case DeployedEnvironment.Staging:
    case DeployedEnvironment.Production:
      return process.env.REACT_APP_HOMEROOM_ENV as DeployedEnvironment;

    default:
      return DeployedEnvironment.Production;
  }
}

export const IS_DEVELOPMENT_ENV =
  getDeployedEnvironment() === DeployedEnvironment.Development;

export const IS_STAGING_ENV =
  getDeployedEnvironment() === DeployedEnvironment.Staging;

export const IS_PRODUCTION_ENV =
  getDeployedEnvironment() === DeployedEnvironment.Production;
