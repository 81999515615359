/**
 * @module Types and constants for emojis.
 *
 * This file was generated using "yarn generate:avatars".
 */
import angryFaceImg from 'src/libraries/avatars/emojis/assets/angry-face.png';
import anguishedFaceImg from 'src/libraries/avatars/emojis/assets/anguished-face.png';
import anxiousFaceWithSweatImg from 'src/libraries/avatars/emojis/assets/anxious-face-with-sweat.png';
import astonishedFaceImg from 'src/libraries/avatars/emojis/assets/astonished-face.png';
import beamingFaceWithSmilingEyesImg from 'src/libraries/avatars/emojis/assets/beaming-face-with-smiling-eyes.png';
import coldFaceImg from 'src/libraries/avatars/emojis/assets/cold-face.png';
import confoundedFaceImg from 'src/libraries/avatars/emojis/assets/confounded-face.png';
import confusedFaceImg from 'src/libraries/avatars/emojis/assets/confused-face.png';
import cowboyHatFaceImg from 'src/libraries/avatars/emojis/assets/cowboy-hat-face.png';
import cryingFaceImg from 'src/libraries/avatars/emojis/assets/crying-face.png';
import disappointedFaceImg from 'src/libraries/avatars/emojis/assets/disappointed-face.png';
import downcastFaceWithSweatImg from 'src/libraries/avatars/emojis/assets/downcast-face-with-sweat.png';
import explodingHeadImg from 'src/libraries/avatars/emojis/assets/exploding-head.png';
import expressionlessFaceImg from 'src/libraries/avatars/emojis/assets/expressionless-face.png';
import faceSavoringFoodImg from 'src/libraries/avatars/emojis/assets/face-savoring-food.png';
import faceScreamingInFearImg from 'src/libraries/avatars/emojis/assets/face-screaming-in-fear.png';
import faceVomitingImg from 'src/libraries/avatars/emojis/assets/face-vomiting.png';
import faceWithHandOverMouthImg from 'src/libraries/avatars/emojis/assets/face-with-hand-over-mouth.png';
import faceWithHeadBandageImg from 'src/libraries/avatars/emojis/assets/face-with-head-bandage.png';
import faceWithMedicalMaskImg from 'src/libraries/avatars/emojis/assets/face-with-medical-mask.png';
import faceWithMonocleImg from 'src/libraries/avatars/emojis/assets/face-with-monocle.png';
import faceWithRaisedEyebrowImg from 'src/libraries/avatars/emojis/assets/face-with-raised-eyebrow.png';
import faceWithRollingEyesImg from 'src/libraries/avatars/emojis/assets/face-with-rolling-eyes.png';
import faceWithSteamFromNoseImg from 'src/libraries/avatars/emojis/assets/face-with-steam-from-nose.png';
import faceWithSymbolsOnMouthImg from 'src/libraries/avatars/emojis/assets/face-with-symbols-on-mouth.png';
import faceWithTearsOfJoyImg from 'src/libraries/avatars/emojis/assets/face-with-tears-of-joy.png';
import faceWithThermometerImg from 'src/libraries/avatars/emojis/assets/face-with-thermometer.png';
import faceWithTongueImg from 'src/libraries/avatars/emojis/assets/face-with-tongue.png';
import faceWithoutMouthImg from 'src/libraries/avatars/emojis/assets/face-without-mouth.png';
import fearfulFaceImg from 'src/libraries/avatars/emojis/assets/fearful-face.png';
import flushedFaceImg from 'src/libraries/avatars/emojis/assets/flushed-face.png';
import frowningFaceWithOpenMouthImg from 'src/libraries/avatars/emojis/assets/frowning-face-with-open-mouth.png';
import frowningFaceImg from 'src/libraries/avatars/emojis/assets/frowning-face.png';
import grimacingFaceImg from 'src/libraries/avatars/emojis/assets/grimacing-face.png';
import grinningFaceWithBigEyesImg from 'src/libraries/avatars/emojis/assets/grinning-face-with-big-eyes.png';
import grinningFaceWithSmilingEyesImg from 'src/libraries/avatars/emojis/assets/grinning-face-with-smiling-eyes.png';
import grinningFaceWithSweatImg from 'src/libraries/avatars/emojis/assets/grinning-face-with-sweat.png';
import grinningFaceImg from 'src/libraries/avatars/emojis/assets/grinning-face.png';
import grinningSquintingFaceImg from 'src/libraries/avatars/emojis/assets/grinning-squinting-face.png';
import hotFaceImg from 'src/libraries/avatars/emojis/assets/hot-face.png';
import huggingFaceImg from 'src/libraries/avatars/emojis/assets/hugging-face.png';
import hushedFaceImg from 'src/libraries/avatars/emojis/assets/hushed-face.png';
import kissingFaceWithSmilingEyesImg from 'src/libraries/avatars/emojis/assets/kissing-face-with-smiling-eyes.png';
import kissingFaceImg from 'src/libraries/avatars/emojis/assets/kissing-face.png';
import loudlyCryingFaceImg from 'src/libraries/avatars/emojis/assets/loudly-crying-face.png';
import lyingFaceImg from 'src/libraries/avatars/emojis/assets/lying-face.png';
import moneyMouthFaceImg from 'src/libraries/avatars/emojis/assets/money-mouth-face.png';
import nauseatedFaceImg from 'src/libraries/avatars/emojis/assets/nauseated-face.png';
import nerdFaceImg from 'src/libraries/avatars/emojis/assets/nerd-face.png';
import neutralFaceImg from 'src/libraries/avatars/emojis/assets/neutral-face.png';
import partyingFaceImg from 'src/libraries/avatars/emojis/assets/partying-face.png';
import pensiveFaceImg from 'src/libraries/avatars/emojis/assets/pensive-face.png';
import pleadingFaceImg from 'src/libraries/avatars/emojis/assets/pleading-face.png';
import poutingFaceImg from 'src/libraries/avatars/emojis/assets/pouting-face.png';
import relievedFaceImg from 'src/libraries/avatars/emojis/assets/relieved-face.png';
import rollingOnTheFloorLaughingImg from 'src/libraries/avatars/emojis/assets/rolling-on-the-floor-laughing.png';
import sadButRelievedFaceImg from 'src/libraries/avatars/emojis/assets/sad-but-relieved-face.png';
import shushingFaceImg from 'src/libraries/avatars/emojis/assets/shushing-face.png';
import sleepyFaceImg from 'src/libraries/avatars/emojis/assets/sleepy-face.png';
import slightlyFrowningFaceImg from 'src/libraries/avatars/emojis/assets/slightly-frowning-face.png';
import slightlySmilingFaceImg from 'src/libraries/avatars/emojis/assets/slightly-smiling-face.png';
import smilingFaceWithHaloImg from 'src/libraries/avatars/emojis/assets/smiling-face-with-halo.png';
import smilingFaceWithHeartEyesImg from 'src/libraries/avatars/emojis/assets/smiling-face-with-heart-eyes.png';
import smilingFaceWithHeartsImg from 'src/libraries/avatars/emojis/assets/smiling-face-with-hearts.png';
import smilingFaceWithSmilingEyesImg from 'src/libraries/avatars/emojis/assets/smiling-face-with-smiling-eyes.png';
import smilingFaceWithSunglassesImg from 'src/libraries/avatars/emojis/assets/smiling-face-with-sunglasses.png';
import smirkingFaceImg from 'src/libraries/avatars/emojis/assets/smirking-face.png';
import starStruckImg from 'src/libraries/avatars/emojis/assets/star-struck.png';
import thinkingFaceImg from 'src/libraries/avatars/emojis/assets/thinking-face.png';
import tiredFaceImg from 'src/libraries/avatars/emojis/assets/tired-face.png';
import unamusedFaceImg from 'src/libraries/avatars/emojis/assets/unamused-face.png';
import upsideDownFaceImg from 'src/libraries/avatars/emojis/assets/upside-down-face.png';
import wearyFaceImg from 'src/libraries/avatars/emojis/assets/weary-face.png';
import winkingFaceWithTongueImg from 'src/libraries/avatars/emojis/assets/winking-face-with-tongue.png';
import winkingFaceImg from 'src/libraries/avatars/emojis/assets/winking-face.png';
import woozyFaceImg from 'src/libraries/avatars/emojis/assets/woozy-face.png';
import yawningFaceImg from 'src/libraries/avatars/emojis/assets/yawning-face.png';
import zanyFaceImg from 'src/libraries/avatars/emojis/assets/zany-face.png';
import zipperMouthFaceImg from 'src/libraries/avatars/emojis/assets/zipper-mouth-face.png';

/**
 * Enum representing all supported emojis.
 */
export enum EmojiEnum {
  AngryFace = 'angry-face',
  AnguishedFace = 'anguished-face',
  AnxiousFaceWithSweat = 'anxious-face-with-sweat',
  AstonishedFace = 'astonished-face',
  BeamingFaceWithSmilingEyes = 'beaming-face-with-smiling-eyes',
  ColdFace = 'cold-face',
  ConfoundedFace = 'confounded-face',
  ConfusedFace = 'confused-face',
  CowboyHatFace = 'cowboy-hat-face',
  CryingFace = 'crying-face',
  DisappointedFace = 'disappointed-face',
  DowncastFaceWithSweat = 'downcast-face-with-sweat',
  ExplodingHead = 'exploding-head',
  ExpressionlessFace = 'expressionless-face',
  FaceSavoringFood = 'face-savoring-food',
  FaceScreamingInFear = 'face-screaming-in-fear',
  FaceVomiting = 'face-vomiting',
  FaceWithHandOverMouth = 'face-with-hand-over-mouth',
  FaceWithHeadBandage = 'face-with-head-bandage',
  FaceWithMedicalMask = 'face-with-medical-mask',
  FaceWithMonocle = 'face-with-monocle',
  FaceWithRaisedEyebrow = 'face-with-raised-eyebrow',
  FaceWithRollingEyes = 'face-with-rolling-eyes',
  FaceWithSteamFromNose = 'face-with-steam-from-nose',
  FaceWithSymbolsOnMouth = 'face-with-symbols-on-mouth',
  FaceWithTearsOfJoy = 'face-with-tears-of-joy',
  FaceWithThermometer = 'face-with-thermometer',
  FaceWithTongue = 'face-with-tongue',
  FaceWithoutMouth = 'face-without-mouth',
  FearfulFace = 'fearful-face',
  FlushedFace = 'flushed-face',
  FrowningFaceWithOpenMouth = 'frowning-face-with-open-mouth',
  FrowningFace = 'frowning-face',
  GrimacingFace = 'grimacing-face',
  GrinningFaceWithBigEyes = 'grinning-face-with-big-eyes',
  GrinningFaceWithSmilingEyes = 'grinning-face-with-smiling-eyes',
  GrinningFaceWithSweat = 'grinning-face-with-sweat',
  GrinningFace = 'grinning-face',
  GrinningSquintingFace = 'grinning-squinting-face',
  HotFace = 'hot-face',
  HuggingFace = 'hugging-face',
  HushedFace = 'hushed-face',
  KissingFaceWithSmilingEyes = 'kissing-face-with-smiling-eyes',
  KissingFace = 'kissing-face',
  LoudlyCryingFace = 'loudly-crying-face',
  LyingFace = 'lying-face',
  MoneyMouthFace = 'money-mouth-face',
  NauseatedFace = 'nauseated-face',
  NerdFace = 'nerd-face',
  NeutralFace = 'neutral-face',
  PartyingFace = 'partying-face',
  PensiveFace = 'pensive-face',
  PleadingFace = 'pleading-face',
  PoutingFace = 'pouting-face',
  RelievedFace = 'relieved-face',
  RollingOnTheFloorLaughing = 'rolling-on-the-floor-laughing',
  SadButRelievedFace = 'sad-but-relieved-face',
  ShushingFace = 'shushing-face',
  SleepyFace = 'sleepy-face',
  SlightlyFrowningFace = 'slightly-frowning-face',
  SlightlySmilingFace = 'slightly-smiling-face',
  SmilingFaceWithHalo = 'smiling-face-with-halo',
  SmilingFaceWithHeartEyes = 'smiling-face-with-heart-eyes',
  SmilingFaceWithHearts = 'smiling-face-with-hearts',
  SmilingFaceWithSmilingEyes = 'smiling-face-with-smiling-eyes',
  SmilingFaceWithSunglasses = 'smiling-face-with-sunglasses',
  SmirkingFace = 'smirking-face',
  StarStruck = 'star-struck',
  ThinkingFace = 'thinking-face',
  TiredFace = 'tired-face',
  UnamusedFace = 'unamused-face',
  UpsideDownFace = 'upside-down-face',
  WearyFace = 'weary-face',
  WinkingFaceWithTongue = 'winking-face-with-tongue',
  WinkingFace = 'winking-face',
  WoozyFace = 'woozy-face',
  YawningFace = 'yawning-face',
  ZanyFace = 'zany-face',
  ZipperMouthFace = 'zipper-mouth-face',
}

/**
 * List of supported emojis.
 */
export const SUPPORTED_EMOJIS = Object.values(EmojiEnum);

/**
 * Map of emoji enums and their sources.
 */
export const EMOJI_SOURCES = {
  [EmojiEnum.AngryFace]: angryFaceImg,
  [EmojiEnum.AnguishedFace]: anguishedFaceImg,
  [EmojiEnum.AnxiousFaceWithSweat]: anxiousFaceWithSweatImg,
  [EmojiEnum.AstonishedFace]: astonishedFaceImg,
  [EmojiEnum.BeamingFaceWithSmilingEyes]: beamingFaceWithSmilingEyesImg,
  [EmojiEnum.ColdFace]: coldFaceImg,
  [EmojiEnum.ConfoundedFace]: confoundedFaceImg,
  [EmojiEnum.ConfusedFace]: confusedFaceImg,
  [EmojiEnum.CowboyHatFace]: cowboyHatFaceImg,
  [EmojiEnum.CryingFace]: cryingFaceImg,
  [EmojiEnum.DisappointedFace]: disappointedFaceImg,
  [EmojiEnum.DowncastFaceWithSweat]: downcastFaceWithSweatImg,
  [EmojiEnum.ExplodingHead]: explodingHeadImg,
  [EmojiEnum.ExpressionlessFace]: expressionlessFaceImg,
  [EmojiEnum.FaceSavoringFood]: faceSavoringFoodImg,
  [EmojiEnum.FaceScreamingInFear]: faceScreamingInFearImg,
  [EmojiEnum.FaceVomiting]: faceVomitingImg,
  [EmojiEnum.FaceWithHandOverMouth]: faceWithHandOverMouthImg,
  [EmojiEnum.FaceWithHeadBandage]: faceWithHeadBandageImg,
  [EmojiEnum.FaceWithMedicalMask]: faceWithMedicalMaskImg,
  [EmojiEnum.FaceWithMonocle]: faceWithMonocleImg,
  [EmojiEnum.FaceWithRaisedEyebrow]: faceWithRaisedEyebrowImg,
  [EmojiEnum.FaceWithRollingEyes]: faceWithRollingEyesImg,
  [EmojiEnum.FaceWithSteamFromNose]: faceWithSteamFromNoseImg,
  [EmojiEnum.FaceWithSymbolsOnMouth]: faceWithSymbolsOnMouthImg,
  [EmojiEnum.FaceWithTearsOfJoy]: faceWithTearsOfJoyImg,
  [EmojiEnum.FaceWithThermometer]: faceWithThermometerImg,
  [EmojiEnum.FaceWithTongue]: faceWithTongueImg,
  [EmojiEnum.FaceWithoutMouth]: faceWithoutMouthImg,
  [EmojiEnum.FearfulFace]: fearfulFaceImg,
  [EmojiEnum.FlushedFace]: flushedFaceImg,
  [EmojiEnum.FrowningFaceWithOpenMouth]: frowningFaceWithOpenMouthImg,
  [EmojiEnum.FrowningFace]: frowningFaceImg,
  [EmojiEnum.GrimacingFace]: grimacingFaceImg,
  [EmojiEnum.GrinningFaceWithBigEyes]: grinningFaceWithBigEyesImg,
  [EmojiEnum.GrinningFaceWithSmilingEyes]: grinningFaceWithSmilingEyesImg,
  [EmojiEnum.GrinningFaceWithSweat]: grinningFaceWithSweatImg,
  [EmojiEnum.GrinningFace]: grinningFaceImg,
  [EmojiEnum.GrinningSquintingFace]: grinningSquintingFaceImg,
  [EmojiEnum.HotFace]: hotFaceImg,
  [EmojiEnum.HuggingFace]: huggingFaceImg,
  [EmojiEnum.HushedFace]: hushedFaceImg,
  [EmojiEnum.KissingFaceWithSmilingEyes]: kissingFaceWithSmilingEyesImg,
  [EmojiEnum.KissingFace]: kissingFaceImg,
  [EmojiEnum.LoudlyCryingFace]: loudlyCryingFaceImg,
  [EmojiEnum.LyingFace]: lyingFaceImg,
  [EmojiEnum.MoneyMouthFace]: moneyMouthFaceImg,
  [EmojiEnum.NauseatedFace]: nauseatedFaceImg,
  [EmojiEnum.NerdFace]: nerdFaceImg,
  [EmojiEnum.NeutralFace]: neutralFaceImg,
  [EmojiEnum.PartyingFace]: partyingFaceImg,
  [EmojiEnum.PensiveFace]: pensiveFaceImg,
  [EmojiEnum.PleadingFace]: pleadingFaceImg,
  [EmojiEnum.PoutingFace]: poutingFaceImg,
  [EmojiEnum.RelievedFace]: relievedFaceImg,
  [EmojiEnum.RollingOnTheFloorLaughing]: rollingOnTheFloorLaughingImg,
  [EmojiEnum.SadButRelievedFace]: sadButRelievedFaceImg,
  [EmojiEnum.ShushingFace]: shushingFaceImg,
  [EmojiEnum.SleepyFace]: sleepyFaceImg,
  [EmojiEnum.SlightlyFrowningFace]: slightlyFrowningFaceImg,
  [EmojiEnum.SlightlySmilingFace]: slightlySmilingFaceImg,
  [EmojiEnum.SmilingFaceWithHalo]: smilingFaceWithHaloImg,
  [EmojiEnum.SmilingFaceWithHeartEyes]: smilingFaceWithHeartEyesImg,
  [EmojiEnum.SmilingFaceWithHearts]: smilingFaceWithHeartsImg,
  [EmojiEnum.SmilingFaceWithSmilingEyes]: smilingFaceWithSmilingEyesImg,
  [EmojiEnum.SmilingFaceWithSunglasses]: smilingFaceWithSunglassesImg,
  [EmojiEnum.SmirkingFace]: smirkingFaceImg,
  [EmojiEnum.StarStruck]: starStruckImg,
  [EmojiEnum.ThinkingFace]: thinkingFaceImg,
  [EmojiEnum.TiredFace]: tiredFaceImg,
  [EmojiEnum.UnamusedFace]: unamusedFaceImg,
  [EmojiEnum.UpsideDownFace]: upsideDownFaceImg,
  [EmojiEnum.WearyFace]: wearyFaceImg,
  [EmojiEnum.WinkingFaceWithTongue]: winkingFaceWithTongueImg,
  [EmojiEnum.WinkingFace]: winkingFaceImg,
  [EmojiEnum.WoozyFace]: woozyFaceImg,
  [EmojiEnum.YawningFace]: yawningFaceImg,
  [EmojiEnum.ZanyFace]: zanyFaceImg,
  [EmojiEnum.ZipperMouthFace]: zipperMouthFaceImg,
};

/**
 * Map of emoji enums and their names.
 */
export const EMOJI_NAMES = {
  [EmojiEnum.AngryFace]: 'Angry face',
  [EmojiEnum.AnguishedFace]: 'Anguished face',
  [EmojiEnum.AnxiousFaceWithSweat]: 'Anxious face with sweat',
  [EmojiEnum.AstonishedFace]: 'Astonished face',
  [EmojiEnum.BeamingFaceWithSmilingEyes]: 'Beaming face with smiling eyes',
  [EmojiEnum.ColdFace]: 'Cold face',
  [EmojiEnum.ConfoundedFace]: 'Confounded face',
  [EmojiEnum.ConfusedFace]: 'Confused face',
  [EmojiEnum.CowboyHatFace]: 'Cowboy hat face',
  [EmojiEnum.CryingFace]: 'Crying face',
  [EmojiEnum.DisappointedFace]: 'Disappointed face',
  [EmojiEnum.DowncastFaceWithSweat]: 'Downcast face with sweat',
  [EmojiEnum.ExplodingHead]: 'Exploding head',
  [EmojiEnum.ExpressionlessFace]: 'Expressionless face',
  [EmojiEnum.FaceSavoringFood]: 'Face savoring food',
  [EmojiEnum.FaceScreamingInFear]: 'Face screaming in fear',
  [EmojiEnum.FaceVomiting]: 'Face vomiting',
  [EmojiEnum.FaceWithHandOverMouth]: 'Face with hand over mouth',
  [EmojiEnum.FaceWithHeadBandage]: 'Face with head bandage',
  [EmojiEnum.FaceWithMedicalMask]: 'Face with medical mask',
  [EmojiEnum.FaceWithMonocle]: 'Face with monocle',
  [EmojiEnum.FaceWithRaisedEyebrow]: 'Face with raised eyebrow',
  [EmojiEnum.FaceWithRollingEyes]: 'Face with rolling eyes',
  [EmojiEnum.FaceWithSteamFromNose]: 'Face with steam from nose',
  [EmojiEnum.FaceWithSymbolsOnMouth]: 'Face with symbols on mouth',
  [EmojiEnum.FaceWithTearsOfJoy]: 'Face with tears of joy',
  [EmojiEnum.FaceWithThermometer]: 'Face with thermometer',
  [EmojiEnum.FaceWithTongue]: 'Face with tongue',
  [EmojiEnum.FaceWithoutMouth]: 'Face without mouth',
  [EmojiEnum.FearfulFace]: 'Fearful face',
  [EmojiEnum.FlushedFace]: 'Flushed face',
  [EmojiEnum.FrowningFaceWithOpenMouth]: 'Frowning face with open mouth',
  [EmojiEnum.FrowningFace]: 'Frowning face',
  [EmojiEnum.GrimacingFace]: 'Grimacing face',
  [EmojiEnum.GrinningFaceWithBigEyes]: 'Grinning face with big eyes',
  [EmojiEnum.GrinningFaceWithSmilingEyes]: 'Grinning face with smiling eyes',
  [EmojiEnum.GrinningFaceWithSweat]: 'Grinning face with sweat',
  [EmojiEnum.GrinningFace]: 'Grinning face',
  [EmojiEnum.GrinningSquintingFace]: 'Grinning squinting face',
  [EmojiEnum.HotFace]: 'Hot face',
  [EmojiEnum.HuggingFace]: 'Hugging face',
  [EmojiEnum.HushedFace]: 'Hushed face',
  [EmojiEnum.KissingFaceWithSmilingEyes]: 'Kissing face with smiling eyes',
  [EmojiEnum.KissingFace]: 'Kissing face',
  [EmojiEnum.LoudlyCryingFace]: 'Loudly crying face',
  [EmojiEnum.LyingFace]: 'Lying face',
  [EmojiEnum.MoneyMouthFace]: 'Money mouth face',
  [EmojiEnum.NauseatedFace]: 'Nauseated face',
  [EmojiEnum.NerdFace]: 'Nerd face',
  [EmojiEnum.NeutralFace]: 'Neutral face',
  [EmojiEnum.PartyingFace]: 'Partying face',
  [EmojiEnum.PensiveFace]: 'Pensive face',
  [EmojiEnum.PleadingFace]: 'Pleading face',
  [EmojiEnum.PoutingFace]: 'Pouting face',
  [EmojiEnum.RelievedFace]: 'Relieved face',
  [EmojiEnum.RollingOnTheFloorLaughing]: 'Rolling on the floor laughing',
  [EmojiEnum.SadButRelievedFace]: 'Sad but relieved face',
  [EmojiEnum.ShushingFace]: 'Shushing face',
  [EmojiEnum.SleepyFace]: 'Sleepy face',
  [EmojiEnum.SlightlyFrowningFace]: 'Slightly frowning face',
  [EmojiEnum.SlightlySmilingFace]: 'Slightly smiling face',
  [EmojiEnum.SmilingFaceWithHalo]: 'Smiling face with halo',
  [EmojiEnum.SmilingFaceWithHeartEyes]: 'Smiling face with heart eyes',
  [EmojiEnum.SmilingFaceWithHearts]: 'Smiling face with hearts',
  [EmojiEnum.SmilingFaceWithSmilingEyes]: 'Smiling face with smiling eyes',
  [EmojiEnum.SmilingFaceWithSunglasses]: 'Smiling face with sunglasses',
  [EmojiEnum.SmirkingFace]: 'Smirking face',
  [EmojiEnum.StarStruck]: 'Star struck',
  [EmojiEnum.ThinkingFace]: 'Thinking face',
  [EmojiEnum.TiredFace]: 'Tired face',
  [EmojiEnum.UnamusedFace]: 'Unamused face',
  [EmojiEnum.UpsideDownFace]: 'Upside down face',
  [EmojiEnum.WearyFace]: 'Weary face',
  [EmojiEnum.WinkingFaceWithTongue]: 'Winking face with tongue',
  [EmojiEnum.WinkingFace]: 'Winking face',
  [EmojiEnum.WoozyFace]: 'Woozy face',
  [EmojiEnum.YawningFace]: 'Yawning face',
  [EmojiEnum.ZanyFace]: 'Zany face',
  [EmojiEnum.ZipperMouthFace]: 'Zipper mouth face',
};
