import { Link } from 'react-router-dom';
import { Card } from 'src/shared';
import styled from 'styled-components';

export const ButtonLessonItem = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.textColor.string()};
  cursor: pointer;
  display: flex;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize};
  gap: 1rem;
  padding: 0;

  ${Card} {
    flex-grow: 1;
  }
`;

export const ExternalLinkLessonItem = styled.a`
  align-items: center;
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  gap: 1rem;
  text-decoration: none;

  ${Card} {
    flex-grow: 1;
  }
`;

export const InternalLinkLessonItem = styled(Link)`
  align-items: center;
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  gap: 1rem;
  text-decoration: none;

  ${Card} {
    flex-grow: 1;
  }
`;
