// import ClipboardIcon from 'src/assets/icons/assignment.svg';
// import MarkupIcon from 'src/assets/icons/coding-course.svg';
// import { Card, SecondHeading } from 'src/shared';
// import styled from 'styled-components';

// const ICON_HEIGHT = 40;
// const ICON_WIDTH = 40;

// const InnerCardWrapper = styled.div`
//   align-items: center;
//   display: flex;

//   h3,
//   p {
//     margin: 0;
//   }
// `;

// const IconWrapper = styled.div`
//   width: 60px;
// `;

export function WeekAtAGlance() {
  // TODO
  return null;
  // return (
  //   <Card lightYellow>
  //     <SecondHeading margin="50">This Week At A Glance</SecondHeading>

  //     <Card lightPurple style={{ marginRight: '40px' }}>
  //       <InnerCardWrapper>
  //         <IconWrapper>
  //           <img
  //             alt=""
  //             height={ICON_HEIGHT}
  //             src={MarkupIcon}
  //             width={ICON_WIDTH}
  //           />
  //         </IconWrapper>
  //         <div>
  //           <h3>August 24, 2021 | 6pm</h3>
  //           <p>Intro to Webmaking: HTML &amp; CSS</p>
  //           <p>Instructor: Jasmine S.</p>
  //         </div>
  //       </InnerCardWrapper>
  //     </Card>

  //     <Card pink>
  //       <InnerCardWrapper>
  //         <IconWrapper>
  //           <img
  //             alt=""
  //             height={ICON_HEIGHT}
  //             src={ClipboardIcon}
  //             width={ICON_WIDTH}
  //           />
  //         </IconWrapper>
  //         <div>
  //           <h3>August 26, 2021 | 11:59pm</h3>
  //           <p>Assignment due</p>
  //           <p>Intro to Webmaking: HTML &amp; CSS</p>
  //         </div>
  //       </InnerCardWrapper>
  //     </Card>
  //   </Card>
  // );
}
