import { CalendarView } from '../types';

/** Converts the URL view param into a {@link CalendarView}. */
export function getViewFromUrl(view: string | undefined): CalendarView {
  if (
    view === CalendarView.Day ||
    view === CalendarView.Month ||
    view === CalendarView.Week
  ) {
    return view;
  }

  return CalendarView.Month;
}
