/**
 * Utilities for logging and reporting.
 */
import { ReportHandler } from 'web-vitals';
import { IS_DEVELOPMENT_ENV, IS_PRODUCTION_ENV } from './env';

export function logDebug(...messages: any[]) {
  if (IS_DEVELOPMENT_ENV) {
    console.debug('[debug]', ...messages);
  }
}

export function logInfo(...messages: any[]) {
  if (!IS_PRODUCTION_ENV) {
    console.info('[info]', ...messages);
  }
}

export function logWarning(...messages: any[]) {
  console.warn(...messages);
}

function toError(obj: unknown): Error {
  if (obj instanceof Error) {
    return obj;
  }

  if (typeof obj === 'string') {
    return new Error(obj);
  }

  return new Error(JSON.stringify(obj));
}

export function logError(msg: unknown) {
  const error = toError(msg);

  console.error(error);

  if (error.cause) {
    console.warn(error.cause);
  }
}

/**
 * Logs an error with the provided message while keeping the original error
 * trace as the cause.
 */
export function logWithCause(msg: string, cause: unknown) {
  logError(new Error(msg, { cause: toError(cause) }));
}

/**
 * Creates a function which logs an error using the provided error message and
 * capturing the original cause. Meant to be used in Promise-based chains.
 */
export function withCause(msg: string) {
  return (cause: unknown) => logWithCause(msg, cause);
}

export async function reportWebVitals(onPerfEntry?: ReportHandler) {
  if (!onPerfEntry) {
    return;
  }

  const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import(
    'web-vitals'
  );

  getCLS(onPerfEntry);
  getFID(onPerfEntry);
  getFCP(onPerfEntry);
  getLCP(onPerfEntry);
  getTTFB(onPerfEntry);
}
