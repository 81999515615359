/**
 * Utility functions for working with state entities.
 */

import {
  MctCourseContentItem,
  MctCourseContentItemExtended,
} from 'src/libraries/mct';
import { LessonResourceType } from './types';

interface ParsedLessonType {
  type: LessonResourceType;
  url: string;
}

/**
 * Helper function for generating a default parsed lesson resource.
 */
function getDefaultLessonResource(
  lesson: MctCourseContentItem | MctCourseContentItemExtended,
): ParsedLessonType {
  return {
    type: LessonResourceType.Unknown,
    url: lesson.Data.Url || lesson.Data.DownloadUrl,
  };
}

/**
 * Determines the file type of a lesson resource.
 */
export function getLessonResourceType(
  lesson: MctCourseContentItem | MctCourseContentItemExtended,
): ParsedLessonType {
  if (lesson.ItemType === 'Quiz') {
    return {
      type: LessonResourceType.Quiz,
      // Link directly to learning content in MCT.
      url: `https://hypehomeroomb2c.azurewebsites.net/#/course/${lesson.CourseId}/item/${lesson.CourseItemId}`,
    };
  }

  switch (lesson.Data.FileType) {
    case 'doc':
    case 'docx':
      return {
        type: LessonResourceType.Document,
        url: lesson.Data.Url || lesson.Data.DownloadUrl,
      };

    case 'ExternalLink':
      return getLessonResourceTypeFromUrl(lesson);

    case 'pdf':
      return {
        type: LessonResourceType.PDF,
        url: lesson.Data.Url || lesson.Data.DownloadUrl,
      };

    case 'Video':
      return {
        type: LessonResourceType.EmbeddableVideo,
        url: lesson.Data.DownloadUrl,
      };

    default:
      return getDefaultLessonResource(lesson);
  }
}

/**
 * Determines the file type of a lesson resource uploaded as a URL.
 */
function getLessonResourceTypeFromUrl(
  lesson: MctCourseContentItem | MctCourseContentItemExtended,
): ParsedLessonType {
  const url = lesson.Data.Url || lesson.Data.DownloadUrl;

  // Descript
  // Example: https://share.descript.com/view/70aUafr0ez5
  if (/^https?:\/\/share\.descript\.com\//.test(url)) {
    return { type: LessonResourceType.ExternalVideo, url };
  }

  // YouTube
  const yt = url.match(/^https:\/\/youtu\.be\/([0-9a-zA-Z_-]*)/);

  if (yt) {
    return {
      type: LessonResourceType.YouTube,
      url: `https://www.youtube.com/embed/${yt[1]}`,
    };
  }

  return getDefaultLessonResource(lesson);
}

/** Filters out unsupported resource types from a list. */
export function filterSupportedLessonResources({
  ItemType,
}: MctCourseContentItem): boolean {
  return ItemType === 'Lesson' || ItemType === 'Quiz';
}
