import { Link } from 'react-router-dom';
import { Card, getColorByHash, getCourseRoute } from 'src/shared';
import styled from 'styled-components';

interface DayCardWrapperProps {
  gridRow: string;
}

interface DayCardProps extends DayCardWrapperProps {
  courseId: number;
  iconSource: string;
  lessonId: number;
  title: string;
}

const DayCardWrapper = styled(Link)<DayCardWrapperProps>`
  color: ${(props) => props.theme.black.string()};
  display: flex;
  grid-column: 2 / 3;
  grid-row: ${(props) => props.gridRow};
  justify-content: center;
  text-decoration: none;
`;

const DayEventCard = styled(Card)`
  box-sizing: border-box;
  padding: 8px;
  width: 90%;
`;

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const Title = styled.h6`
  font-size: 18px;
  margin: 0;
`;

export function DayCard({
  courseId,
  gridRow,
  iconSource,
  lessonId,
  title,
}: DayCardProps) {
  const color = getColorByHash(title);
  const colorProp = { [color]: true };

  return (
    <DayCardWrapper gridRow={gridRow} to={getCourseRoute(courseId, lessonId)}>
      <DayEventCard {...colorProp}>
        <Icon alt="" src={iconSource} />
        <Title>{title}</Title>
      </DayEventCard>
    </DayCardWrapper>
  );
}
