import { Button } from 'src/shared';
import styled from 'styled-components';
import { fadeInAnimation } from './fadeIn';

interface EndMenuProps {
  backToStartMenu: () => void;
  beginNewMatch: () => void;
  result: string;
}

const Wrapper = styled.div`
  align-items: center;
  animation: ${fadeInAnimation};
  display: flex;
  flex-direction: column;
`;

const Message = styled.h3`
  font-family: ${(props) => props.theme.funFontFamily};
  font-size: 32px;
  margin: 0 0 1rem 0;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

export function EndMenu({
  backToStartMenu,
  beginNewMatch,
  result,
}: EndMenuProps) {
  return (
    <Wrapper>
      <Message>{result}</Message>
      <ButtonGroup>
        <Button onClick={beginNewMatch} pink>
          Play Again
        </Button>
        <Button onClick={backToStartMenu} yellow>
          Back To Start Menu
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}
