import { MctGroupInfo } from 'src/libraries/mct';

/**
 * Represents a user group, e.g. a cohort or a group of facilitators.
 */
export class Group {
  static fromMctEntity(group: MctGroupInfo) {
    return new Group(group.GroupId, group.GroupName);
  }

  /** Creates a copy of this {@link Group}. */
  copy() {
    return new Group(this.id, this.name);
  }

  constructor(readonly id: number, readonly name: string) {}
}
