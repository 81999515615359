import announcementsImage from 'src/assets/images/announcements.svg';
import { Notification } from 'src/libraries/mct';
import { FirstHeading } from 'src/shared';
import styled from 'styled-components';
import { A11y, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

interface AnnouncementsProps {
  isLoading: boolean;
  notifications: Notification[];
}

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 900px;
`;

// Padding is to somewhat vertically center the text.
const LeftContainer = styled.div`
  padding: 5% 0;
  width: 50%;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const SwiperWrapper = styled.div`
  display: block;
  width: 100%;
`;

const SliderText = styled.p`
  font-size: 26px;
`;

const swiperModules = [A11y, Autoplay, Pagination];

export function Announcements({
  isLoading,
  notifications,
}: AnnouncementsProps) {
  return (
    <Container>
      <LeftContainer>
        <FirstHeading>Announcements</FirstHeading>
        <SwiperWrapper>
          <Swiper autoplay loop modules={swiperModules}>
            {isLoading && (
              <SwiperSlide>
                <SliderText>Loading...</SliderText>
              </SwiperSlide>
            )}
            {notifications?.length ? (
              notifications.map((notification) => (
                <SwiperSlide key={notification.id}>
                  <SliderText>{notification.message}</SliderText>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <SliderText>All caught up!</SliderText>
              </SwiperSlide>
            )}
          </Swiper>
        </SwiperWrapper>
      </LeftContainer>
      <RightContainer>
        <img alt="" height={426} src={announcementsImage} width={483} />
      </RightContainer>
    </Container>
  );
}
