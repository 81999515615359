/** Builds a route based on the provided arguments. */
export function buildRoute(
  root: string,
  paths: Array<string | null | undefined> = [],
) {
  return `/${[root, ...paths]
    .filter((path) => path && path.trim().length > 0)
    .join('/')}`;
}

export function getHomeRoute() {
  return getCourseRoute();
}

export function getLoginRoute(nextPage: string = '/') {
  return `/login?next=${encodeURIComponent(nextPage)}`;
}

export function getParentLoginRoute(nextPage: string = '/') {
  return `/login/parent?next=${encodeURIComponent(nextPage)}`;
}

export function getStudentLoginRoute(nextPage: string = '/') {
  return `/login/student?next=${encodeURIComponent(nextPage)}`;
}

export function getCalendarRoute(...paths: string[]) {
  return buildRoute('calendar', paths);
}

export function getCalendarMonthRoute(date: string) {
  return getCalendarRoute('month', date);
}

export function getCalendarWeekRoute(date: string) {
  return getCalendarRoute('week', date);
}

export function getCalendarDayRoute(date: string) {
  return getCalendarRoute('day', date);
}

export function getCourseRoute(courseId?: number, lessonId?: number | string) {
  return courseId
    ? buildRoute('courses', [courseId.toString(), lessonId?.toString()])
    : '/';
}

export function getGamesRoute(): string {
  return '/games';
}

export function getProfileRoute() {
  return '/profile';
}

export function isAuthRoute(route: string) {
  return (
    route.startsWith('/login') ||
    route.startsWith('/register') ||
    route.startsWith('/reset')
  );
}

export function isCalendarRoute(route: string) {
  return route.startsWith('/calendar');
}

export function isCourseRoute(route: string) {
  return route.startsWith('/courses') || route === '/';
}

export function isGamesRoute(route: string): boolean {
  return route.startsWith('/games');
}

export function isProfileRoute(route: string) {
  return route.startsWith('/profile');
}
