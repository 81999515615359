import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CalendarDayView,
  CalendarMonthView,
  CalendarView,
  CalendarViewNav,
  CalendarWeekView,
  CustomDateObject,
  getDateFromUrl,
  getDefaultCalendarRoute,
  getViewFromUrl,
  isValidCalendarView,
  isValidYearMonthDayDate,
  parseToYearMonthDay,
} from 'src/calendar';
import {
  Footer,
  getCalendarDayRoute,
  getCalendarMonthRoute,
  getCalendarWeekRoute,
} from 'src/shared';

export default function CalendarPage() {
  const pushRoute = useNavigate();
  const { date, view } = useParams();

  useEffect(() => {
    if (
      !isValidYearMonthDayDate(date || '') ||
      !isValidCalendarView(view || '')
    ) {
      pushRoute(getDefaultCalendarRoute(), { replace: true });
    }
  }, [date, pushRoute, view]);

  const selectedDate = getDateFromUrl(date);
  const selectedView = getViewFromUrl(view);

  function handleMonthUpdate(date: CustomDateObject) {
    const ymdDate = parseToYearMonthDay(date.year, date.month, date.dayOfMonth);

    pushRoute(getCalendarMonthRoute(ymdDate));
  }

  function handleWeekUpdate(date: CustomDateObject) {
    const ymdDate = parseToYearMonthDay(date.year, date.month, date.dayOfMonth);

    pushRoute(getCalendarWeekRoute(ymdDate));
  }

  function handleDayUpdate(date: CustomDateObject) {
    const ymdDate = parseToYearMonthDay(date.year, date.month, date.dayOfMonth);

    pushRoute(getCalendarDayRoute(ymdDate));
  }

  return (
    <>
      <CalendarViewNav selectedDate={selectedDate} view={selectedView}>
        {selectedView === CalendarView.Month && (
          <CalendarMonthView
            onDateUpdate={handleMonthUpdate}
            selectedDate={selectedDate}
          />
        )}
        {selectedView === CalendarView.Week && (
          <CalendarWeekView
            onDateUpdate={handleWeekUpdate}
            selectedDate={selectedDate}
          />
        )}
        {selectedView === CalendarView.Day && (
          <CalendarDayView
            onDateUpdate={handleDayUpdate}
            selectedDate={selectedDate}
          />
        )}
      </CalendarViewNav>

      <Footer lavender />
    </>
  );
}
