import { FormEvent } from 'react';
import { LessonResource } from 'src/state';
import {
  ButtonLessonItem,
  ExternalLinkLessonItem,
} from '../ClickableLessonItem';

interface ResourceItemProps {
  children: React.ReactNode;
  resource: LessonResource;
  setEmbed: (embed: string) => void;
}

export function LessonResourceItem({
  children,
  resource,
  setEmbed,
}: ResourceItemProps) {
  function handleClick(event: FormEvent<HTMLButtonElement>) {
    event?.preventDefault();
    setEmbed(resource.embed);
  }

  if (resource.hasEmbed) {
    return (
      <ButtonLessonItem onClick={handleClick}>{children}</ButtonLessonItem>
    );
  }

  return (
    <ExternalLinkLessonItem
      href={resource.url}
      rel="noreferrer"
      target="_blank">
      {children}
    </ExternalLinkLessonItem>
  );
}
