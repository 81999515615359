import { MctCategoryEntity, MctCategoryInfo } from 'src/libraries/mct';
import { Lesson } from './lesson';

/**
 * Represents a single course, i.e. a group of lessons.
 */
export class Course {
  static fromMctEntity(entity: MctCategoryEntity | MctCategoryInfo) {
    return new Course(
      'CategoryId' in entity ? entity.CategoryId : entity.CategoryIdKey,
      entity.CategoryName,
      new Map(),
    );
  }

  /**
   * Returns a copy of this {@link Course} with the added {@link Lesson}.
   */
  withLesson(lesson: Lesson): Course {
    const lessonMap = new Map<string, Lesson>(this.lessonMap);

    lessonMap.set(lesson.id, lesson);

    return new Course(this.id, this.name, lessonMap);
  }

  /**
   * Returns a copy of this {@link Course} with the specified {@link Lesson}
   * removed.
   */
  removeLesson(lesson: Lesson | number): Course {
    const lessonMap = new Map<string, Lesson>(this.lessonMap);
    const lessonId = lesson instanceof Lesson ? lesson.id : lesson;

    lessonMap.delete(`${lessonId}`);

    return new Course(this.id, this.name, lessonMap);
  }

  /**
   * Returns a copy of this {@link Course} with the provided lessons.
   */
  withLessons(lessons: Lesson[], replace: boolean = false): Course {
    const lessonsMap = new Map<string, Lesson>(replace ? [] : this.lessonMap);

    for (const lesson of lessons) {
      lessonsMap.set(lesson.id, lesson);
    }

    return new Course(this.id, this.name, lessonsMap);
  }

  get lessons(): Lesson[] {
    return Array.from(this.lessonMap.values()).sort(sortLessons);
  }

  /** Returns a list of unique {@link Lesson} entities with unique resources. */
  get mergedLessons(): Lesson[] {
    const mergedLessons: Lesson[] = [];

    for (const lesson of this.lessons) {
      const mergedLessonIndex = mergedLessons.findIndex(
        ({ lessonId }) => lessonId === lesson.lessonId,
      );

      if (mergedLessonIndex > -1) {
        const mergedLesson = mergedLessons[mergedLessonIndex];

        // Append any unique resources.
        const mergedResources = [
          ...mergedLesson.resources,
          ...lesson.resources,
        ].filter(
          (resource, index, arr) =>
            arr.findIndex((res) => res.id === resource.id) === index,
        );

        let startTimestamp = mergedLesson.startTimestamp;
        let endTimestamp = mergedLesson.endTimestamp;

        // Overwrite the start timestamp to be the oldest.
        if (!startTimestamp) {
          startTimestamp = lesson.startTimestamp;
        } else if (
          lesson.startTimestamp &&
          lesson.startTimestamp.valueOf() < startTimestamp.valueOf()
        ) {
          startTimestamp = lesson.startTimestamp;
        }

        // Overwrite the end timestamp to be the newest.
        if (!endTimestamp) {
          endTimestamp = lesson.endTimestamp;
        } else if (
          lesson.endTimestamp &&
          lesson.endTimestamp.valueOf() > endTimestamp.valueOf()
        ) {
          endTimestamp = lesson.endTimestamp;
        }

        mergedLessons[mergedLessonIndex] = mergedLesson
          .withResources(mergedResources)
          .withStartTimeStamp(startTimestamp)
          .withEndTimeStamp(endTimestamp);
      } else {
        mergedLessons.push(lesson);
      }
    }

    return mergedLessons;
  }

  get currentLesson(): Lesson | null {
    const lessons = this.lessons;

    return lessons.length ? lessons.find((l) => l.started) || lessons[0] : null;
  }

  /** Returns the percentage of completed lessons. */
  get percentage(): number {
    const mergedLessons = this.mergedLessons;

    if (this.completed) {
      return 100;
    }

    const completedLessons = mergedLessons.filter((l) => l.completed);

    return (completedLessons.length / mergedLessons.length) * 100;
  }

  /** Determines if every course lesson has been completed. */
  get completed(): boolean {
    const mergedLessons = this.mergedLessons;

    if (mergedLessons.every((l) => !l.endTimestamp)) {
      return true;
    }

    return this.mergedLessons.every((l) => l.completed);
  }

  constructor(
    readonly id: number,
    readonly name: string,
    readonly lessonMap: Map<string, Lesson>,
  ) {}
}

/**
 * Sorts lessons.
 */
function sortLessons(a: Lesson, b: Lesson): number {
  const aTime = a.startTimestamp?.valueOf() ?? 0;
  const bTime = b.startTimestamp?.valueOf() ?? 0;

  return aTime - bTime;
}
