import { useEffect, useState } from 'react';
import { getTokens } from './token';

interface TokensHookProps {
  accessToken: string;
  idToken: string;
  isLoading: boolean;
}

export function useTokens(): TokensHookProps {
  const [state, setState] = useState<TokensHookProps>({
    accessToken: '',
    idToken: '',
    isLoading: true,
  });

  useEffect(() => {
    getTokens().then(({ accessToken, idToken }) => {
      setState({ isLoading: false, accessToken, idToken });
    });

    // TODO/IMPROVEMENT: clear token on logout.
  }, []);

  return { ...state };
}
