import Color from 'color';
import { MouseEvent, useRef } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';
import { getColor } from '../colors/getColor';
import { ColorProps } from '../colors/types';
import {
  COLOR_BLACK,
  COLOR_LIGHT_PURPLE,
  COLOR_PINK,
  COLOR_PURPLE,
  COLOR_TEAL,
  COLOR_WHITE,
  COLOR_YELLOW,
} from '../theme';
import { Button } from './buttons/Button';

export interface ModalProps extends ColorProps {
  children: React.ReactNode;
  // Removes content padding and uses all of the space within the modal.
  cover?: boolean;
  onHide: () => void;
  title?: string;
  visible?: boolean;
}

const ModalWrapper = styled.div<{ visible?: boolean }>`
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: ${(props) => props.theme.textColor.string()};
  display: ${(props) => (props.visible ? 'block' : 'none')};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
`;

const Background = styled.div<{ bgColor: Color }>`
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: ${(props) => props.bgColor.fade(0.4).string()};
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  transition: all 300ms;
  width: 100%;
`;

const Content = styled.div<{
  bgColor: Color;
  cover?: boolean;
  textColor: Color;
}>`
  background-color: ${(props) => props.bgColor.string()};
  box-sizing: border-box;
  color: ${(props) => props.textColor.string()};
  height: 100%;
  padding: ${(props) => (props.cover ? '0' : '1rem')};
  position: relative;
  width: 100%;

  @media (min-width: 1024px) {
    border: 2px solid ${(props) => props.theme.violet.string()};
    border-radius: 5px;
    height: auto;
    margin-top: 20vh;
    max-width: 1024px;
    min-height: 200px;
    outline: 5px solid ${(props) => props.theme.white.string()};
    width: 600px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;

  svg {
    margin: -5px -10px -9px;
  }
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 5rem 1rem 1rem;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
`;

const Body = styled.div<{ cover?: boolean }>`
  height: ${(props) => (props.cover ? '100%' : 'auto')};
  padding: ${(props) => (props.cover ? '0' : '1rem')};
`;

export function Modal({
  children,
  cover,
  onHide,
  title,
  visible,
  ...colorProp
}: ModalProps) {
  const bgRef = useRef<HTMLDivElement>(null);
  const bgColor = getColor(colorProp, COLOR_LIGHT_PURPLE);
  const btnColor = getBtnColor(bgColor);
  const borderColor = getBtnBorderColor(bgColor);
  const textColor = getTextColor(bgColor);

  function handleOutsideClick(event: MouseEvent) {
    if (event.target === bgRef.current) {
      onHide();
    }
  }

  return (
    <ModalWrapper visible={visible}>
      <Background bgColor={bgColor} onClick={handleOutsideClick} ref={bgRef}>
        <Content bgColor={bgColor} cover={cover} textColor={textColor}>
          {cover ? null : <Title>{title}</Title>}
          <Body cover={cover}>{children}</Body>
          <CloseButton {...btnColor} borderColor={borderColor} onClick={onHide}>
            <X size={30} />
          </CloseButton>
        </Content>
      </Background>
    </ModalWrapper>
  );
}

function getBtnColor(bgColor: Color) {
  if (bgColor === COLOR_PINK) {
    return { white: true };
  }

  if ([COLOR_PURPLE, COLOR_TEAL].includes(bgColor)) {
    return { lavender: true };
  }

  if (bgColor === COLOR_YELLOW) {
    return { pink: true };
  }

  return { teal: true };
}

function getBtnBorderColor(bgColor: Color) {
  if (bgColor === COLOR_PURPLE) {
    return COLOR_WHITE;
  }

  return undefined;
}

function getTextColor(bgColor: Color) {
  if (bgColor === COLOR_PURPLE) {
    return COLOR_WHITE;
  }

  return COLOR_BLACK;
}
