import Color from 'color';
import {
  COLOR_LAVENDER,
  COLOR_LIGHT_PURPLE,
  COLOR_LIGHT_YELLOW,
  COLOR_MINT,
  COLOR_PINK,
  COLOR_PURPLE,
  COLOR_TEAL,
  COLOR_WHITE,
  COLOR_YELLOW,
} from '../theme';
import { ColorProps, ThemedColorProps } from './types';

export function getColor(props: ColorProps, defaultColor?: Color) {
  if (props.lavender) {
    return COLOR_LAVENDER;
  }

  if (props.lightPurple) {
    return COLOR_LIGHT_PURPLE;
  }

  if (props.lightYellow) {
    return COLOR_LIGHT_YELLOW;
  }

  if (props.mint) {
    return COLOR_MINT;
  }

  if (props.pink) {
    return COLOR_PINK;
  }

  if (props.purple) {
    return COLOR_PURPLE;
  }

  if (props.teal) {
    return COLOR_TEAL;
  }

  if (props.white) {
    return COLOR_WHITE;
  }

  if (props.yellow) {
    return COLOR_YELLOW;
  }

  return defaultColor || COLOR_TEAL;
}

export function getColorFromProps(
  props: ThemedColorProps,
  defaultColor?: Color,
) {
  if (props.lavender) {
    return props.theme.lavender;
  }

  if (props.lightPurple) {
    return props.theme.lightPurple;
  }

  if (props.lightYellow) {
    return props.theme.lightYellow;
  }

  if (props.mint) {
    return props.theme.mint;
  }

  if (props.pink) {
    return props.theme.pink;
  }

  if (props.purple) {
    return props.theme.purple;
  }

  if (props.white) {
    return props.theme.white;
  }

  return defaultColor || props.theme.teal;
}
