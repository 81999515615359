import { useState } from 'react';
import { SecondHeading } from 'src/shared';
import styled from 'styled-components';
import { Board } from './Board';
import { EndMenu } from './EndMenu';
import { StartMenu } from './StartMenu';
import { Difficulty } from './types';

const Wrapper = styled.section`
  min-height: 530px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export function TicTacToe() {
  const [difficulty, setDifficulty] = useState(Difficulty.Easy);
  const [result, setResult] = useState('');
  const [showBoard, setShowBoard] = useState(false);
  const [showEndMenu, setShowEndMenu] = useState(false);
  const [showStartMenu, setShowStartMenu] = useState(true);

  /** Ensures only the start menu is rendered. */
  function isolateStartMenu() {
    setShowBoard(false);
    setShowEndMenu(false);
    setShowStartMenu(true);
  }

  /** Ensures only the board is rendered. */
  function isolateBoard() {
    setShowBoard(true);
    setShowEndMenu(false);
    setShowStartMenu(false);
  }

  /** Ensures only the end menu is rendered. */
  function isolateEndMenu() {
    setShowBoard(false);
    setShowEndMenu(true);
    setShowStartMenu(false);
  }

  /** Enables the player to select a difficulty level. */
  function chooseDifficulty(difficulty: Difficulty) {
    setDifficulty(difficulty);
  }

  /** Sets the game result message. */
  function changeResult(result: string) {
    setResult(result);
  }

  return (
    <Wrapper>
      <SecondHeading margin="0 0 1.25rem 0">Tic Tac Toe</SecondHeading>
      <ContentWrapper>
        {showStartMenu && (
          <StartMenu
            beginMatch={isolateBoard}
            chooseDifficulty={chooseDifficulty}
            difficulty={difficulty}
          />
        )}

        {showBoard && (
          <Board
            changeResult={changeResult}
            difficulty={difficulty}
            endMatch={isolateEndMenu}
          />
        )}

        {showEndMenu && (
          <EndMenu
            backToStartMenu={isolateStartMenu}
            beginNewMatch={isolateBoard}
            result={result}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
}
