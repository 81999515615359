import { useState } from 'react';
import { BadgeAvatar, SUPPORTED_BADGES } from 'src/libraries/avatars';
import styled from 'styled-components';

interface LoadingProps {
  badgeSize?: string;
  height?: string;
  padding?: string;
  text?: string;
  textSize?: string;
}

const Wrapper = styled.div<{ height?: string; padding?: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height || 'auto'};
  padding: ${(props) => props.padding || 0};
`;

const Text = styled.p<{ fontSize?: string }>`
  font-family: ${(props) => props.theme.inputFontFamily};
  font-size: ${(props) => props.fontSize || '18px'};
  margin: 0;
`;

export function Loading({
  badgeSize,
  height,
  padding,
  text,
  textSize,
}: LoadingProps) {
  const [randomBadge] = useState(
    SUPPORTED_BADGES[Math.trunc(Math.random() * SUPPORTED_BADGES.length)],
  );

  return (
    <Wrapper height={height} padding={padding}>
      <BadgeAvatar badge={randomBadge} size={badgeSize || '50px'} />
      <Text fontSize={textSize}>{text || 'Please hold on...'}</Text>
    </Wrapper>
  );
}
