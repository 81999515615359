import { FirstHeading } from 'src/shared';
import styled from 'styled-components';

interface ProgramSlideProps {
  background: string;
  text: string;
  title: string;
}

const SlideWrapper = styled.div<{ background: string }>`
  background: ${(props) => props.theme.lightYellow.string()} center center
    no-repeat url(${(props) => props.background});
  background-size: contain;
  padding-bottom: 80%; // Maintain aspect ratio of 5:4
  width: 100%;
`;

const Text = styled.p`
  bottom: 0;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: 50%;
`;

export function ProgramSlide(props: ProgramSlideProps) {
  return (
    <SlideWrapper background={props.background}>
      <FirstHeading margin="0 auto 4px">{props.title}</FirstHeading>
      <Text>{props.text}</Text>
    </SlideWrapper>
  );
}
