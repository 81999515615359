import { Form, Input, Label } from './FormComponents';

export function ParentForm() {
  return (
    <Form>
      <Label htmlFor="parent">Find your parent or guardian</Label>
      <Input id="parent" name="parent" type="text" />
    </Form>
  );
}
