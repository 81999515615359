import { useEffect } from 'react';
import { Mark } from '../types';

/** Assigns mark to the player, deciding if they start or not. */
export function useInitializeMatch(onGameInitialized: (mark: Mark) => void) {
  useEffect(() => {
    const zeroOrOne = Math.round(Math.random());

    onGameInitialized(zeroOrOne ? Mark.X : Mark.O);

    // The effect does not depend on onGameInitialized.
    // eslint-disable-next-line
  }, []);
}
