import { Link } from 'react-router-dom';
import { Card, getColorByHash, getCourseRoute } from 'src/shared';
import styled from 'styled-components';

interface WeekCardWrapperProps {
  gridColumn: string;
  gridRow: string;
}

interface WeekCardProps extends WeekCardWrapperProps {
  courseId: number;
  iconSource: string;
  lessonId: number;
  title: string;
}

const WeekCardWrapper = styled(Link)<WeekCardWrapperProps>`
  color: ${(props) => props.theme.black.string()};
  display: flex;
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  justify-content: center;
  text-decoration: none;
`;

const WeekEventCard = styled(Card)`
  box-sizing: border-box;
  padding: 5px;
  width: 80%;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Title = styled.h6`
  font-size: 14px;
  margin: 0;
`;

export function WeekCard({
  courseId,
  gridColumn,
  gridRow,
  iconSource,
  lessonId,
  title,
}: WeekCardProps) {
  const color = getColorByHash(title);
  const colorProp = { [color]: true };

  return (
    <WeekCardWrapper
      gridColumn={gridColumn}
      gridRow={gridRow}
      to={getCourseRoute(courseId, lessonId)}>
      <WeekEventCard {...colorProp}>
        <Icon alt="" src={iconSource} />
        <Title>{title}</Title>
      </WeekEventCard>
    </WeekCardWrapper>
  );
}
