import { graphClient } from '../client';
import eventsBackup from '../data/homeroom-events-backup.json';
import { GraphCalendarEvent } from '../entities/GraphCalendarEvent';

export class CalendarGraph {
  /** Retrieves all events from the shared Homeroom calendar. */
  static async getAllEvents(): Promise<GraphCalendarEvent[]> {
    // Read events from file for now.
    return this.getAllEventsFromFile();
  }

  /** Temporary/backup method for retrieving calendar events. */
  private static async getAllEventsFromFile(): Promise<GraphCalendarEvent[]> {
    const events = await Promise.resolve(
      eventsBackup.map(GraphCalendarEvent.fromJson),
    );

    // TODO SPRING 2023
    // Manually add first lesson (01 Wireframing) and remove old events.
    const tempSpring2023 = new Date('2023-01-01').valueOf();
    return [
      GraphCalendarEvent.create(
        'temp-s23-01-20230130',
        'Intro to Web Development - 01 Wireframing - Spring 2023',
        new Date('2023-01-30 16:00:00'),
        new Date('2023-01-30 17:30:00'),
      ),
      GraphCalendarEvent.create(
        'temp-s23-01-20230131',
        'Intro to Web Development - 01 Wireframing - Spring 2023',
        new Date('2023-01-31 16:30:00'),
        new Date('2023-01-31 18:00:00'),
      ),
      GraphCalendarEvent.create(
        'temp-s23-01-20230201',
        'Intro to Web Development - 01 Wireframing - Spring 2023',
        new Date('2023-02-01 16:00:00'),
        new Date('2023-02-01 17:30:00'),
      ),
      GraphCalendarEvent.create(
        'temp-s23-01-20230202',
        'Intro to Web Development - 01 Wireframing - Spring 2023',
        new Date('2023-02-02 16:30:00'),
        new Date('2023-02-02 18:00:00'),
      ),
      ...events.filter((e) => e.start.valueOf() > tempSpring2023),
    ];
  }

  /** Test method, not used for anything at the moment. */
  static async listCalendars() {
    return graphClient.api('/me/calendars').get();
  }
}
