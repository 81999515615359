import { useEffect, useState } from 'react';
import { MctApi } from 'src/libraries/mct';
import { useTokens } from 'src/libraries/msal';
import { withCause } from 'src/utils';
import { Profile } from '../entities/profile';
import { DEFAULT_USER_STATE } from './constants';
import { getUserState } from './getUserState';
import { UserState } from './types';

/** Provides the user state as a hook. */
export function useUserState() {
  const [timestamp, setTimestamp] = useState(Date.now());
  const [partialState, setPartialState] = useState<Partial<UserState>>({});
  const [isLoadingUserState, setLoading] = useState(true);
  const { idToken } = useTokens();

  useEffect(() => {
    if (!idToken) {
      return;
    }

    setLoading(true);

    getUserState(idToken)
      .then(setPartialState)
      .catch(withCause('Could not retrieve user state.'))
      .finally(() => void setLoading(false));
  }, [timestamp, idToken]);

  /** Refreshes the user state. */
  function onRefreshUserState() {
    setTimestamp(Date.now());
  }

  /** Updates the profile of the authenticated student. */
  function onUpdateProfile(p: Profile) {
    MctApi.updateOrgUserProfile(idToken, p, state.currentUser)
      .then(() => setTimestamp(Date.now()))
      .catch(withCause('Could not update profile'));
  }

  const state: UserState = {
    ...DEFAULT_USER_STATE,
    ...partialState,
    isLoadingUserState,
    onRefreshUserState,
    onUpdateProfile,
  };

  return state;
}
